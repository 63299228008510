import { Button, withStyles } from '@material-ui/core'

export const StyledNavBarButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white'
    }
  },
  disabled: {
    backgroundColor: '#e0e0e0'
  }
}))(Button)
