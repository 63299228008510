import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import LayoutInviteAccess from '../Layouts/LayoutInviteAccess'
import { AppState } from '../../interfaces/AppState'
import { Pod } from '../../interfaces/interfacesApp'
import { Redirect, useParams } from 'react-router-dom'
import makeDebug from 'debug'

const debug = makeDebug('PagePodInvite:')

export interface ParamTypes {
  podid: string,
  page: string,
  moduleid: string
}

const PagePodInvite = (): ReactElement | null => {
  const { podid, page, moduleid } = useParams<ParamTypes>()
  const userId = useSelector((state:AppState) => state.login.user?._id)
  const isMember = useSelector((state:AppState) => (userId && Array.isArray(state.pods.queryResult) && (state.pods.queryResult.filter((pod :Pod) => (pod._id === podid) && (pod.usersConfirmed?.includes(userId) || pod.usersPending?.includes(userId))).length > 0)))
  const podInviteId = useSelector((state:AppState) => state.podsSlice.podInviteId)
  const isInvited = (podInviteId && podInviteId.length > 0)

  debug(`Render: userId:${userId}, podid:${podid}, page:${page}, moduleid:${moduleid}, isMember:${isMember}, podInviteId:${podInviteId}, isInvited:${isInvited}`)

  if (isMember) {
    const invitePage = (page === undefined) ? null : (page === 'module') ? `poll/${moduleid}` : page
    const url = (invitePage && (invitePage.length > 0)) ? `/pod/${podid}/${invitePage}` : `/pod/${podid}`
    debug(`Redirect to url:${url}`)
    return <Redirect to={url} />
  } else if (isInvited) {
    debug('Transitory state: do nothing, ListenerPodInvite will manage the invitation')
    return null
  } else {
    debug('Render LayoutInviteAccess')
    return (<LayoutInviteAccess />)
  }
}

export default PagePodInvite
