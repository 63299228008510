import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import { Post } from '../../interfaces/interfacesApp'
import Linkify from 'react-linkify'
import MediaInPost from './MediaInPost'
import { IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import { isPublicEmail } from '../../lib/utils'

export const useStyles = makeStyles(() => ({
  postDate: {
    color: 'gray',
    whiteSpace: 'nowrap'
  },
  postFull: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    borderRadius: '12px',
    backgroundColor: '#F2F2F2',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word' // overflowWrap: 'anywhere' not yet supported on safari
  },
  postList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  loggedInUserPostDate: {
    color: 'rgba(0, 124, 137, 0.5)',
    whiteSpace: 'nowrap'
  },
  loggedInUserPostFull: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    borderRadius: '12px',
    backgroundColor: 'rgba(0, 124, 137, 0.2)',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word' // overflowWrap: 'anywhere' not yet supported on safari
  },
  loggedInUserPostList: {
    paddingTop: '0px',
    paddingBottom: '0px',
    justifyContent: 'flex-end'
  },
  deletedMessage: {
    color: 'grey'
  },
  icon: {
    color: 'lightgrey'
  }
}))

export interface LayoutPostFullProps {
  post: Post
  messagesEndRef: any
  isLoggedInUserPost: boolean,
  postToDelete: (post: Post) => void
}

export default function LayoutPostFull ({ post, messagesEndRef, isLoggedInUserPost, postToDelete }: LayoutPostFullProps) : ReactElement {
  const classes = useStyles()
  const { t } = useTranslation()
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const oneMonthAgo = new Date(today)
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  const oneWeekAgo = new Date(today)
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
  const postDate = post.creationDate ? new Date(post.creationDate) : new Date(oneMonthAgo)
  const listClass = isLoggedInUserPost ? classes.loggedInUserPostList : classes.postList
  const boxClass = isLoggedInUserPost ? classes.loggedInUserPostFull : classes.postFull
  const dateClass = isLoggedInUserPost ? classes.loggedInUserPostDate : classes.postDate

  const message = post.message
  const deletedMessage = t('chat.deletePost.postDeleted')
  const media = post.medias
  const isPublic = isPublicEmail(post.user?.email || '')

  return (
    <ListItem className={listClass}>
      {(isLoggedInUserPost && message) || (isLoggedInUserPost && !message && media)
        ? (
          <IconButton size='small' aria-label='delete' color='inherit' onClick={() => postToDelete(post)}>
            <DeleteIcon className={classes.icon} fontSize='small' />
          </IconButton>
        ) : null}
      <Box className={boxClass}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!isLoggedInUserPost
            ? (
              <div style={{ color: post.userColor, flexGrow: 2, paddingRight: '1rem', whiteSpace: 'nowrap' }}>{post.user?.firstname + (isPublic ? ` (${t('profile.publicMail')})` : '')}</div>
            ) : null}
          <div className={dateClass}>{postDate <= oneMonthAgo ? new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }).format(postDate)
            : postDate < oneWeekAgo ? new Intl.DateTimeFormat('fr-FR', {
              month: 'long',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: false
            }).format(postDate)
              : postDate < today ? new Intl.DateTimeFormat('fr-FR', {
                weekday: 'long',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
              }).format(postDate)
                : new Intl.DateTimeFormat('fr-FR', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false
                }).format(postDate)}
          </div>
        </div>
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target='_blank' rel='noreferrer' href={decoratedHref} key={key}>
            {decoratedText}
          </a>)}
        >
          <Typography className={message || classes.deletedMessage}>
            {media ? message : message || deletedMessage}
          </Typography>
        </Linkify>
        {media != null
          ? (
            <MediaInPost medias={post.processedMedias || []} />
          )
          : null}
        <div ref={messagesEndRef} />
      </Box>
    </ListItem>)
}
