import React from 'react'
import { connect } from 'react-redux'
import LayoutResetPassword from '../../components/Layouts/LayoutResetPassword'
import { resetPasswordThunk, ResetPasswordThunk } from '../../features/ResetPassword/resetPasswordSlice'

const mapDispatch = { resetPasswordThunk }

export interface VerifyTokenProps {
  resetPasswordThunk: ResetPasswordThunk
  resetStatus: string,
  resetError: string
}

const ResetPassword = ({ resetPasswordThunk, resetStatus, resetError }: VerifyTokenProps) => {
  return (
    <LayoutResetPassword
      resetPasswordThunk={resetPasswordThunk} resetStatus={resetStatus} resetError={resetError}
    />
  )
}

interface RootState {
  resetPassword : any
}

const mapState = (state: RootState) => {
  return ({
    resetStatus: state?.resetPassword?.resetPasswordStatus,
    resetError: state?.resetPassword?.resetPasswordStatusError
  })
}

export default connect(mapState, mapDispatch)(ResetPassword)
