import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@material-ui/core'
import { StyledListIcon, StyledPollIcon } from '../Styled/StyledIcons'
import React, { memo, ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

export const useStyles = makeStyles(() => ({
  CCC: {
    fontSize: '17px',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  CCCdescription: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#808080'
  }
}))
interface WidgetSwipeableDrawerProps{ // TODO Define type of module
  open: boolean
  onClose: any
  onOpen: any
  handlePoll: any
  handleList: any
  toggleDrawer: any
}

const WidgetSwipeableDrawer = ({ open, onClose, onOpen, handlePoll, handleList, toggleDrawer }: WidgetSwipeableDrawerProps) : ReactElement => {
  const classes = useStyles()

  const { t } = useTranslation('translation')

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div role='presentation' onClick={toggleDrawer('bottom', false)} onKeyDown={toggleDrawer('bottom', false)}>
        <List>
          <ListItem>
            <ListItemText primary={t('chat.flowMenu.title')} />
          </ListItem>
        </List>
        <Divider />
        <List onClick={handlePoll}>
          <ListItem button key='sondage'>
            <ListItemIcon><StyledPollIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.flowMenu.poll')}</ListItemText>
              <ListItemText className={classes.CCCdescription}>{t('chat.flowMenu.pollDescription')}</ListItemText>
            </div>
          </ListItem>
        </List>
        <Divider />
        <List onClick={handleList}>
          <ListItem button key='todolist'>
            <ListItemIcon><StyledListIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.flowMenu.list')}</ListItemText>
              <ListItemText className={classes.CCCdescription}>{t('chat.flowMenu.listDescription')}</ListItemText>
            </div>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  )
}

export default memo(WidgetSwipeableDrawer)
