import React, { ReactElement } from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'

export const LinearBarStatus = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#404040'
  }
}))(LinearProgress)

export const WidgetModuleProgressStatus = (props: LinearProgressProps & { value: number }) : ReactElement => {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearBarStatus variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
            props.value
          )}%`}
        </Typography>
      </Box>
    </Box>
  )
}
