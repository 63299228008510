/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AvatarPod from '../Widgets/AvatarPod'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import ListItemUser from '../Widgets/ListItemUser'
import NavBar from '../Menus/NavBar'
import { Pod, User } from '../../interfaces/interfacesApp'
import { getUsersConfirmed, sortUsersByAlphabeticalOrderId, getColorFromID } from '../../lib/utils'
import { podEditThunk } from '../../features/Pods/podsSlice'
import { ListItemText } from '@material-ui/core'
import WidgetTextField from '../Widgets/WidgetTextField'

export const ContainerStyled = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '130px'
  }
}))(Container)

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: 'darkgrey'
  },
  inline: {
    display: 'inline',
    fontSize: '16px',
    fontFamily: 'Roboto',
    paddingLeft: '1rem'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  numberOfUsers: {
    paddingLeft: '10px',
    paddingTop: '5px',
    color: 'grey'
  },
  AddIcon: {
    color: 'gray',
    padding: '5px'
  }
}))

interface PagePodEditProps {
  pod: Pod
  users: User[]
  curUserId: string
  onLeave: any
}

export default function PagePodEdit ({ pod, users, curUserId, onLeave } : PagePodEditProps): ReactElement {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const dispatch = useDispatch()
  const usersConfirmed = getUsersConfirmed(pod, users)
  const usersConfirmedIds = sortUsersByAlphabeticalOrderId(pod.usersConfirmed, users)
  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: `Editing pod "${pod.title}"`
    })
  }, [])

  function AddButton () : ReactElement {
    return (
      <>
        <PersonAddOutlinedIcon className={classes.AddIcon} />
        <span className={classes.inline}>{t('pods.podEdit.addMembersButton')}</span>
      </>
    )
  }

  function Leave () : ReactElement {
    return (
      <>
        <ExitToAppIcon className={classes.large} />
        <ListItemText className={classes.inline} primary={t('pods.podEdit.leaveButton')} />
      </>

    )
  }

  // Array of users confirmed
  // transform array of users confirmed to list
  function UsersConfirmed () : ReactElement {
    return (
      <>
        {usersConfirmed
          .map((user : User, index : number) =>
            <div key={user._id}>
              {user._id !== curUserId
                ? (
                  <Link className={classes.link} to={`/persondetails/${user._id}`}>
                    <ListItemUser user={user} key={user._id} index={index} onChevron />
                  </Link>
                )
                : (
                  <ListItemUser user={user} key={user._id} index={index} />
                )}
            </div>
          )}
      </>
    )
  }
  const handleSave = useCallback((title) => dispatch(podEditThunk(pod, { title })), [pod])
  return (
    <>
      <NavBar back title={t('pods.podEdit.option')} />
      <ContainerStyled maxWidth='lg'>
        <AvatarPod pod={pod} width='60px' height='60px' fontSize='2rem' opacity='30%' borderRadius='12px' backgroundColor={getColorFromID(pod._id)} />
      </ContainerStyled>
      <ListItem>
        <WidgetTextField label={t('pods.podEdit.title')} value={pod.title} save={handleSave} style={{ numberOfRows: 1, multiline: false }} />
      </ListItem>
      <Link className={classes.link} to={`/invitationfriends/${pod._id}`}><ListItem><AddButton /></ListItem></Link>
      <Divider />
      <Typography variant='caption' className={classes.numberOfUsers}>{t('pods.podEdit.member', { count: usersConfirmed.length })} </Typography>
      <UsersConfirmed />
      <Divider />
      <ListItem button onClick={() => { onLeave() }}><Leave /></ListItem>
      <Divider />
    </>
  )
}
