import React, { memo, ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NotificationData, Pod, User } from '../../../interfaces/interfacesApp'
import { Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'
import PodItem from './PodItem'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '120px'
  },
  description: {
    marginTop: '50%',
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: 'lightGray'
  }
}))

interface PodListProps {
  pods: Pod[],
  user: User,
  notifications: NotificationData[]
}

const PodList = ({ pods, user, notifications }:PodListProps): ReactElement => {
  const [, setPodId] = useState(null)
  const [, setInviteId] = useState(null)
  const classes = useStyles()

  const userId = user?._id

  let podsPending : Pod[] = []
  let podsConfirmed : Pod[] = []
  pods.forEach((pod : Pod) => {
    if (pod.usersPending.includes(userId)) {
      podsPending = [...podsPending, pod]
    } else {
      podsConfirmed = [...podsConfirmed, pod]
    }
  })
  return (
    <div className={classes.root}>
      {podsPending.sort((a: Pod, b: Pod) => { // display pods pending first
        const aCompare = a.lastModificationDate || a.dateStart
        const bCompare = b.lastModificationDate || b.dateStart
        if (aCompare < bCompare || aCompare === undefined) return 1; return -1
      }).map((pod: Pod, index: number) => <PodItem key={index} pod={pod} setPodId={() => setPodId} setInviteId={() => setInviteId} isInvitation notifications={notifications} />)}

      {podsConfirmed.length > 0
        ? (podsConfirmed.sort((a: Pod, b: Pod) => { // then display pods confirmed by order of last modification
          const aCompare = a.lastModificationDate || a.dateStart
          const bCompare = b.lastModificationDate || b.dateStart
          if (aCompare < bCompare || aCompare === undefined) return 1; return -1
        }).map((pod: Pod, index: number) => <PodItem key={index} pod={pod} setPodId={() => setPodId} setInviteId={() => setInviteId} isInvitation={false} notifications={notifications} />))
        : (
          <div>
            <Typography className={classes.description} align='center'><Trans i18nKey='pods.nopod' /></Typography>
          </div>)}
    </div>
  )
}
export default memo(PodList)
