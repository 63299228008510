import React, { ReactElement } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import getInitials from '../../lib/utils'
import NavBar from '../Menus/NavBar'
// import { createTrue } from 'typescript'

export const useStyles = makeStyles(() => ({
  link: { textDecoration: 'none' },
  avatar: {
    marginTop: '30px',
    width: '65px',
    height: '65px',
    fontSize: '23px',
    backgroundColor: 'lightGray'
  },
  label: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    alignItems: 'center'
  },
  description: {
    marginTop: '20px',
    fontSize: '20px',
    fontFamily: 'Roboto',
    overflowWrap: 'break-word'
  },
  buttonDescription: {
    marginTop: '10px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    overflowWrap: 'break-word'
  },
  buttonYes: {
    marginTop: '40px',
    backgroundColor: '#000000',
    fontSize: '18px',
    borderRadius: '30px',
    padding: '15px',
    width: '300px'
  },
  buttonNo: {
    marginTop: '40px',
    backgroundColor: '#eeeeee',
    fontSize: '18px',
    color: '#000000',
    borderRadius: '30px',
    padding: '15px',
    width: '300px'
  }
}))

export interface LayoutConfirmRelationShipProps {
  onConfirm : any
  setConfirmRelation: any
  user: any
}

// render layout for inviting people with a link
const LayoutConfirmRelationShip = ({ onConfirm, user, setConfirmRelation } : LayoutConfirmRelationShipProps): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation('translation')

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Viewing Invitation Link'
    })
  }, [])

  function Label () : ReactElement {
    return (
      <div className={classes.label}>
        {user.firstname + ' ' + user.lastname}
      </div>)
  }

  function Intro () : ReactElement {
    return (
      <div className={classes.description}>
        <span>{user.firstname + t('invite.confirmIntro')}</span>
      </div>)
  }

  function YesText () : ReactElement {
    return (
      <div className={classes.buttonDescription}>
        <span>{user.firstname + t('invite.confirmYes')}</span>
      </div>)
  }

  function NoText () : ReactElement {
    return (
      <div className={classes.buttonDescription}>
        <span>{t('invite.confirmNo')}</span>
      </div>)
  }

  // **** principal layout ****
  return (
    <>
      <NavBar onBack={() => setConfirmRelation(false)} title={t('invite.confirmTitle')} />
      <div className={classes.container}>
        <Avatar className={classes.avatar}>
          {getInitials(user.firstname + ' ' + user.lastname)}
        </Avatar>
        <Label />
        <Intro />
        <Button className={classes.buttonYes} onClick={() => onConfirm(true)} variant='contained' color='primary'> {t('invite.confirmButtonYes')} </Button>
        <YesText />
        <Button className={classes.buttonNo} onClick={() => onConfirm(false)} variant='contained' color='primary'> {t('invite.confirmButtonNo')} </Button>
        <NoText />
      </div>
    </>
  )
}

export default LayoutConfirmRelationShip
