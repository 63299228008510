import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SymbolActiveSVG } from '../../assets/activeSymbol.svg'
import { ReactComponent as SymbolPassiveSVG } from '../../assets/passiveSymbol.svg'
import { BottomNavigation, BottomNavigationAction, SvgIcon } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../interfaces/AppState'
import { NotificationData } from '../../interfaces/interfacesApp'
import { numberOfUpdatedPods } from '../../lib/utils'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: '0',
    right: '0',
    left: '0',
    zIndex: 1100
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  badge: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: '50%',
    width: '13px',
    height: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    right: '-6px'
  },
  bold: {
    fontSize: '10px',
    fontWeight: 'bold'
  }
}))

export enum MainBottomBarSelectedItemType {
  PODS = 'pods',
  RELATIONSHIPS = 'relationships',
  PROFILE = 'profile'
}
export interface MainBottomBarProps {
  item: MainBottomBarSelectedItemType
  notifications: NotificationData[]
}

function MainBottomBar ({ item, notifications } : MainBottomBarProps) : ReactElement {
  const numberOfNotifications = numberOfUpdatedPods(notifications)
  const classes = useStyles()
  const [value, setValue] = React.useState(item)
  const history = useHistory()

  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    history.push(`/${newValue}`)
  }

  const { t } = useTranslation('translation')

  const BadgeNotification = () => {
    if (numberOfNotifications) {
      return (
        <div style={{ position: 'relative' }}>
          {item === MainBottomBarSelectedItemType.PODS
            ? <SvgIcon style={{ position: 'relative' }} component={SymbolActiveSVG} />
            : <SvgIcon style={{ position: 'relative' }} component={SymbolPassiveSVG} />}
          <div className={classes.badge}>
            <p className={classes.bold}>{numberOfNotifications}</p>
          </div>
        </div>
      )
    } else {
      return (
        item === 'pods'
          ? <SvgIcon style={{ position: 'relative' }} component={SymbolActiveSVG} />
          : <SvgIcon style={{ position: 'relative' }} component={SymbolPassiveSVG} />
      )
    }
  }

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      className={classes.root}
    >
      <BottomNavigationAction
        value={MainBottomBarSelectedItemType.PODS}
        label={t('navbar.symflos')}
        icon={<BadgeNotification />}
      />
      <BottomNavigationAction
        value={MainBottomBarSelectedItemType.RELATIONSHIPS}
        label={t('navbar.friends')}
        icon={<PeopleOutlineOutlinedIcon />}
      />
      <BottomNavigationAction
        value={MainBottomBarSelectedItemType.PROFILE}
        label={t('navbar.profile')}
        icon={<AccountCircleOutlinedIcon />}
      />
    </BottomNavigation>
  )
}

export default MainBottomBar
