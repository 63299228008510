import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { usersClient, usersServices, podsServices, pollsServices, postsServices, mediasServices, podsClient, pollsClient, mediasClient, postsClient } from '../../App'

import { FeathersClient, FeathersServices, FeathersOpaqueData } from '../../interfaces/interfacesFeathers'

import { Credentials, MsToken, SignUpData, User } from '../../interfaces/interfacesApp'
import { FeathersError } from '@feathersjs/errors'

const localStorageToken = window.localStorage.getItem('feathers-users-token')

interface LoginInfo {
  accessToken: string
  user?: any
}

export enum LoginState {
  USER_NOT_LOGGED_IN = 'user-not-logged',
  USER_AUTH_ONGOING = 'user-auth-ongoing',
  USER_AUTH_OK = 'user-auth-ok',
  USER_AUTH_ERROR = 'user-auth-error',
  USER_LOGIN_OUT = 'logging-out',
  USER_LOGIN_OUT_ERROR = 'error-logout',
  MS_RETRIEVING_TOKENS_ONGOING = 'ms-tokens-ongoing',
  MS_RETRIEVING_TOKENS_OK = 'ms-tokens-ok',
  MS_RETRIEVING_TOKENS_ERROR = 'ms-tokens-ERROR',
  MS_AUTH_WITH_TOKENS_ONGOING = 'ms-auth-ongoing',
  MS_AUTH_WITH_TOKENS_OK = 'ms-auth-ok',
  MS_AUTH_WITH_TOKENS_ERROR = 'ms-auth-error',
  USER_CONTEXT_INITIALIZATION = 'user-ctx-init',
  USER_SUCCESSFULLY_LOGGED_IN = 'user-logged-in',
}

export type State = {
  token: string | null, // Token to be used to authenticate if available (from user token or URL token provided)
  error: string | null,
  status: LoginState,
  user: User | null,
  msTokens: MsToken[] | null,
  boot: string | null
}

const initialState: State = {
  token: localStorageToken || null,
  error: null,
  status: LoginState.USER_NOT_LOGGED_IN,
  user: null,
  msTokens: null,
  boot: null
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUserUpdate (state, action: PayloadAction<User>) {
      return {
        ...state,
        user: action.payload
      }
    },
    loginCleanUp (state) {
      return {
        ...state,
        error: null,
        token: null,
        status: LoginState.USER_NOT_LOGGED_IN
      }
    },
    loginRequest (state) {
      return {
        ...state,
        error: null,
        status: LoginState.USER_AUTH_ONGOING
      }
    },
    loginSuccess (state, action: PayloadAction<LoginInfo>) {
      const token = action.payload.accessToken
      const user = action.payload.user

      return {
        ...state,
        status: LoginState.USER_AUTH_OK,
        token,
        user
      }
    },
    loginError (state, action: PayloadAction<string>) {
      const errorMessage = action.payload
      return {
        ...state,
        status: LoginState.USER_AUTH_ERROR,
        token: null,
        error: errorMessage
      }
    },
    msTokensRequest (state) {
      return {
        ...state,
        status: LoginState.MS_RETRIEVING_TOKENS_ONGOING
      }
    },
    msTokensSuccess (state, action) {
      const msTokens = action.payload
      return {
        ...state,
        msTokens, // Store tokens for all ms
        status: LoginState.MS_RETRIEVING_TOKENS_OK
      }
    },
    msTokensError (state, action: PayloadAction<string>) {
      const errorMessage = action.payload
      return {
        ...state,
        token: null,
        error: errorMessage,
        status: LoginState.MS_RETRIEVING_TOKENS_ERROR
      }
    },
    msAuthRequest (state) {
      return {
        ...state,
        status: LoginState.MS_AUTH_WITH_TOKENS_ONGOING
      }
    },
    msAuthSuccess (state) {
      return {
        ...state,
        status: LoginState.MS_AUTH_WITH_TOKENS_OK
      }
    },
    msAuthError (state, action: PayloadAction<string>) {
      const errorMessage = action.payload
      return {
        ...state,
        status: LoginState.MS_AUTH_WITH_TOKENS_ERROR,
        token: null,
        error: errorMessage
      }
    },
    logoutRequest (state) {
      return {
        ...state,
        error: null,
        status: LoginState.USER_LOGIN_OUT
      }
    },
    logoutSuccess (state) {
      return {
        ...state,
        status: LoginState.USER_NOT_LOGGED_IN,
        token: null,
        msTokens: null,
        boot: 'none',
        id: null,
        user: null
      }
    },
    logoutError (state, action: PayloadAction<string>) {
      const errorMessage = action.payload
      return {
        ...state,
        status: LoginState.USER_LOGIN_OUT_ERROR,
        error: errorMessage
      }
    },
    bootRequest (state) {
      return {
        ...state,
        status: LoginState.USER_CONTEXT_INITIALIZATION,
        error: null,
        boot: 'booting'
      }
    },
    bootSuccess (state) {
      return {
        ...state,
        status: LoginState.USER_SUCCESSFULLY_LOGGED_IN,
        boot: 'booted'
      }
    },
    bootError (state, action: PayloadAction<string>) {
      const errorMessage = action.payload
      return {
        ...state,
        status: LoginState.USER_LOGIN_OUT_ERROR,
        error: errorMessage
      }
    },
    storeURLToken (state, action: PayloadAction<string>) {
      return {
        ...state,
        token: action.payload
      }
    }
  }
})

export const {
  loginCleanUp, loginUserUpdate,
  loginRequest, loginSuccess, logoutError,
  logoutRequest, logoutSuccess, loginError,
  msTokensRequest, msTokensSuccess, msTokensError,
  msAuthRequest, msAuthSuccess, msAuthError,
  bootRequest, bootSuccess, bootError, storeURLToken
} = loginSlice.actions

export type StoreURLToken = (token:string) => void

export type LoginCleanUp = () => void

export type LoginJWTThunk = (token: string, usersClient : FeathersClient) => void

export const loginJWTThunk = (token: string, usersClient : FeathersClient): AppThunk => async dispatch => {
  try {
    dispatch(loginRequest())

    await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)) // forcd delay to avoid buggy looking UX....

    const data = await usersClient.authenticate({
      strategy: 'jwt',
      accessToken: token
    })
    await (usersServices as FeathersServices).tokenretriever.find({})
    dispatch(loginSuccess(data))
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(loginError(errorMessage))
  }
}

export type LoginThunk = (credentials: Credentials, usersClient: FeathersClient) => void

export const loginThunk = (credentials: Credentials, usersClient: FeathersClient): AppThunk => async dispatch => {
  try {
    dispatch(loginRequest())
    const data = await usersClient.authenticate({
      strategy: 'local',
      ...credentials
    })

    await (usersServices as FeathersServices).tokenretriever.find({})
    dispatch(loginSuccess(data))
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(loginError(errorMessage))
  }
}

export type SignUpThunk = (data: FeathersOpaqueData) => void

export const signupThunk = (data: FeathersOpaqueData): AppThunk => async dispatch => {
  try {
    await dispatch(usersServices.users.create(data))
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    console.log(`signupThunk error: ${errorMessage}`)
    // dispatch(loginError(errorMessage))
  }
}

export type SignUpUpdateThunk = (data: FeathersOpaqueData) => void

export const signUpUpdateThunk = (id: string | undefined, data: SignUpData): AppThunk => async dispatch => {
  try {
    await dispatch(usersServices.users.update(id, data))
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    console.log(`signUpUpdateThunk error: ${errorMessage}`)
    // dispatch(loginError(errorMessage))
  }
}

export type LogoutThunk = () => void

export const logoutThunk = () : AppThunk => async dispatch => {
  try {
    dispatch(logoutRequest())
    await usersClient.logout()
    await podsClient.logout()
    await pollsClient.logout()
    await mediasClient.logout()
    await postsClient.logout()
    window.localStorage.clear()
    dispatch(logoutSuccess())
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(logoutError(errorMessage))
  }
}

export type RemoveFeathersTokenThunk = () => void

export const removeFeathersTokenThunk = (): AppThunk => async dispatch => {
  try {
    dispatch(logoutRequest())
    await usersClient.logout()
    await podsClient.logout()
    await pollsClient.logout()
    await mediasClient.logout()
    await postsClient.logout()
    dispatch(logoutSuccess())
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(logoutError(errorMessage))
  }
}

export type MsTokensThunk = () => void

export const msTokensThunk = (): AppThunk => async dispatch => {
  try {
    dispatch(msTokensRequest())
    // const data1 = await dispatch((usersServices as FeathersServices).tokenretriever.find({}))
    // console.log('data1', data1)
    const msTokens = await (usersClient as FeathersClient).services.tokenretriever.find({})
    dispatch(msTokensSuccess(msTokens))
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(msTokensError(errorMessage))
  }
}

// MEDIAS APP
export type MsAuthThunk = (podsClient: FeathersClient, pollsClient: FeathersClient, postsClient: FeathersClient, mediasClient: FeathersClient, msTokens: MsToken[]) => void

export const msAuthThunk = (podsClient: FeathersClient, pollsClient: FeathersClient, postsClient: FeathersClient, mediasClient: FeathersClient, msTokens: MsToken[]): AppThunk => async dispatch => {
  if (msTokens?.length === 0) { return }
  try {
    dispatch(msAuthRequest())
    await podsClient.authenticate({
      strategy: 'jwt',
      accessToken: msTokens[0].token // TODO: get properly tokens
    })
    await pollsClient.authenticate({
      strategy: 'jwt',
      accessToken: msTokens[1].token // TODO: get properly tokens
    })
    await postsClient.authenticate({
      strategy: 'jwt',
      accessToken: msTokens[2].token // TODO: get properly tokens
    })
    // MEDIAS APP
    await mediasClient.authenticate({
      strategy: 'jwt',
      accessToken: msTokens[3].token // TODO: get properly tokens
    })
    dispatch(msAuthSuccess())
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    dispatch(msAuthError(errorMessage))
  }
}

export type BootThunk = (
  usersClient: FeathersClient,
  podsClient : FeathersClient,
  pollsClient : FeathersClient,
  postsClient: FeathersClient,
  mediasClient: FeathersClient,
  loginId: string) => void

export const bootThunk = (
  usersClient: FeathersClient,
  podsClient : FeathersClient,
  pollsClient : FeathersClient,
  postsClient: FeathersClient,
  mediasClient: FeathersClient, // MEDIAS APP
  loginId: string) : AppThunk => async dispatch => {
  try {
    dispatch(bootRequest())
    await dispatch((usersServices as FeathersServices).users.find({ query: { loginId } }))

    const usersClientService = usersClient.service('/users')
    usersClientService.on('created', (data: FeathersOpaqueData) => { dispatch((usersServices as FeathersServices).users.onCreated(data)) })
    usersClientService.on('updated', (data: FeathersOpaqueData) => { dispatch((usersServices as FeathersServices).users.onUpdated(data)) })
    usersClientService.on('patched', (data: FeathersOpaqueData) => { dispatch((usersServices as FeathersServices).users.onPatched(data)) })
    usersClientService.on('removed', (data: FeathersOpaqueData) => { dispatch((usersServices as FeathersServices).users.onRemoved(data)) })

    await dispatch((podsServices as FeathersServices).pods.find())
    const podsClientService = podsClient.service('/pods')
    podsClientService.on('created', (data: FeathersOpaqueData) => { dispatch((podsServices as FeathersServices).pods.onCreated(data)) })
    podsClientService.on('updated', (data: FeathersOpaqueData) => { dispatch((podsServices as FeathersServices).pods.onUpdated(data)) })
    podsClientService.on('patched', (data: FeathersOpaqueData) => { dispatch((podsServices as FeathersServices).pods.onPatched(data)) })
    podsClientService.on('removed', (data: FeathersOpaqueData) => { dispatch((podsServices as FeathersServices).pods.onRemoved(data)) })

    await dispatch((pollsServices as FeathersServices).polls.find())
    const pollsClientService = pollsClient.service('/polls')
    pollsClientService.on('created', (data: FeathersOpaqueData) => { dispatch((pollsServices as FeathersServices).polls.onCreated(data)) })
    pollsClientService.on('updated', (data: FeathersOpaqueData) => { dispatch((pollsServices as FeathersServices).polls.onUpdated(data)) })
    pollsClientService.on('patched', (data: FeathersOpaqueData) => { dispatch((pollsServices as FeathersServices).polls.onPatched(data)) })
    pollsClientService.on('removed', (data: FeathersOpaqueData) => { dispatch((pollsServices as FeathersServices).polls.onRemoved(data)) })

    /* await dispatch((postsServices as FeathersServices).posts.find()) */
    const postsClientService = postsClient.service('/posts')
    postsClientService.on('created', (data: FeathersOpaqueData) => { dispatch((postsServices as FeathersServices).posts.onCreated(data)) })
    postsClientService.on('updated', (data: FeathersOpaqueData) => { dispatch((postsServices as FeathersServices).posts.onUpdated(data)) })
    postsClientService.on('patched', (data: FeathersOpaqueData) => { dispatch((postsServices as FeathersServices).posts.onPatched(data)) })
    postsClientService.on('removed', (data: FeathersOpaqueData) => { dispatch((postsServices as FeathersServices).posts.onRemoved(data)) })

    await dispatch((usersServices as FeathersServices).relationships.find())
    const relationshipsClientService = usersClient.service('/relationships')
    relationshipsClientService.on('created', (data: any) => { dispatch((usersServices as any).relationships.onCreated(data)) })
    relationshipsClientService.on('updated', (data: any) => { dispatch((usersServices as any).relationships.onUpdated(data)) })
    relationshipsClientService.on('patched', (data: any) => { dispatch((usersServices as any).relationships.onPatched(data)) })
    relationshipsClientService.on('removed', (data: any) => { dispatch((usersServices as any).relationships.onRemoved(data)) })

    await dispatch((usersServices as FeathersServices).notifications.find())
    const notificationsClientService = usersClient.service('/notifications')
    notificationsClientService.on('created', (data: any) => { dispatch((usersServices as any).notifications.onCreated(data)) })
    notificationsClientService.on('updated', (data: any) => { dispatch((usersServices as any).notifications.onUpdated(data)) })
    notificationsClientService.on('patched', (data: any) => { dispatch((usersServices as any).notifications.onPatched(data)) })
    notificationsClientService.on('removed', (data: any) => { dispatch((usersServices as any).notifications.onRemoved(data)) })

    // MEDIAS ADD
    await dispatch((mediasServices as FeathersServices).medias.find())
    const mediasClientService = mediasClient.service('/medias')
    mediasClientService.on('created', (data: any) => { dispatch((mediasServices as any).medias.onCreated(data)) })
    mediasClientService.on('updated', (data: any) => { dispatch((mediasServices as any).medias.onUpdated(data)) })
    mediasClientService.on('patched', (data: any) => { dispatch((mediasServices as any).medias.onPatched(data)) })
    mediasClientService.on('removed', (data: any) => { dispatch((mediasServices as any).medias.onRemoved(data)) })

    dispatch(bootSuccess())
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    console.log('error', errorMessage)
    dispatch(bootError(errorMessage))
  }
}

export default loginSlice.reducer
