import { Avatar, makeStyles, Typography } from '@material-ui/core'
import React, { memo, ReactElement } from 'react'
import { Pod } from '../../interfaces/interfacesApp'
import { getColorFromID } from '../../lib/utils'
export interface AvatarPodProps {
  pod: Pod,
  width?: string,
  height?: string,
  fontSize?: string,
  backgroundColor?: string,
  opacity?: string,
  borderRadius?: string,
  color?: any
}

const useStyles = makeStyles(() => ({
  AvatarPos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  initPos: {
    position: 'absolute'
  }
}))

const AvatarPod = ({ pod, width, height, fontSize, backgroundColor, opacity, borderRadius } : AvatarPodProps): ReactElement => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.AvatarPos}>
        <Avatar
          variant='rounded'
          style={{ width, height, backgroundColor, opacity, borderRadius, display: 'flex', justifyContent: 'center' }}
          src=' '
          alt=' '
        />
        <Typography className={classes.initPos} style={{ color: getColorFromID(pod._id).toString(), fontSize: fontSize }}>{pod.title.charAt(0)}</Typography>
      </div>
    </>)
}

export default memo(AvatarPod)
