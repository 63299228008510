import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { usersServices, usersClient } from '../../App'

import { FeathersClient, FeathersServices } from '../../interfaces/interfacesFeathers'

const relationshipsSlice = createSlice({
  name: 'relationshipsControl',
  initialState: {},
  reducers: {
    relationshipsCreateRequest (state) {
      return {
        ...state,
        create: 'creating'
      }
    },
    relationshipsCreateSuccess (state) {
      return {
        ...state,
        create: 'done'
      }
    },
    relationshipsCreateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        create: 'error',
        createError: errorMessage
      }
    },
    relationshipsUpdateRequest (state) {
      return {
        ...state,
        update: 'updating'
      }
    },
    relationshipsUpdateSuccess (state) {
      return {
        ...state,
        update: 'updated'
      }
    },
    relationshipsUpdateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        update: 'error',
        updateError: errorMessage
      }
    }
  }
})

export const {
  relationshipsCreateRequest, relationshipsCreateSuccess, relationshipsCreateError,
  relationshipsUpdateRequest, relationshipsUpdateSuccess, relationshipsUpdateError
} = relationshipsSlice.actions

export type RelationshipCreateThunk = (data: any, setIdCreate: any, title: string) => void

export const relationshipCreateThunk = (data: any, setIdCreate: any, title: string): AppThunk => async dispatch => {
  try {
    // await relationshipsServices.relationships.create(data)
    dispatch(relationshipsCreateRequest())
    const ev = await (usersClient as FeathersClient).service('/relationships').create(data)
    setIdCreate(ev._id)
    await dispatch((usersServices as FeathersServices).relationships.find()) // TODO: use result TODO: do we need a new find BTW
    dispatch(relationshipsCreateSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(relationshipsCreateError(e.message))
  }
}

export type RelationshipUpdateThunk = (relationship: any, status: any) => void

export const relationshipUpdateThunk = (relationship: any, status: any): AppThunk => async dispatch => {
  try {
    dispatch(relationshipsUpdateRequest())
    await (usersServices as FeathersServices).relationships.patch(relationship._id, { status: status })
    // await store.dispatch(relationshipsServices.relationships.find()) // TODO: use result TODO: do we need a new find BTW
    dispatch(relationshipsUpdateSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(relationshipsUpdateError(e.message))
  }
}

export default relationshipsSlice.reducer
