import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { StyledNavBarButton } from '../Styled/StyledNavBarButton'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'

import { useMatomo } from '@datapunt/matomo-tracker-react'
import i18n from '../../i18n'

export const useStyles = makeStyles(() => ({
  textEntry0: {
    position: 'relative',
    top: 10
  },

  textfields: {
    marginTop: '3.5rem',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: 'auto'
  },

  textfield: {
    marginTop: '1.5rem'
  },

  nextButton: {
    alignSelf: 'right',
    position: 'fixed',
    right: '1rem',
    zIndex: 1100
  },

  dateInput: {
    backgroundColor: '#ededed'
  }
}))

interface LayoutPodCreateProps {
    setRedirect : any
    onCreatePod : any
    onboarding: number
}

export default function LayoutPodName (props: LayoutPodCreateProps) : ReactElement {
  const [title, setTitle] = useState('')
  const [date, setDate] = useState(null)
  const [desc, setDesc] = useState('')
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const [openDatePicker, setOpenDatePicker] = useState(false)

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Viewing pod creation'
    })
  }, [])

  return (
    <>
      <AppBar position='fixed' color='default'>
        <Toolbar>
          {props.onboarding === 1
            ? null
            : <IconButton onClick={() => { props.setRedirect(false) }}><ChevronLeft style={{ color: 'black' }} /></IconButton>}
          <Typography variant='h6'>{t('pods.podCreate.pageTitle')}</Typography>
          {title === ''
            ? <StyledNavBarButton color='primary' variant='contained' className={classes.nextButton} disabled>{t('pods.podCreate.button')}</StyledNavBarButton>
            : <StyledNavBarButton color='primary' variant='contained' className={classes.nextButton} onClick={() => props.onCreatePod({}, title, date, desc)}>{t('pods.podCreate.button')}</StyledNavBarButton>}
        </Toolbar>
      </AppBar>

      <div className={classes.textfields}>
        <TextField
          fullWidth onChange={(text: any) => setTitle(text.target.value)}
          id='filled-basic' label={t('pods.podCreate.defaultTitle')} value={title} variant='filled' className={classes.textfield}
          autoFocus
        />
        <MuiPickersUtilsProvider locale={i18n.language === 'en' ? en : fr} utils={DateFnsUtils}>
          <KeyboardDatePicker
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            fullWidth
            autoOk
            format='dd/MM/yyyy'
            label={t('pods.podCreate.defaultDate')}
            onChange={(date: any) => setDate(date)}
            value={date}
            variant='inline'
            inputVariant='filled'
            className={classes.textfield}
            InputProps={{ readOnly: true, onClick: () => setOpenDatePicker(true) }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          fullWidth onChange={(text: any) => setDesc(text.target.value)}
          className={classes.textfield} label={t('pods.podCreate.defaultDesc')} value={desc} variant='filled'
        />
        <div>
          <Typography component='span'>
            <Box fontSize='14px' textAlign='center' color='gray'>{t('pods.podCreate.inputDescription')}</Box>
          </Typography>
        </div>
      </div>
    </>
  )
}
