/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import NavBar from '../Menus/NavBar'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import ListItemUser from '../Widgets/ListItemUser'
import { v4 as uuidv4 } from 'uuid'
import WidgetDisplayInfo from '../Widgets/WidgetDisplayInfo'
import EmptyRelationshipIcon from '../../assets/15--sad--face-emoticons-emoji.svg'
import LayoutConfirmRelationShip from './LayoutConfirmRelationship'
import FeedbackDialog, { showFeedbackDialog } from '../Popup/FeedbackDialog'
import { MainBottomBarSelectedItemType } from '../Menus/MainBottomBar'

const useStyles = makeStyles((theme) => ({
  text: {
    verticalAlign: 'middle',
    fontSize: '16px',
    fontFamily: 'Roboto'
  },
  dot: {
    height: '30px',
    width: '30px',
    backgroundColor: 'grey',
    borderRadius: '50%',
    display: 'inline-block',
    position: 'absolute',
    textAlign: 'center'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column'
  },
  inline: {
    display: 'inline',
    fontSize: '16px',
    fontFamily: 'Roboto'
  }
}))

interface LayoutRelationshipsProps {
  relationships : any[]
  onConfirmRelationship: any
  users: any[]
  userId: string
}

export default function LayoutRelationships ({ relationships, onConfirmRelationship, users, userId } : LayoutRelationshipsProps): ReactElement {
  const [confirmRelationship, setConfirmRelationship] = useState(null)
  const { t } = useTranslation('translation')
  const relationshipsConfirmed = relationships.filter(r => r.status === 'Confirmed')
  const relationshipsWaiting = relationships.filter(r => r.requesterId === userId && r.status === 'Pending')
  const relationshipsPending = relationships.filter(r => r.requestedId === userId && r.status === 'Pending')
  const [openFeedback, setOpenFeedback] = useState(false)
  const [messageFeedback, setMessageFeedback] = useState('')
  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Relationships'
    })
  }, [])

  function getOther (relation : any) {
    if (relation.requesterId === userId) {
      return (users.find(u => u._id === relation.requestedId))
    } else {
      return (users.find(u => u._id === relation.requesterId))
    }
  }
  // type is any because component can be null, let it be for now ...
  function LayoutRelationshipsConfirmed () : any {
    return (relationshipsConfirmed.map((relation : any) => {
      return (
        <div key={uuidv4()}>
          <ListItemUser
            key={uuidv4()}
            index={1}
            user={getOther(relation)}
          />
        </div>
      )
    })
    )
  }

  function LayoutRelationshipsPending () : any {
    return (relationshipsPending.map((relation : any, index : number) => {
      return (
        <div key={uuidv4()}>
          <ListItemUser
            index={1}
            user={getOther(relation)}
            badgeText={t('friends.pending')}
            onBadge={() => { setConfirmRelationship(relationshipsPending[index]) }}
          />
        </div>
      )
    })
    )
  }

  function LayoutRelationshipsWaiting () : any {
    // If no relationship.
    return (relationshipsWaiting.map((relation : any, index : number) => {
      return (
        <div key={uuidv4()}>
          <ListItemUser
            index={1}
            user={getOther(relation)}
            badgeText={t('friends.waiting')}
          />
        </div>
      )
    })
    )
  }

  if (confirmRelationship != null) {
    const user = getOther(confirmRelationship)
    const id = (confirmRelationship as any)._id
    return (
      <LayoutConfirmRelationShip
        setConfirmRelation={() => { setConfirmRelationship(null) }}
        onConfirm={(v: boolean) => {
          onConfirmRelationship(id, v ? 'Confirmed' : 'Rejected')
          v
            ? showFeedbackDialog(1000, t('invite.accepted'), setMessageFeedback, setOpenFeedback)
            : showFeedbackDialog(1000, t('invite.rejected'), setMessageFeedback, setOpenFeedback)
          setConfirmRelationship(null)
        }}
        user={user}
      />
    )
  }

  return (
    <>
      <NavBar title={t('friends.title')} mainBottomBar={MainBottomBarSelectedItemType.RELATIONSHIPS} />
      {relationships.length === 0 ? <WidgetDisplayInfo title={t('emptyRelationship.title')} desc1={t('emptyRelationship.desc1')} desc2={t('emptyRelationship.desc2')} image={EmptyRelationshipIcon} /> : null}
      <Paper>
        <LayoutRelationshipsPending />
        <LayoutRelationshipsWaiting />
        <LayoutRelationshipsConfirmed />
      </Paper>
      <FeedbackDialog open={openFeedback} success description={messageFeedback} />
    </>
  )
}
