import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Badge from '@material-ui/core/Badge'
import { PollOption, ModuleType, User, Poll } from '../../interfaces/interfacesApp'
import { didUserVoted, didUserVotedOption, getChosenOptions, getNumberOfVotes, getVotersByOption, isOptionChecked, isLoggedInUserChoice } from '../../lib/utils'
import AvatarGroupUser from '../Widgets/AvatarGroupUser'

const useStyles = makeStyles(() => ({
  roundedCorner: {
    borderRadius: '25px',
    background: '#F2F2F2',
    textAlign: 'center',
    position: 'relative',
    width: '30px',
    height: '20px'
  },
  roundedCornerChecked: {
    borderRadius: '25px',
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    width: '30px',
    height: '20px'
  },
  centerText: {
    position: 'relative',
    bottom: 2
  },
  strikethroughText: {
    textDecoration: 'line-through'
  },
  link: {
    textDecoration: 'none'
  },
  customBadge: {
    backgroundColor: 'lightGray',
    color: 'white'
  },
  clickable: {
    pointerEvents: 'auto' // remains clickable even if disabled
  }
}))

interface LayoutPollChoicesProps {
    type: ModuleType,
    userId : string,
    friendIds: string[]
    onToggleChoice : any,
    users : User[],
    poll : Poll
    selectedItemCallback: (option : PollOption) => void
}

export const LayoutPollChoices = ({ type, userId, friendIds, onToggleChoice, users, poll, selectedItemCallback } : LayoutPollChoicesProps) : any => {
  const classes = useStyles()
  const options = poll.options
  const choices = poll.choices
  const enableAnonymousChoice = poll.config ? poll.config?.enableAnonymousChoice : false
  const enableMultipleUserPerOption = poll.config ? poll.config?.enableMultipleUserPerOption : true
  const enableMultipleChoices = poll.config ? poll.config?.enableMultipleChoices : true

  let chosenOptions: PollOption[] = []

  if (type === ModuleType.LIST_N || type === ModuleType.LIST) {
    chosenOptions = getChosenOptions(options, choices)
  }

  return chosenOptions.map((option: PollOption) => {
    const voters = getVotersByOption(choices, option.id, users)
    const checked = isLoggedInUserChoice(option.id, choices, userId)

    const isDisabled = (enableMultipleUserPerOption === false) && !checked // here !checked = voted by another person

    function Voters () : any {
      return (
        <div className={classes.clickable}>
          {enableAnonymousChoice
            ? (voters.length > 0 && <Badge badgeContent={voters.length} classes={{ badge: classes.customBadge }} />)
            : <AvatarGroupUser users={voters} currentUserId={userId} friendsIDs={friendIds} />}
        </div>
      )
    }

    // case were we cannot choose multiple options, but we still need to show the grey square if someone voted for an option
    // horrible but it works, sorry, here the reasoning :
    /*
    case when display checkbox:

    - enable = true
    - enable = false + user didn't voted
    - enable = false + user voted for this option
    - enable = false + someone else voted this option

    case when we display but have to disable disable:

    - enable = false + user voted + not for this option
    */
    const isDisabled2 = (!enableMultipleChoices && didUserVoted(choices, userId) && !didUserVotedOption(choices, option.id, userId))
    const handleClick = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      onToggleChoice(option.id, userId)
    }
    const listItemTextPrimaryClass = enableMultipleUserPerOption ? {} : { primary: classes.strikethroughText }
    const listItemTextClass = { root: classes.clickable, ...listItemTextPrimaryClass }
    return (
      <>
        <ListItem button divider key={option.id} onClick={() => selectedItemCallback(option)}>
          <ListItemIcon>
            {(enableMultipleChoices ||
            isOptionChecked(choices, option.id) ||
            ((getNumberOfVotes(choices, userId) > 0) ? didUserVotedOption(choices, option.id, userId) : true)
            ) &&
            !isDisabled2
              ? (
                <Checkbox
                  color='primary'
                  edge='start'
                  checked={checked || isDisabled}
                  disabled={isDisabled || isDisabled2}
                  tabIndex={-1}
                  disableRipple
                  onClick={handleClick}
                />
              )
              : null}
          </ListItemIcon>
          <ListItemText primary={option.text} classes={listItemTextClass} />
          {(voters.length !== 0)
            ? (
              <Voters />
            )
            : null}
        </ListItem>
      </>
    )
  })
}
