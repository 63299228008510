import { usersServices } from '../../App'

import { AppThunk } from '../../index'

import { NotificationData } from '../../interfaces/interfacesApp'

export type UserNotificationDeleteThunk = (notifications: NotificationData[]) => void

export const userNotificationDeleteThunk = (notifications: NotificationData[]): AppThunk => async dispatch => {
  try {
    const notificationIds: string[] = []
    notifications.forEach((notification: NotificationData) => notificationIds.push(notification._id as string))
    await dispatch(usersServices.notifications.remove(null, { query: { notificationIds } }))
  } catch (e) {
    console.log('error', e)
  }
}
