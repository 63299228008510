import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledFab } from '../Styled/StyledFab'

const useStyles = makeStyles(() => ({
  pollStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: '1rem',
    marginRight: '1rem',
    position: 'fixed',
    bottom: '70px',
    right: '0',
    zIndex: 1100
  },
  icon: {
    display: 'flex',
    flexDirection: 'column'
  },
  moduleStyle: {
    marginLeft: '1rem',
    marginRight: '1rem',
    position: 'fixed',
    bottom: '20px',
    right: '-100%'
  }
}))

interface WidgetCreateFabButtonProps {
    onAdd: any,
    type: string
  }

const WidgetCreateFabButton = ({ onAdd, type } : WidgetCreateFabButtonProps) : ReactElement => {
  const classes = useStyles()

  const { t } = useTranslation('translation')
  return (
    <>
      <div className={type === 'poll' ? classes.pollStyle : classes.moduleStyle}>
        <div className={classes.icon}>
          <StyledFab variant='extended' onClick={() => onAdd()}><Add />{type === 'poll' ? t('pods.new') : t('module.new')}</StyledFab>
        </div>
      </div>
    </>
  )
}

export default WidgetCreateFabButton
