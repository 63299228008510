import React, { ReactElement, useState } from 'react'

import TextField from '@material-ui/core/TextField/TextField'
import ListItem from '@material-ui/core/ListItem/ListItem'
import AddBoxIcon from '@material-ui/icons/AddBox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: '-3px',
    paddingRight: '1rem',
    paddingTop: '1.3rem',
    color: 'darkgrey'
  }
}))

export interface ListItemAddOptionProps {
  /** function to execute on chevron click, if not set chevron is not displayer */
  onAddOption?: any
  setToggleChoice?: any
}

const ListItemAddOption = (props : ListItemAddOptionProps) : ReactElement => {
  const classes = useStyles()
  const [option, setOption] = useState('')
  const { t } = useTranslation('translation')
  // Matomo tracking
  const { trackEvent } = useMatomo()

  const onAddOption = (option: any) => {
    if (option !== '') {
      trackEvent({ category: 'add option', action: option })
      props.onAddOption(option)
      setOption('')
    }
  }

  return (
    <ListItem>
      <div onClick={() => onAddOption(option)}> <AddBoxIcon className={classes.large} /> </div>
      <TextField
        autoFocus
        fullWidth id='standard' label={t('polls.addOption')} value={option}
        onChange={e => setOption(e.target.value)}
        onKeyPress={e => { if (e.key === 'Enter') { onAddOption(option); setOption('') } }}
        onBlur={() => { onAddOption(option); setOption(''); props.setToggleChoice(false) }}
      />
    </ListItem>
  )
}

export default ListItemAddOption
