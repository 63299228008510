import React, { useState, ReactElement } from 'react'
import LayoutPods from '../../components/Layouts/LayoutPods'
import { connect } from 'react-redux'
import { podCreateThunk, PodCreateThunk, podTrackTarget, PodTrackTarget } from '../Pods/podsSlice'
import { ResendVerifyTokenThunk, resendVerifyTokenThunk } from '../VerifyToken/verifyTokenSlice'
import { Redirect } from 'react-router-dom'
import { Pod, User } from '../../interfaces/interfacesApp'

const mapDispatch = { podCreateThunk, podTrackTarget, resendVerifyTokenThunk }

export interface PodsProps {
  pods: any
  relationships: any
  userId: string | null
  userIsVerified: boolean | null
  userVerifyToken: string | null
  email: string | null
  podCreateThunk: PodCreateThunk
  user: User | null
  podTrackTarget: PodTrackTarget // Action to update the target pod id to track
  trackPodIdTarget: string // Curren tvalue of the pod id to track
  resendVerifyTokenThunk: ResendVerifyTokenThunk
}

const Pods = (props : PodsProps): ReactElement => {
  const [idCreate, setIdCreate] = useState(null)
  const pods = props.pods.queryResult.data || props.pods.queryResult
  // prettify users
  // pods.forEach(e => e.usersConfirmed.reduce((a,v,i) => (i > 0) ? `${a} / ${v}` : v, ''))

  if (idCreate != null) {
    return (<Redirect push to={`/pod/${idCreate}`} />)
  }

  // update pod id to track only if necessary
  if (props.trackPodIdTarget !== 'pods') { props.podTrackTarget('') }

  /* const invites = pods.filter(e =>
    ((e.usersPending.findIndex(e => e === props.email) !== -1)
    || (e.usersMaybe.findIndex(e => e === props.email) !== -1))) */

  const podsSorted = pods.map((e: any) => {
    const out = Object.assign({}, e)
    out.isInvite = (out.usersPending.findIndex((e: any) => e === props.userId) !== -1) || (e.usersMaybe.findIndex((e: any) => e === props.userId) !== -1)
    return out
  })/* .sort((a : any, b : any) => {
    if (a.isInvite && !b.isInvite) { return -1 }
    if (!a.isInvite && b.isInvite) { return 1 }
    return 0
  }) */ // now it is donne in layoutPods because we want to sort also by updates

  const relationships = props.relationships.queryResult.data || props.relationships.queryResult
  const notifyUserRequest = relationships.filter((r: any) => (r != null && r.requestedId === props.userId && r.status === 'Pending')).length > 0

  let onboarding = 3

  if (props.user !== undefined) {
    if (props.user?.onboarding !== undefined) {
      onboarding = props.user.onboarding
      if (onboarding === 0) {
        return <Redirect to='/onboarding' />
      }
    } else {
      console.log('undefined onboarding status')
      return <Redirect to='/onboarding' />
    }
  }

  return (
    <div>
      <LayoutPods
        notifyUserRequest={notifyUserRequest}
        user={props.user}
        pods={podsSorted}
        onCreatePod={(data: any, title : string, date: Date, desc: string) => { props.podCreateThunk(data, setIdCreate, title, date, desc, props?.user) }}
        onboarding={onboarding}
        onResendVerifyToken={props.userVerifyToken ? () => { props.resendVerifyTokenThunk(props.email || '') } : undefined}
      />
    </div>
  )
}

interface PodsState {
  login : {
    id: string
    user: User
  }
  pods: Pod[]
  users: any
  relationships: any[]
  podsSlice : {
    trackPodIdTarget: string
  }
}

const mapState = (state : PodsState) => {
  return ({
    pods: state.pods,
    userId: state?.login?.id,
    email: state?.login?.user?.email || null,
    userIsVerified: !(state?.login?.user?.isVerified === false),
    userVerifyToken: state?.login?.user?.isVerified ? null : state?.login?.user?.verifyToken, // If already Verified dont add the verify token
    relationships: state.relationships,
    user: state?.login?.user,
    trackPodIdTarget: state.podsSlice.trackPodIdTarget
  })
}

export default connect(mapState, mapDispatch)(Pods)
