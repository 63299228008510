import React, { ReactElement, memo } from 'react'
import Debug from 'debug'
import { useDispatch, useSelector } from 'react-redux'
import { loginUserUpdate } from '../../features/Login/loginSlice'
import { AppState } from '../../interfaces/AppState'
import { User } from '../../interfaces/interfacesApp'
import i18n from '../../i18n'

const debug = Debug('Listeners:ListenerLoggedUser')

const ListenerLoginUser = () : ReactElement => {
  debug('got a users change')
  const loginUser: User | undefined = useSelector((state: AppState) =>
    state.users.queryResult?.find((u: User) => u._id === state?.login?.user?._id))

  const dispatch = useDispatch()

  if (loginUser != null) {
    debug('got a logged user %o', loginUser)
    i18n.changeLanguage(loginUser.locale)
    dispatch(loginUserUpdate(loginUser))
  } else {
    debug('no logged user')
  }

  return <></>
}

export default memo(ListenerLoginUser)
