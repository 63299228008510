import React, { FC, memo, useEffect, useState } from 'react'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'
import { Button, IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import Debug from 'debug'

const debug = Debug('ListenerServiceWorker:')

const ListenerServiceWorker: FC = () => {
  const [showReload, setShowReload] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
  const { t } = useTranslation('translation')

  debug(`waitingWorker:${waitingWorker}, showReload:${showReload}`)

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    debug(`onSWUpdate installing: ${registration.installing}, waiting: ${registration.waiting}, registration:`, registration)
    setWaitingWorker(registration.waiting)
    setShowReload(true)
    return null
  }

  // Register Service worker at startup
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    debug('useEffect: register serviceWorker')
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate })
  }, [])

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    }
    setShowReload(false)
    window.location.reload()
  }

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setShowReload(false)
  }

  const handleClick = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    reloadPage()
  }

  debug('Render')
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showReload && (waitingWorker !== null)}
      message={t('update.message')}
      key={'bottom' + ' ' + 'center'}
      onClose={handleClose}
      action={
        <>
          <Button color='inherit' size='small' onClick={handleClick}>
            {t('update.action')}
          </Button>
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      }
    />
  )
}

export default memo(ListenerServiceWorker)
