import React, { ReactElement } from 'react'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import Login from './features/Login/Login'
import SignUp from './features/Login/SignUp'
import ConnectionProvider from './features/Login/ConnectionProvider'
import Pods from './features/Pods/Pods'
import Invites from './features/Pods/Invites'
import PodTracker from './features/Pods/PodTracker'
import PodEdit from './features/Pods/PodEdit'
import PagePodInvite from './components/Pages/PagePodInvite'
import ListenerPodInvite from './components/Listeners/ListenerPodInvite'
import PagePoll from './components/Pages/PagePoll'
import InvitationFriends from './features/InvitationFriends/InvitationFriends'
import InvitationLink from './features/InvitationLink/InvitationLink'
import Relationships from './features/Relationships/Relationships'
import PersonDetails from './features/PersonDetails/PersonDetails'
import Onboarding from './features/Pods/Onboarding'
import VerifyToken from './features/VerifyToken/verifyToken'
import ResetPassword from './features/ResetPassword/resetPassword'
import ResetPasswordTest from './features/ResetPassword/resetPasswordTest'
import ResetPasswordChange from './features/ResetPassword/resetPasswordChange'

import feathers from '@feathersjs/client'
import io from 'socket.io-client'

import { combineReducers } from 'redux'
import loginReducer, { LoginState } from './features/Login/loginSlice'
import podsReducer from './features/Pods/podsSlice'
import relationshipReducer from './features/Pods/relationshipsSlice'
import mediasReducer from './features/Medias/mediasSlice' // MEDIAS ADD
import reduxifyServices from './lib/feathers-redux'
import resetPasswordReducer from './features/ResetPassword/resetPasswordSlice'
import verifyTokenReducer from './features/VerifyToken/verifyTokenSlice'
import ListenerLoginUser from './components/Listeners/ListenerLoginUser'

import * as packageData from './../package.json'

import { connect } from 'react-redux'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ErrMgmt from './ErrMgmt'
import { SnackbarProvider } from 'notistack'
import LayoutProfile from './components/Layouts/LayoutProfile'
import { User } from './interfaces/interfacesApp'
import { FeathersReduxifyModel } from './interfaces/interfacesFeathers'
import PagePod from './components/Pages/PagePod'
import PageModuleCreate from './components/Pages/PageModuleCreate'
import ModalProvider from 'mui-modal-provider'
import LayoutPublicAccesSignup from './components/Layouts/LayoutPublicAccessSignup'
import ListenerServiceWorker from './components/Listeners/ListenerServiceWorker'

const disabledOpacity = 0.44

const theme = createMuiTheme({
  palette: {
    action: {
      disabledOpacity: disabledOpacity
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#007c89'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$disabled': {
          opacity: disabledOpacity
        }
      }
    }
  }
})

// ********** BUILD VERSIONS ************

export const appVersion = packageData.version
export const appBuild = process.env.REACT_APP_BUILDVERSION || '0000'

// ********** URLS ************
const hostName = 'localhost'
export const urlApiUsers = process.env.REACT_APP_API_USERS || `http://${hostName}:3030`
console.log('urlApiUsers >> ', urlApiUsers)
const urlApiPods = process.env.REACT_APP_API_PODS || `http://${hostName}:3040`
console.log('urlApiPods >> ', urlApiPods)
const urlApiPolls = process.env.REACT_APP_API_POLLS || `http://${hostName}:3050`
console.log('urlApiPolls >> ', urlApiPolls)
const urlApiPosts = process.env.REACT_APP_API_POSTS || `http://${hostName}:3060`
console.log('urlApiPosts >> ', urlApiPosts)
// MEDIAS ADD
const urlApiMedias = process.env.REACT_APP_API_MEDIAS || `http://${hostName}:3070`
console.log('urlApiMedias >> ', urlApiMedias)
const matomoSiteID = process.env.REACT_APP_MATOMO_SITE_ID || '1'
console.log('matomoSiteID >> ', matomoSiteID)

export const urlApp = process.env.REACT_APP_SERVER_FRONT || `http://${hostName}:3000`
console.log('urlApp >> ', urlApp)

// ********** USERS ************
// Establish a Socket.io connection
const usersSocket = io(urlApiUsers)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const usersClient = (feathers as any)()

usersClient.configure(feathers.socketio(usersSocket))
// Use localStorage to store our login token
usersClient.configure(feathers.authentication({ storageKey: 'feathers-users-token' }))

// Create Redux actions and reducers for Feathers services
const reduxifyOptions = { idField: '_id' }
export const usersServices = (reduxifyServices as any)(usersClient, ['users', 'tokenretriever', 'relationships', 'authManagement', 'notifications'], reduxifyOptions)

// ********** EVENTS ************
// Establish a Socket.io connection
const podsSocket = io(urlApiPods)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const podsClient = (feathers as any)()

podsClient.configure(feathers.socketio(podsSocket))
// Use localStorage to store our login token
podsClient.configure(feathers.authentication({ storageKey: 'feathers-pods-token' }))

// Create Redux actions and reducers for Feathers services
export const podsServices = (reduxifyServices as any)(podsClient, ['pods', 'podUser'], reduxifyOptions)

// ********** POLLS ************
// Establish a Socket.io connection
const pollsSocket = io(urlApiPolls)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const pollsClient = (feathers as any)()

pollsClient.configure(feathers.socketio(pollsSocket))
// Use localStorage to store our login token
pollsClient.configure(feathers.authentication({ storageKey: 'feathers-polls-token' }))

// Create Redux actions and reducers for Feathers services
export const pollsServices = (reduxifyServices as any)(pollsClient, ['polls'], reduxifyOptions)

// ********** POSTS ************
// Establish a Socket.io connection
const postsSocket = io(urlApiPosts)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const postsClient = (feathers as any)()

postsClient.configure(feathers.socketio(postsSocket))
// Use localStorage to store our login token
postsClient.configure(feathers.authentication({ storageKey: 'feathers-posts-token' }))

// Create Redux actions and reducers for Feathers services
export const postsServices = (reduxifyServices as any)(postsClient, ['posts'], reduxifyOptions)

// MEDIAS ADD
// ********** MEDIAS ************
// Establish a Socket.io connection
const mediasSocket = io(urlApiMedias)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const mediasClient = (feathers as any)()

mediasClient.configure(feathers.socketio(mediasSocket))
// Use localStorage to store our login token
mediasClient.configure(feathers.authentication({ storageKey: 'feathers-medias-token' }))

// Create Redux actions and reducers for Feathers services
export const mediasServices = (reduxifyServices as any)(mediasClient, ['medias'], reduxifyOptions)

// *********** Matomo Analytics *******************
const instance = createInstance({
  urlBase: 'https://symplace.matomo.cloud/',
  siteId: parseInt(matomoSiteID), // optional, default value: `1`
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    setSecureCookie: true
  }
})

// ********** Errors ************
const feathersErrorMgmt = (context: any) => {
  console.log('Feathers ERROR ---------------------------------------')
  console.log(context)
  // Matomo tracking
  instance.trackEvent({ category: 'FEATHERS ERROR', action: JSON.stringify(context.error) })
}

usersClient.hooks({
  error (context: any) {
    feathersErrorMgmt(context)
  }
})
podsClient.hooks({
  error (context: any) {
    feathersErrorMgmt(context)
  }
})
pollsClient.hooks({
  error (context: any) {
    feathersErrorMgmt(context)
  }
})

postsClient.hooks({
  error (context: any) {
    feathersErrorMgmt(context)
  }
})

// MEDIAS ADD
mediasClient.hooks({
  error (context: any) {
    feathersErrorMgmt(context)
  }
})

// ********** REDUCER ************
export const rootReducer = combineReducers({
  login: loginReducer,
  users: usersServices.users.reducer,
  authManagement: usersServices.authManagement.reducer, // reducer already implemented by feathers-redux
  tokenretriever: usersServices.tokenretriever.reducer,
  relationships: usersServices.relationships.reducer,
  notifications: usersServices.notifications.reducer,
  // pods: pods object in db
  // podsSlice: pods operations from front
  pods: podsServices.pods.reducer,
  podUser: podsServices.podUser.reducer,
  podsSlice: podsReducer,
  polls: pollsServices.polls.reducer,
  posts: postsServices.posts.reducer,
  relationshipSlice: relationshipReducer,
  medias: mediasServices.medias.reducer,
  // MEDIAS ADD
  mediasSlice: mediasReducer,
  resetPassword: resetPasswordReducer,
  verifyToken: verifyTokenReducer
})

// ********** GOOGLE CAPTCHA ************
export const captchaSitekey = '6LfSgT4aAAAAAEy8-vVMKNCQI8hXtcuYVTPPCPYf'

interface AppBaseProps{
  loginStatus : LoginState,
  user: User
}

export function AppBase ({ user, loginStatus } : AppBaseProps): ReactElement {
  // TODO: SLF Why is it <Login usersClient={usersClient} podsClient={podsClient} pollsClient={pollsClient}/> usersClient... are exported
  // TODO: SLF why use component={}
  // TODO: SLF not clear why logoutProvider ???
  // TODO: SLF invites page my invitations is not used?

  // ConnectionProvider: add logout to menu
  // Routes: Depending on loginStatus, different routes allowed
  // Boot: background listeners that retreive user information when user is fully logged

  return (
    <MatomoProvider value={instance}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={3000} maxSnack={3} dense>
          <ModalProvider>
            <ListenerServiceWorker />
            <ConnectionProvider>
              {loginStatus === LoginState.USER_SUCCESSFULLY_LOGGED_IN
                ? (
                  <>
                    <Router>
                      <Switch>
                        <Route path='/pods'> <Pods /></Route>
                        <Route path='/profile'> <LayoutProfile user={user} /></Route>
                        <Route path='/invites'> <Invites /></Route>
                        <Route path='/relationships'> <Relationships /></Route>
                        <Route path='/pod/:id/modulecreate/:type'> <PageModuleCreate /></Route>
                        <Route path='/pod/:podid/poll/:id' component={PagePoll} />
                        <Route path='/pod/:id/:tabName?'> <PagePod /></Route>
                        <Route path='/podinvite/:podid/:page?/:moduleid?'><PagePodInvite /></Route>
                        <Route path='/podedit/:id' component={PodEdit} />
                        <Route path='/invitationfriends/:id' component={InvitationFriends} />
                        <Route path='/invitationlink/:id' component={InvitationLink} />
                        <Route path='/persondetails/:id' component={PersonDetails} />
                        <Route path='/onboarding' component={Onboarding} />
                        <Route path='/verify' component={VerifyToken} />
                        <Route path='/publicsignup'><SignUp /></Route>
                        <Route path='*'> <Redirect to='/pods' /> </Route>
                      </Switch>
                      <ListenerPodInvite />
                      <ListenerLoginUser />
                    </Router>
                    <PodTracker />
                  </>)
                : (
                  <Router>
                    <Switch>
                      <Route path='/signup'> <SignUp /></Route>
                      <Route path='/podinvite/:podid/:page?/:moduleid?'><LayoutPublicAccesSignup /></Route>
                      <Route path='/verify' component={VerifyToken} />
                      <Route path='/resetpassword' component={ResetPassword} />
                      <Route path='/resetpasswordtest' component={ResetPasswordTest} />
                      <Route path='/resetpasswordchange/:token' component={ResetPasswordChange} />
                      <Route path='*'> <Login /> </Route>
                      {/* MEDIAS APP */}
                    </Switch>
                  </Router>)}
              {/* ErrMgmt Must be at the end to allow components to reset error and handle them locally */}
              <ErrMgmt />
            </ConnectionProvider>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MatomoProvider>
  )
}

interface RootState {
  login: {
    status: LoginState
    user: User
  },
  users: FeathersReduxifyModel
}

const mapState = (state: RootState) => {
  return {
    loginStatus: state?.login.status,
    user: state?.login?.user
  }
}

export const App = connect(
  mapState
)(AppBase)
