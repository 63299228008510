import { Card, CardHeader, CardMedia, IconButton, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { ProcessedMedia } from '../../interfaces/interfacesApp'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

export type OnMediaPreviewHandleClose = () => void

export interface MediaPreviewProps {
  open: boolean, // TODO discuss about it during code review.
  onClose: OnMediaPreviewHandleClose,
  medias: ProcessedMedia[]
}

export const useStyles = makeStyles(() => ({

  root: {
    position: 'fixed',
    top: '58px',
    left: '0px',
    bottom: '80px',
    width: '100%',
    height: '100% - 80px',
    zIndex: 1900,
    backgroundColor: '#707070',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '50px'
  },
  cardRoot: {
    padding: 'unset'
  },
  media: {
    height: '92%',
    width: '100%',
    zIndex: 1910,
    marginLeft: '-32px',
    backgroundSize: 'contain'
  },
  document: {
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    zIndex: 1930,
    color: 'white',
    marginLeft: '-32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  typoStyle: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    wordBreak: 'break-word',
    borderBottom: '1px solid white'
  },
  typoStyleExt: {
    paddingTop: '10px',
    fontSize: '14px'
  },
  close: {
    position: 'absolute',
    left: 10,
    top: 0,
    zIndex: 1920,
    color: 'white',
    paddingLeft: 'unset'
  }
}))

const MediaPreview = ({ onClose, medias } : MediaPreviewProps): any => {
  const classes = useStyles()
  const handleCloseClick = () => {
    onClose()
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label='close' onClick={handleCloseClick} className={classes.close}>
            <CloseIcon />
          </IconButton>
        }
      />
      {medias.map(media => {
        // File size in KB
        const newFileSize = (media.fileSize / 1000).toFixed(2)
        // Get extension from original name
        const splitString = media.originalName.split('.')
        const newExtension = splitString[1].toUpperCase()
        return ((media.mimeType as string).match(/image/) != null
          ? (
            <CardMedia
              key={media.url}
              className={classes.media}
              image={media.url}
              title={media.url}
            />)
          : (
            <Card className={classes.document}> <InsertDriveFileIcon fontSize='large' />
              <Typography className={classes.typoStyle}>{media.originalName}</Typography>
              <Typography className={classes.typoStyleExt}>{newExtension}{' '}{newFileSize} KB</Typography>
            </Card>)
        )
      })}
    </Card>
  )
}

export default MediaPreview
