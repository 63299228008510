import React, { ReactElement } from 'react'

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import WidgetDisplayInfo from '../Widgets/WidgetDisplayInfo'
import CongratIcon from '../../assets/icon-congrats.svg'
import WrongFaceIcon from '../../assets/icon-grimacingface.svg'

interface LayoutVerifyTokenProps {
  status: string
  errorMsg: string | null
}

const LayoutVerifyToken = ({ status, errorMsg }: LayoutVerifyTokenProps): ReactElement => {
  const { t } = useTranslation('translation')

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Verify email'
    })
  }, [])
  return (
    <>
      {(status === 'verified') &&
        <WidgetDisplayInfo
          barTitle=''
          image={CongratIcon}
          title={t('verify.success.title')}
          desc1={t('verify.success.desc')}
          buttonLabel={t('verify.success.button')}
          link='/pods'
        />}
      {(status === 'error') && (errorMsg === 'Verification token has expired.') &&
        <WidgetDisplayInfo
          barTitle=''
          image={WrongFaceIcon}
          title={t('verify.expiredToken.title')}
          desc1={t('verify.expiredToken.desc')}
          buttonLabel={t('verify.expiredToken.button')}
          link='/'
        />}
      {(status === 'error') && (errorMsg !== 'Verification token has expired.') &&
        <WidgetDisplayInfo
          barTitle=''
          image={WrongFaceIcon}
          title={t('verify.error.title')}
          desc1={t('verify.error.desc')}
          buttonLabel={t('verify.error.button')}
          link='/'
        />}
    </>
  )
}

export default LayoutVerifyToken
