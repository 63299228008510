import React, { memo, ReactElement, useState } from 'react'
import NavBar from '../Menus/NavBar'
import { AppBar, Badge, Box, makeStyles, Snackbar, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { Media, Module, ModuleType, NotificationData, NotificationObjectType, NotificationType, Pod, Poll, Post, Relationship, User } from '../../interfaces/interfacesApp'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../interfaces/AppState'
import LayoutPodModules from '../Layouts/LayoutPodModules'
import { podTrackTarget, podUpdateUserThunk } from '../../features/Pods/podsSlice'
import LayoutPodChat from '../Layouts/LayoutPodChat'
import { chatCreateThunk } from '../../features/Chat/chatSlice'
import { refreshUsersThunk } from '../../features/RefreshUsers/RefreshUsers'
import { copyToClipboard, getColorFromID, getUserFriends, showShareAPI } from '../../lib/utils'
import getGlimpse from '../../lib/glimpse'
import makeDebug from 'debug'
import LayoutPodDesc from '../Layouts/LayoutPodDesc'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import { userNotificationDeleteThunk } from '../../features/userNotifications/userNotificationsSlice'
import { urlApp } from '../../App'
import { showFeedbackDialog } from '../Popup/FeedbackDialog'

const debug = makeDebug('PagePod:')

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  selectedIndex: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, selectedIndex, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={selectedIndex !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {selectedIndex === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  tabBar: {
    position: 'fixed',
    top: '3.5rem', // NavBar height
    height: '3 rem',
    backgroundColor: 'white',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 1110 // NavBar zIndex 1100
  },
  tabLabel: {
    textTransform: 'none'
  },
  tabLabelText: {
    position: 'relative',
    fontSize: '14px'
  },
  tabPanel: {
    paddingTop: '3rem'
  },
  tabBullet: {
    position: 'absolute',
    top: '50%',
    right: '-8px'
  }
}))

// ******** STORE PREPROCESSORS

// preprocess posts to add relevant user information
const preprocessPosts = (filteredPosts: Post[], users: User[]) => {
  // create an impoved possts table by adding to each post the user data and user color
  const namedPosts = filteredPosts.reduce((filtered : Post[], p: Post) => {
    p.user = users.find((u: User) => u._id === p.creatorId)
    if (p.user !== undefined) {
      p.userColor = getColorFromID(p.user._id)
      filtered.push(p)
    }

    return filtered
  }, [])

  return namedPosts
}

// preprocess posts to reorder and group by user
const preprocessPostOrdering = (posts: Post[]) => {
  // sort all posts by date
  posts.sort((a: Post, b: Post) => (a.creationDate >= b.creationDate || b.creationDate === undefined) ? 1 : -1)

  // init first creatorId
  let prevCreatorId = ''

  // for each post check prev creatorId and update postType accordingly
  const orderedPosts = posts.map((post : Post) => {
    post.postType = (prevCreatorId === post.creatorId) ? 'post-group' : 'post-full'
    prevCreatorId = post.creatorId ?? ''

    return post
  })

  return orderedPosts
}

// preprocess posts to reorder and group by user
const preprocessMedias = (posts: Post[], medias: Media[]) => {
  return posts.map(post => {
    if (post.medias == null || post.medias.map == null || medias == null || medias.find == null) { return post }
    post.processedMedias = post.medias.map((id: string) => {
      const media = medias.find(media => media._id === id)
      if (media == null) return ({ url: 'no-url', mimeType: 'unknown', fileSize: 0, originalName: 'none' }) // FIXME: find a better fallback
      return ({ url: media.url, mimeType: media.mimeType, fileSize: media.fileSize, originalName: media.originalName })
    })
    return post
  })
}

// preprocess posts to insert module posts
const preprocessModulesInPod = (modules: Module[], polls: Poll[], podId: string, loginId: string, pod: Pod, posts: Post[]) => {
  // get actual modules from the moduleIds list in the pod so we can use the module internal state
  const modulePolls = modules.map(
    (mod: Module) => polls.find((poll: Poll) => poll._id === mod.id) // get corresponding poll
  ).filter((el: Poll | undefined) => el !== undefined) as Poll[] // remove modules which are not polls

  // for each module of the pod push its 'comprehensive' state in the posts list
  modulePolls.forEach((poll : any) => {
    posts.push({
      moduleType: poll.type || ModuleType.POLL,
      title: poll.title,
      closed: poll.closed,
      podId: pod._id,
      moduleId: poll._id,
      creationDate: poll.lastUpdate || new Date(),
      message: getGlimpse(poll.type, poll, loginId),
      user: { firstName: poll.title }
    })
  })

  return modulePolls
}
// preprocess posts to insert notification posts
const preprocessNotificationPost = (posts: Post[], havePostNotification: boolean, firstUnreadPostDate: Date | undefined, unreadMessage: string, pod: Pod) => {
  if (havePostNotification && firstUnreadPostDate) {
    const newfirstUnreadPostDate = new Date(firstUnreadPostDate)
    newfirstUnreadPostDate.setMilliseconds(newfirstUnreadPostDate.getMilliseconds() - 10) // set 10 milliseconds before the last post to be ordered correctly and not modify the sort function
    posts.push({
      podId: pod._id,
      message: unreadMessage, // TODO: Translate should be performed in the rendering not here in the creation !
      creationDate: newfirstUnreadPostDate.toISOString() as unknown as Date,
      moduleType: ModuleType.UNREAD_BREAKLINE
    })
  }
  return posts
}

interface ParamTypes {
    id: string
    tabName?: string
}

const PagePod = (): ReactElement|null => {
  const { id, tabName } = useParams<ParamTypes>()
  debug(`params id:${id} tab:${tabName}`)

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  // Follow All related users (shared pod or relationship) & logged in user relationships
  const users: User[] | null = useSelector((state: AppState) => state.users.queryResult)
  const relationships: Relationship[] | null = useSelector((state: AppState) => state.relationships.queryResult)
  const podInviteId = useSelector((state: AppState) => state.podsSlice.podInviteId)

  // Follow filtered polls, medias & posts by podId
  const findPending: boolean = useSelector((state: AppState) => state.pods.findPending)
  const pod: Pod | undefined = useSelector((state: AppState) => state.pods.queryResult?.find((pod: Pod) => pod._id === id))
  const filteredPolls: Poll[] | undefined = useSelector((state: AppState) => pod && state.polls.queryResult?.filter((poll: Poll) => poll.podId === pod._id))
  const filteredMedias: Media[] | undefined = useSelector((state: AppState) => pod && state.medias.queryResult?.filter((media: Media) => media.trackPackIds?.includes(pod._id)))
  const filteredPosts: Post[] | undefined = useSelector((state: AppState) => pod && state.posts.queryResult?.filter((post: Post) => post.podId === pod._id))

  // Follow loggedInUserId, trackPodIdTarget & mediaToken
  const loggedInUserId: string | undefined = useSelector((state: AppState) => state.login?.user?._id)
  const trackPodIdTarget: string | undefined = useSelector((state: any) => state.podsSlice?.trackPodIdTarget)
  const mediaToken: string | undefined = useSelector((state: any) => state.login?.msTokens.find((t: any) => t.service === 'medias')?.token || 'notoken')

  const [checkedUnknownUserIds] = useState(new Map<string, boolean>())
  // History hooks
  const history = useHistory()

  // Get current user
  const user: User | undefined = useSelector((state: AppState) => state.users.queryResult?.find((user: User) => user._id === loggedInUserId))

  // Notification management
  const notifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) => !(
      (notification.type === NotificationType.DELETE) || // do not display delete notif
      (notification.type === NotificationType.CLOSE) || // do not display delete notif
      ((notification.type === NotificationType.UPDATE) && (notification.objectType === NotificationObjectType.POST)) || // do not display post update notif
      (pod?._id && !notification.parentIds.includes(pod._id)) // do not display other pods notif
    ))
  )
  const getPostNotifications: NotificationData[] | undefined = notifications?.filter((notification:NotificationData) => (notification.objectType === NotificationObjectType.POST))
  const postNotification = getPostNotifications?.length !== 0

  const notificationReducer = (oldestNotification: Date, notification: NotificationData) => (notification.creationDate < oldestNotification) ? notification.creationDate : oldestNotification
  const firstUnreadPostDate = getPostNotifications?.reduce(notificationReducer, getPostNotifications[0]?.creationDate)

  const getModuleNotifications: NotificationData[] | undefined = notifications?.filter((notification:NotificationData) => (notification.objectType === NotificationObjectType.MODULE))
  const moduleNotification = getModuleNotifications?.length !== 0

  const getDescriptionNotifications: NotificationData[] | undefined = notifications?.filter((notification:NotificationData) => (notification.objectType === NotificationObjectType.POD))
  const descriptionNotification = getDescriptionNotifications?.length !== 0

  const getNewPodNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification : NotificationData) =>
      pod?._id &&
      notification.objectType === NotificationObjectType.POD &&
      notification.type === NotificationType.NEW &&
      notification.parentIds.includes(pod._id)))
  const notificationsRemovePending: boolean = useSelector((state: AppState) => state.notifications.removePending)

  // Tab management
  const urlTabIndex = (tabName === 'chat') ? 0 : (tabName === 'modules') ? 1 : (tabName === 'description') ? 2 : 0
  const [selectedIndex, setSelectedIndex] = useState(urlTabIndex) // Selected tab index
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation('translation')
  const dispatch = useDispatch()
  const [userConfirmationRequested, setUserConfirmationRequested] = useState(false) // confirmation of the user in the pod

  const tabPropsFactory = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
      className: classes.tabLabel
    }
  }

  const checkWeHaveAllUsers = () => {
    if (pod == null || users == null) { return }
    const userIdMap = new Map<string, boolean>()

    users.forEach((user: User) => userIdMap.set(user._id, true))

    let unkwownUserIds: string[] = pod.usersConfirmed

    unkwownUserIds = unkwownUserIds.filter((userId: string) => !userIdMap.has(userId))
    unkwownUserIds = unkwownUserIds.filter((userId: string) => !checkedUnknownUserIds.has(userId))

    if (unkwownUserIds.length !== 0) {
      // FIXME: improve fix with something better than checkedUnknownUserIds
      dispatch(refreshUsersThunk(pod._id))
      unkwownUserIds.forEach((id: string) => checkedUnknownUserIds.set(id, true))
    }
  }

  checkWeHaveAllUsers()

  debug(`Rendered loggedInUserId=${loggedInUserId}, users=${users}, pod=${pod}, trackPodIdTarget=${trackPodIdTarget}, filteredPolls=${filteredPolls}, relationships=${relationships}, filteredPosts=${filteredPosts}, filteredMedias=${filteredMedias}`)

  if (!loggedInUserId || findPending) {
    debug('EXIT no loggedin user or findPending')
    return null
  } else if (!pod) { // Pod not found
    // TODO: implement a pod not found page
    if (id !== podInviteId) history.replace('/pods') // if not invited
    return null
  }

  // Add pending user to confirmed if the user clicks on the pod link
  if (loggedInUserId && pod?.usersPending.includes(loggedInUserId) && !userConfirmationRequested) {
    dispatch(podUpdateUserThunk(pod, true, loggedInUserId))
    setUserConfirmationRequested(true)
  }

  // update pod id to track only if necessary
  if (pod._id != null && pod._id !== trackPodIdTarget) { dispatch(podTrackTarget(pod._id)) }

  // Remove new pod notifications
  if (getNewPodNotifications && getNewPodNotifications.length !== 0 && !notificationsRemovePending) {
    debug(`Remove ${getNewPodNotifications.length} notifications`, getNewPodNotifications)
    dispatch(userNotificationDeleteThunk(getNewPodNotifications))
  }

  // depending on displayed tabs: discussion or modules
  if (!filteredPolls || !users || !filteredPosts || !filteredMedias || !mediaToken) {
    return null
  }

  const modules = pod.modules || [] // some old pods dont have modules
  // preprocess posts and modules
  const namedPosts = preprocessPosts(filteredPosts, users) // filtered posts also contain left user posts
  // debug('namedPosts', namedPosts)
  const modulePolls = preprocessModulesInPod(modules, filteredPolls, pod._id, loggedInUserId, pod, namedPosts)
  // preprocess Notification
  const amalgamPosts = preprocessNotificationPost(namedPosts, postNotification, firstUnreadPostDate, t('chat.unReadBreakLine.unRead'), pod)
  // debug('modulePolls', modulePolls)
  const orderedPosts = preprocessPostOrdering(amalgamPosts) // Is it really necessary ?
  // debug('orderedPosts', orderedPosts)
  const preprocessedPosts = preprocessMedias(orderedPosts, filteredMedias)
  // debug('preprocessedPosts', preprocessedPosts)

  // preprocess users & relationships
  const filteredUsers = users.filter((user: User) => pod?.usersConfirmed.includes(user._id))
  const friendIds = (Array.isArray(relationships) && loggedInUserId) ? getUserFriends(relationships, loggedInUserId) : []

  // tab change handlers
  const handleChange = (event: React.ChangeEvent<unknown>, index: number) => {
    setSelectedIndex(index)
    const urlTabName = (index === 0) ? 'chat' : (index === 1) ? 'modules' : 'description'
    history.replace(`/pod/${pod._id}/${urlTabName}`)
  }
  const handleChangeIndex = (index: number) => {
    setSelectedIndex(index)
  }

  const link = tabName !== undefined ? `${urlApp}/podinvite/${pod._id}/${tabName}` : `${urlApp}/podinvite/${pod._id}`

  return (
    <>
      <NavBar
        backUrl='/pods'
        title={pod.title}
        subTitle={t('pods.podEdit.memberNav', { count: filteredUsers?.length })}
        onTitleClicked={() => history.push(`/podedit/${pod._id}`)}
        shareIcon={() => navigator.share !== undefined
          ? showShareAPI(link, ({ podTitle: pod.title, podDesc: pod.description, lang: user?.locale }))
          : (copyToClipboard(link, { podTitle: pod.title, podDesc: pod.description, lang: user?.locale })
            ? showFeedbackDialog(1500, t('invite.copy_success'), setMessage, setOpen) : null)}
      />
      <div className={classes.tabBar}>
        <AppBar position='static' color='default'>
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            aria-label='full width pod tabs'
          >
            <Tab
              label={
                <Typography className={classes.tabLabelText}>
                  {t('pods.pagePod.chatTab')}
                  {postNotification ? <Badge classes={{ dot: classes.tabBullet }} variant='dot' color='error' /> : null}
                </Typography>
              }
              {...tabPropsFactory(0)}
            />
            <Tab
              label={
                <Typography className={classes.tabLabelText}>
                  {t('pods.pagePod.modTab')}
                  {moduleNotification ? <Badge className={classes.tabBullet} variant='dot' color='error' /> : null}
                </Typography>
              }
              {...tabPropsFactory(1)}
            />
            <Tab
              label={
                <Typography className={classes.tabLabelText}>
                  {t('pods.pagePod.descTab')}
                  {descriptionNotification ? <Badge className={classes.tabBullet} variant='dot' color='error' /> : null}
                </Typography>
              }
              {...tabPropsFactory(2)}
            />
          </Tabs>
        </AppBar>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedIndex}
        onChangeIndex={handleChangeIndex}
        className={classes.tabPanel}
      >
        <TabPanel selectedIndex={selectedIndex} index={0} dir={theme.direction}>
          <LayoutPodChat
            pod={pod}
            mediaToken={mediaToken}
            posts={preprocessedPosts}
            users={filteredUsers}
            friendIds={friendIds}
            loggedInUserId={loggedInUserId}
            onSendMessage={(text: string, medias: any) => { dispatch(chatCreateThunk(text, pod, loggedInUserId, medias)) }}
            onDeleteMessage={() => { console.log('TODO: implement onDeleteMessage') }}
            notifications={getPostNotifications}
          />
        </TabPanel>
        <TabPanel selectedIndex={selectedIndex} index={1} dir={theme.direction}>
          <LayoutPodModules
            pod={pod}
            polls={modulePolls}
            loggedInUserId={loggedInUserId}
          />
        </TabPanel>
        <TabPanel selectedIndex={selectedIndex} index={2} dir={theme.direction}>
          <LayoutPodDesc pod={pod} notifications={getDescriptionNotifications} />
        </TabPanel>
      </SwipeableViews>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        message={message}
        key={'bottom' + ' ' + 'center'}
      />
    </>
  )
}

export default memo(PagePod)
