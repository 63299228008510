import React, { useState, ReactElement } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Redirect } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import NavBar from '../Menus/NavBar'
import { Pod } from '../../interfaces/interfacesApp'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 2
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    right: 0,
    margin: '0 auto'
  },
  avatar: {
    width: 80,
    height: 80
  }
}))

function renderInvite (pod: Pod, classes: any, setPodId: any) {
  return (
    <React.Fragment key={uuidv4()}>
      <ListItem button alignItems='flex-start' key={pod.title + Math.random()} onClick={() => setPodId(pod._id)}>
        <ListItemAvatar>
          <Avatar className={classes.avatar2} variant='square' alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
        </ListItemAvatar>
        <ListItemText
          primary='Cinéma'
          secondary={
            <>
              <Typography
                component='span'
                variant='body2'
                className={classes.inline}
                color='textPrimary'
              >
                {pod.title}
              </Typography>
              {' - ' + pod.description}
            </>
          }
        />
      </ListItem>
    </React.Fragment>
  )
}

export interface LayoutInvitesProps {
  pods: any
}

export default function LayoutInvites (props: LayoutInvitesProps): ReactElement {
  const [podId, setPodId] = useState(null)

  const classes = useStyles()

  if (podId != null) { return (<Redirect to={`/invite/${podId}`} />) }

  return (
    <>
      <List className={classes.root}>
        {props.pods ? props.pods.map((pod: Pod) => renderInvite(pod, classes, setPodId)) : null}
      </List>
      <NavBar
        title='MES INVITATIONS'
        back
      />
    </>
  )
}
