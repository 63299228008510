import React, { ReactElement, useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Trans, useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import Logo from '../../assets/logo_symbol.svg'
import FacebookLogo from '../../assets/facebookLogo.svg'
import GoogleLogo from '../../assets/googleLogo.svg'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { captchaSitekey, urlApiUsers } from '../../App'
import { emailRegex, getPublicNameFromLocalStorage } from '../../lib/utils'
import InputPasswordWithLevel from '../Widgets/InputPasswordWithLevel'
import ReCAPTCHA from 'react-google-recaptcha'
import i18n from '../../i18n'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(3),
    backgroundColor: 'white'
  },
  terms: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.875rem',
    textAlign: 'justify',
    marginTop: theme.spacing(3),
    '& a': {
      color: 'rgba(0, 0, 0, 0.6)'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    marginTop: theme.spacing(3),
    height: 56,
    fontSize: '0.875rem',
    fontWeight: 500
  },
  orDivider: {
    textAlign: 'center',
    fontSize: 14,
    color: '#999999'
  },
  title: {
    fontSize: '20px',
    marginTop: '1rem',
    fontWeight: 'bold'
  },
  signin: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 20,
    height: 56,
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1
  },
  symly: {
    fontSize: '24px',
    fontFamily: 'Futura PT'
  },
  divDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%'
  },
  buttonFacebook: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 20,
    height: 56,
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    textTransform: 'none'
  },
  buttonGoogle: {
    margin: theme.spacing(0, 0, 2),
    height: 56,
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    textTransform: 'none'
  },
  divider: {
    flexGrow: 2
  },
  labelDivider: {
    color: '#707070',
    fontSize: '14px',
    fontFamily: 'Roboto',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },
  signinLink: {
    marginTop: theme.spacing(3),
    fontSize: '1,125rem',
    fontWeight: 500,
    width: '100%',
    textDecoration: 'none'
  },
  signinText: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '1rem',
    color: 'black'
  }
}))

export interface LayoutSignUpProps {
  signUp : any
  setEmail : any
  setPassword : any
  isPublicAccount: boolean
}

export default function LayoutSignUp (props : LayoutSignUpProps): ReactElement {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstname] = useState(getPublicNameFromLocalStorage() || '')
  const [lastname, setLastname] = useState('')
  const [captcha, setCaptcha] = useState(null)

  const emailError = ((email !== '') && !((new RegExp(emailRegex)).test(email)))

  const language = i18n.language
  const handleCaptchaChange = (value: any) => {
    console.log('Captcha value:', value)
    setCaptcha(value)
  }

  const { t } = useTranslation('translation')

  const googleAuth = `${urlApiUsers}/oauth/google`
  const facebookAuth = `${urlApiUsers}/oauth/facebook`

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Logo} className={classes.logo} />
        <Typography className={classes.title} component='h1' variant='h5'>
          {t('login.signupTitle')}
        </Typography>
        {props.isPublicAccount === false
          ? (
            <>
              <Button fullWidth variant='contained' startIcon={<img src={FacebookLogo} />} className={classes.buttonFacebook} href={facebookAuth}>{t('login.facebook')}</Button>
              <Button fullWidth variant='contained' startIcon={<img src={GoogleLogo} />} className={classes.buttonGoogle} href={googleAuth}>{t('login.google')}</Button>
              <div className={classes.divDivider}>
                <Divider className={classes.divider} />
                <span className={classes.labelDivider}>{t('login.divider')}</span>
                <Divider className={classes.divider} />
              </div>
            </>
          ) : null}
        <form className={classes.form} onSubmit={(ev) => { props.setPassword(password); props.signUp({ email, password, firstname, lastname }); ev.preventDefault() }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField onChange={e => setFirstname(e.target.value)} value={firstname} name='firstName' variant='outlined' fullWidth id='firstName' label={t('login.firstname')} autoFocus={!props.isPublicAccount} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField onChange={e => setLastname(e.target.value)} variant='outlined' fullWidth id='lastName' label={t('login.lastname')} name='lastName' autoComplete='lname' />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={e => { setEmail(e.target.value.toLowerCase()); props.setEmail(e.target.value.toLowerCase()) }} type='email' error={emailError} {...(emailError && { helperText: t('login.emailError') })} variant='outlined' fullWidth id='email' label={t('login.email')} name='email' autoComplete='email' />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='outlined' style={{ width: '100%' }}>
                <InputLabel htmlFor='outlined-adornment-password'>{t('login.password')}</InputLabel>
                <InputPasswordWithLevel password={password} setPassword={setPassword} />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <ReCAPTCHA sitekey={captchaSitekey} hl={language} onChange={handleCaptchaChange} />
            </Grid>
          </Grid>

          <Button
            color='primary' type='submit' fullWidth variant='contained' className={classes.submit}
            disabled={firstname === '' || lastname === '' || email === '' || emailError || password === '' || captcha === null}
          >
            {t('login.signupButton')}
          </Button>
          {props.isPublicAccount === false
            ? (
              <Link to='/signin' className={classes.signinLink}>
                <div className={classes.signinText}>
                  <span style={{ textDecoration: 'underline' }}>{t('login.alreadyAccount') + ' ' + t('login.signin')}</span>
                </div>
              </Link>
            ) : null}
        </form>
        <Typography className={classes.terms} variant='body1'>
          <Trans i18nKey={props.isPublicAccount === false ? 'login.terms' : 'publicSignup.terms'}>En cliquant sur Accepter et continuer / Google / Facebook , vous confirmez que vous avez lu, compris et accepté les <a href='https://sym.place/symly/mentions-legales.html'>mentions légales</a> de Symly</Trans>
        </Typography>
      </div>
      <div style={{ marginBottom: '2rem' }} />
    </Container>
  )
}
