import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { resetPasswordChangeThunk, ResetPasswordChangeThunk } from '../../features/ResetPassword/resetPasswordSlice'
import { LayoutChangePassword } from '../../components/Layouts/LayoutResetPassword'
import { loginThunk, LoginThunk } from '../Login/loginSlice'
import { usersClient } from '../../App'
import { useTranslation } from 'react-i18next'
import WidgetDisplayInfo from '../../components/Widgets/WidgetDisplayInfo'
import WrongFaceIcon from '../../assets/icon-grimacingface.svg'

const mapDispatch = { resetPasswordChangeThunk, loginThunk }

export interface ResetPasswordChangeProps {
  resetPasswordChangeThunk: ResetPasswordChangeThunk
  loginThunk: LoginThunk
  changePassStatus: string,
  changePassError: string,
  token: string,
  userEmail: string
}

const ResetPasswordChange = ({ token, resetPasswordChangeThunk, loginThunk, changePassStatus, changePassError, userEmail }: ResetPasswordChangeProps) => {
  const { t } = useTranslation('translation')
  const [password, setPassword] = useState('')

  if (changePassStatus === 'success') {
    loginThunk({ email: userEmail, password }, usersClient)
    return (<Redirect to='/' />)
  }

  if (changePassStatus === 'error') {
    const tokenExpired = (changePassError === 'Password reset token has expired.')

    return (
      <WidgetDisplayInfo
        barTitle=''
        image={WrongFaceIcon}
        title={tokenExpired ? t('resetPassword.expiredToken.title') : t('systemError.title')}
        desc1={tokenExpired ? t('resetPassword.expiredToken.desc') : t('systemError.desc', { error: changePassError })}
        buttonLabel={tokenExpired ? t('resetPassword.expiredToken.button') : 'OK'}
        link={tokenExpired ? '/resetpassword' : '/'}
      />
    )
  }

  return (
    <LayoutChangePassword
      onChangePassword={(pw: string) => resetPasswordChangeThunk(pw, token, setPassword)}
    />)
}

interface RootState {
  resetPassword : any
}

const mapState = (state: RootState, ownProps: any) => {
  const token = ownProps?.match?.params?.token
  return ({
    changePassStatus: state?.resetPassword?.resetPasswordChangeStatus,
    changePassError: state?.resetPassword?.resetPasswordChangeStatusError,
    userEmail: state?.resetPassword?.resetPasswordEmail,
    token
  })
}

export default connect(mapState, mapDispatch)(ResetPasswordChange)
