import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { usersClient } from '../../App'
import { logoutError, logoutRequest, logoutSuccess } from '../Login/loginSlice'

export type State = {
  create: string | null,
  createError: string | null,
}

const verifyTokenSlice = createSlice({
  name: 'verifyTokenControl',
  initialState: {},
  reducers: {
    verifyTokenCreateRequest (state) {
      return {
        ...state,
        create: 'creating'
      }
    },
    verifyTokenCreateSuccess (state) {
      return {
        ...state,
        create: 'done'
      }
    },
    verifyTokenCreateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        create: 'error',
        createError: errorMessage
      }
    },
    resendVerifyTokenRequest (state) {
      return {
        ...state,
        resendVerifyToken: 'sending'
      }
    },
    resendVerifyTokenSuccess (state) {
      return {
        ...state,
        resendVerifyToken: 'done'
      }
    },
    resendVerifyTokenError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        resendVerifyToken: 'error',
        error: errorMessage
      }
    }
  }
})

export const {
  verifyTokenCreateRequest, verifyTokenCreateSuccess, verifyTokenCreateError,
  resendVerifyTokenRequest, resendVerifyTokenSuccess, resendVerifyTokenError
} = verifyTokenSlice.actions

export type VerifyTokenCreateThunk = (verifyToken: string) => void

export const verifyTokenCreateThunk = (verifyToken: string): AppThunk => async dispatch => {
  try {
    dispatch(verifyTokenCreateRequest())
    console.log('token slice ', verifyToken)
    await usersClient.service('authManagement').create({ action: 'verifySignupLong', value: verifyToken }, {})
    dispatch(verifyTokenCreateSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(verifyTokenCreateError(e.message))
  }
}

export type ResendVerifyTokenThunk = (email: string) => void

export const resendVerifyTokenThunk = (email: string): AppThunk => async dispatch => {
  try {
    dispatch(resendVerifyTokenRequest())
    await usersClient.service('authManagement').create({ action: 'resendVerifySignup', value: { email } })
    dispatch(resendVerifyTokenSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(resendVerifyTokenError(e.message))
  }
}

export default verifyTokenSlice.reducer
