import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { usersClient } from '../../App'

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: { },
  reducers: {
    resetPasswordRequest (state) {
      return {
        ...state,
        resetPasswordStatus: 'requesting'
      }
    },
    resetPasswordSuccess (state) {
      return {
        ...state,
        resetPasswordStatus: 'success'
      }
    },
    resetPasswordError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        resetPasswordStatus: 'error',
        resetPasswordStatusError: errorMessage
      }
    },
    resetPasswordChangeRequest (state) {
      return {
        ...state,
        resetPasswordChangeStatus: 'requesting'
      }
    },
    resetPasswordChangeSuccess (state) {
      return {
        ...state,
        resetPasswordChangeStatus: 'success'
      }
    },
    resetPasswordChangeError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        resetPasswordChangeStatus: 'error',
        resetPasswordChangeStatusError: errorMessage
      }
    },
    storeEmail (state, action: PayloadAction<any>) {
      return {
        ...state,
        resetPasswordEmail: action.payload
      }
    }
  }
})

export const {
  resetPasswordRequest, resetPasswordSuccess, resetPasswordError,
  resetPasswordChangeRequest, resetPasswordChangeSuccess, resetPasswordChangeError, storeEmail
} = resetPasswordSlice.actions

export type ResetPasswordThunk = (email: string) => void

export const resetPasswordThunk = (email: string): AppThunk => async dispatch => {
  try {
    dispatch(resetPasswordRequest())
    await usersClient.service('authManagement').create({ action: 'sendResetPwd', value: { email } }, {})
    dispatch(resetPasswordSuccess())
  } catch (e) {
    dispatch(resetPasswordError(e.message))
  }
}

export type ResetPasswordChangeThunk = (password: string, token: string, setPassword: any) => void

export const resetPasswordChangeThunk = (password: string, token: string, setPassword: any): AppThunk => async dispatch => {
  try {
    dispatch(resetPasswordChangeRequest())
    const user = await usersClient.service('authManagement').create({ action: 'resetPwdLong', value: { token, password } }, {})
    setPassword(password)
    dispatch(storeEmail(user.email))
    dispatch(resetPasswordChangeSuccess())
  } catch (e) {
    dispatch(resetPasswordChangeError(e.message))
  }
}

export default resetPasswordSlice.reducer
