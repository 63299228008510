import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr from './locales/fr.json'
import en from './locales/en.json'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator'],
      caches: []
    },
    resources: {},
    defaultNS: 'translation'
  })

i18n.addResourceBundle('fr', 'translation', fr, true, true)
i18n.addResourceBundle('en', 'translation', en, true, true)

export default i18n
