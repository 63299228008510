import { Fab, withStyles } from '@material-ui/core'

export const StyledFab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark
  },
  label: {
    textTransform: 'capitalize',
    color: theme.palette.primary.light
  }
}))(Fab)
