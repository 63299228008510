import { AppThunk } from '../../index'
import { postsServices } from '../../App'
import { Pod, Post } from '../../interfaces/interfacesApp'

export const chatCreateThunk = (message: string, pod: Pod, creatorId: string, medias: string[]|null): AppThunk => async dispatch => {
  try {
    const podId = pod._id
    await dispatch(postsServices.posts.create({ podId, message, creatorId, medias }))
  } catch (e) {
    console.log(e.message)
  }
}

export const chatEditThunk = (post: Post, data: Partial<Post>): AppThunk => async dispatch => {
  if (!post._id) { console.log('No post ID'); return }
  try {
    await dispatch(postsServices.posts.patch(post._id, data))
  } catch (e) {
    console.log(e.message)
  }
}
