import React from 'react'
import { connect } from 'react-redux'
import { resetPasswordThunk, ResetPasswordThunk } from '../../features/ResetPassword/resetPasswordSlice'

const mapDispatch = { resetPasswordThunk }

export interface VerifyTokenProps {
  resetPasswordThunk: ResetPasswordThunk
  status: any
}

const ResetPasswordTest = ({ resetPasswordThunk, status }: VerifyTokenProps) => {
  if (status != null) {
    return (<div> {`STATUS IS ${status}`} </div>)
  }

  resetPasswordThunk('corrado.longoni@sym.place')

  return (<div> {`SENT RESET PASSWORD @${Date()}`} </div>)
}

interface RootState {
  resetPassword : any
}

const mapState = (state: RootState) => {
  return ({
    status: state?.resetPassword?.resetPasswordStatus
  })
}

export default connect(mapState, mapDispatch)(ResetPasswordTest)
