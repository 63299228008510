import React from 'react'
import PageInvitationLink from '../../components/Pages/PageInvitationLink'
import { Pod, Relationship, User } from '../../interfaces/interfacesApp'
import { FeathersReduxifyModel } from '../../interfaces/interfacesFeathers'
import { podAddUserThunk } from '../Pods/podsSlice'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'

const mapDispatch = { podAddUserThunk }

export interface InvitationLinkProps {
  pod: Pod
  users: User[]
  relationships: Relationship[]
  curUserId: string
  podAddUserThunk: any
  isReady: boolean
}

function addMultipleFriendsToPod (toAdd: string[], podId: string, podAddUserThunk: any): void {
  toAdd.forEach(userId => {
    podAddUserThunk({}, userId, podId)
  })
}

const InvitationLink = ({ pod, users, relationships, curUserId, podAddUserThunk, isReady }: InvitationLinkProps) => {
  if (!isReady) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <PageInvitationLink
      pod={pod}
      users={users}
      relationships={relationships}
      curUserId={curUserId}
      onUpdateAddFriendToPod={(data: any, toAdd: string[], podId: string) => addMultipleFriendsToPod(toAdd, podId, podAddUserThunk)}
    />
  )
}

interface RootState {
  polls: any // TODO: FeathersReduxifyModel
  pods: any // TODO: FeathersReduxifyModel
  users: any // TODO: FeathersReduxifyModel
  relationships: any // TODO: FeathersReduxifyModel
  login : {
    email: string,
    user : User
  }
}

const mapState = (state: RootState, ownProps: any) => {
  const id = ownProps?.match?.params?.id
  const pod = (id != null && state?.pods?.queryResult?.find != null)
    ? state.pods.queryResult.find((pod: Pod) => pod._id === id)
    : null
  const curUserId = state.login?.user?._id
  const users = state.users?.queryResult
  const relationships = state.relationships?.queryResult
  return ({
    pod,
    curUserId,
    users,
    relationships,
    isReady: pod != null && users != null && relationships != null && relationships.filter != null
  })
}

export default connect(mapState, mapDispatch)(InvitationLink)
