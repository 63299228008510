import React, { ReactElement } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Button, Link } from '@material-ui/core'

export interface WidgetDisplayInfoProps {
  barTitle?: string
  title: string
  desc1: string
  desc2?: string
  buttonLabel?: string
  link?: string
  image: any
}

const useStyles = makeStyles(() => ({
  root: {
    width: '90%',
    height: '50%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    margin: '20px auto'
  },
  img: {
    marginTop: '4rem',
    width: '6rem'
  },
  button: {
    bottom: 'imp5rem',
    display: 'flex',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const WidgetDisplayInfo = ({ title, desc1, desc2, image, link, buttonLabel } : WidgetDisplayInfoProps) : ReactElement => {
  const classes = useStyles()
  return (
    <>
      <Container className={classes.root}>
        <img className={classes.img} src={image} />
        <Typography className={classes.text} variant='h5'>{title}</Typography>
        <Typography className={classes.text}>{desc1}</Typography>
        {desc2 ? <Typography className={classes.text}>{desc2}</Typography> : null}
        {(link && buttonLabel) ? (
          <Link href={link} style={{ textDecoration: 'none' }}>
            <Button variant='contained' color='primary' className={classes.button}>{buttonLabel}</Button>
          </Link>) : (null)}
      </Container>
    </>
  )
}

export default WidgetDisplayInfo
