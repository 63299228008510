import React from 'react'
import LayoutPersonDetails from '../../components/Layouts/LayoutPersonDetails'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Relationship, User } from '../../interfaces/interfacesApp'
import { relationshipCreateThunk, relationshipRemoveThunk } from '../Pods/relationshipsSlice'
import { connect } from 'react-redux'

const mapDispatch = { relationshipCreateThunk, relationshipRemoveThunk }

export interface PersonDetailsProps {
  userName: string | null
  personId: string | null
  status: string
  curUserId: string
  relationshipId: string
  relationshipCreateThunk: any
  relationshipRemoveThunk: any
  isReady: boolean
}

const PersonDetails = ({ userName, personId, status, curUserId, relationshipId, relationshipCreateThunk, relationshipRemoveThunk, isReady }: PersonDetailsProps) => {
  if (!isReady) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <LayoutPersonDetails
      userName={userName || ''}
      personId={personId || ''}
      onAddFriend={(data: any, requestedId: string) => { relationshipCreateThunk(data, curUserId, requestedId) }}
      onRemoveFriend={() => { relationshipRemoveThunk(relationshipId) }}
      status={status}
    />
  )
}

interface RootState {
  relationships: any // TODO: FeathersReduxifyModel
  users: any // TODO: FeathersReduxifyModel
  login : {
    email: string,
    user : User
  }
}

const mapState = (state: RootState, ownProps: any) => {
  const personId = ownProps?.match?.params?.id
  const userName = (personId != null && state.users.queryResult.find != null && state.users.queryResult.length)
    ? state.users.queryResult.find((user: User) => user._id === personId).firstname + ' ' + state.users.queryResult.find((user: User) => user._id === personId).lastname
    : null

  const curUserId = state.login?.user?._id

  const relationship = (personId != null && state?.relationships?.queryResult?.find != null) ? state.relationships.queryResult.find((relation: Relationship) => ((relation.requesterId === curUserId && relation.requestedId === personId) || (relation.requestedId === curUserId && relation.requesterId === personId))) : null

  let status = 'Nothing'
  if (relationship) { status = relationship.status }

  const relationshipId = relationship ? relationship._id : null

  return ({
    curUserId,
    userName,
    personId,
    relationshipId,
    status,
    isReady: personId != null && userName != null
  })
}

export default connect(mapState, mapDispatch)(PersonDetails)
