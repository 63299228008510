import React, { ReactElement, useState } from 'react'
import LayoutPoll from '../Layouts/LayoutPoll'
import CircularProgress from '@material-ui/core/CircularProgress'
import { pollAddOptionThunk, PollAddOptionThunk, pollToggleChoiceThunk, PollToggleChoiceThunk } from '../../features/Polls/pollsSlice'
import { podTrackTarget, PodTrackTarget, podUpdateUserThunk } from '../../features/Pods/podsSlice'
import { connect, useDispatch } from 'react-redux'
import { Pod, User, Poll, ModuleType } from '../../interfaces/interfacesApp'
import { getUserFriends } from '../../lib/utils'
import makeDebug from 'debug'
import { refreshUsersThunk } from '../../features/RefreshUsers/RefreshUsers'

const debug = makeDebug('PagePoll:')

const mapDispatch = { pollAddOptionThunk, pollToggleChoiceThunk, podTrackTarget }

interface PagePollProps {
  poll: Poll
  isReady: boolean
  podId: string
  pod: Pod
  userId: string
  friendIds: string[]
  pollAddOptionThunk: PollAddOptionThunk
  pollToggleChoiceThunk: PollToggleChoiceThunk
  users: User[]
  nbUsers: number
  podTrackTarget: PodTrackTarget // Action to update the target pod id to track
  trackPodIdTarget: string // Curren tvalue of the pod id to track
}

function StringToModuleType (type: string) {
  switch (type) {
    case 'list':
      return ModuleType.LIST
    case 'list_n':
      return ModuleType.LIST_N
    default:
      return ModuleType.POLL
  }
}

const PagePoll = (props: PagePollProps): ReactElement | null => {
  debug(`userId:${props.userId}, podId:${props.podId}, trackPodIdTarget:${props.trackPodIdTarget}, pollId:${props.poll?._id}, isReady:${props.isReady}, nbUsers:${props.nbUsers}`)
  const dispatch = useDispatch()
  const [userConfirmationRequested, setUserConfirmationRequested] = useState(false) // confirmation of the user in the pod
  const [checkedUnknownUserIds] = useState(new Map<string, boolean>())

  // TODO : change this to a real waiting layout
  if (!props.isReady) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  // Sames as in PagePod.tsx TODO: centralize pod update and users update
  const checkWeHaveAllUsers = () => {
    if (props.pod == null || props.users == null) { return }
    const userIdMap = new Map<string, boolean>()

    props.users.forEach((user: User) => userIdMap.set(user._id, true))

    let unkwownUserIds: string[] = props.pod.usersConfirmed

    unkwownUserIds = unkwownUserIds.filter((userId: string) => !userIdMap.has(userId))
    unkwownUserIds = unkwownUserIds.filter((userId: string) => !checkedUnknownUserIds.has(userId))

    if (unkwownUserIds.length !== 0) {
      // FIXME: improve fix with something better than checkedUnknownUserIds
      dispatch(refreshUsersThunk(props.pod._id))
      unkwownUserIds.forEach((id: string) => checkedUnknownUserIds.set(id, true))
    }
  }

  checkWeHaveAllUsers()
  // Add pending user to confirmed if the user clicks on the poll link
  if (props.userId && props.pod?.usersPending.includes(props.userId) && !userConfirmationRequested) {
    console.log('update user to confirmed')
    dispatch(podUpdateUserThunk(props.pod, true, props.userId))
    setUserConfirmationRequested(true)
  }

  // update pod id to track only if necessary
  if (props.podId != null && props.podId !== props.trackPodIdTarget) {
    debug(`Track podId:${props.podId}`)
    props.podTrackTarget(props.podId)
  }

  // TODO: check if poll exists otherwise return to pod or pods page
  if (!props.poll) {
    return null
  }

  return (
    <LayoutPoll
      poll={props.poll}
      podId={props.podId}
      userId={props.userId}
      friendIds={props.friendIds}
      onAddOption={(option: string) => props.pollAddOptionThunk(props.poll, option)}
      onToggleChoice={(optionId: string, userId: string) => props.pollToggleChoiceThunk(props.poll, optionId, userId)}
      nbUsers={props.nbUsers}
      users={props.users}
      moduleType={StringToModuleType(props.poll?.type)}
    />
  )
}

interface RootState {
  polls: any
  pods: any
  users: any
  login : {
    user: User
  }
  relationships: any
  podsSlice : {
    trackPodIdTarget: string
  }
}

const mapState = (state: RootState, ownProps: any) => {
  const idMetaData = ownProps?.match?.params?.id
  const podId = ownProps?.match?.params?.podid
  const id = idMetaData.split('_')[0] // FIXME: Temporary fix.
  const poll = (id != null && state?.polls?.queryResult?.find != null)
    ? state.polls.queryResult.find((poll: Poll) => poll._id === id)
    : null
  const pod = state?.pods?.queryResult?.find != null ? state?.pods?.queryResult?.find((pod : Pod) => pod._id === poll?.podId) : null
  const usersIds = pod?.usersConfirmed != null ? pod.usersConfirmed : []
  const nbUsers = usersIds.length

  // get all users to have firstnames and lastnames because we need to render who voted for which option in poll
  let users : User[] = []
  for (let i = 0; i < usersIds.length; i++) {
    const curUser = state.users.queryResult?.find((u : any) => (u._id === usersIds[i]))
    if (curUser !== undefined) users = [...users, curUser]
  }

  // Get user's friend ids
  const userId = state.login?.user?._id
  const relationships = state?.relationships?.queryResult
  const friendIds = (Array.isArray(relationships) && userId) ? getUserFriends(relationships, userId) : []

  return ({
    poll,
    podId: poll?.podId || podId,
    pod,
    nbUsers,
    users,
    userId,
    friendIds,
    isReady: !state.polls.findPending,
    trackPodIdTarget: state.podsSlice.trackPodIdTarget
  })
}

export default connect(mapState, mapDispatch)(PagePoll)
