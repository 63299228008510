import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 500
  },
  progress: {
    marginTop: theme.spacing(1),
    height: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: '#EDEDED'
  },
  progressRed: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#DF0000'
    }
  },
  progressOrange: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#E48100'
    }
  },
  progressYellow: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#E4C600'
    }
  },
  progressGreen: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#6FC400'
    }
  },
  info: {
    marginTop: theme.spacing(1),
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'justify',
    marginBottom: theme.spacing(1)
  }
}))

const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z\\d])(?=.{8,})') // support all special chars including invisible chars
const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')
const weakRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{4,})')

const analyze = (password: string) => {
  if (strongRegex.test(password)) {
    return ('strong')
  } else if (mediumRegex.test(password)) {
    return ('medium')
  } else if (weakRegex.test(password)) {
    return ('weak')
  } else {
    return ('veryWeak')
  }
}

export interface PasswordStrengthProps {
    password: string
}

const PasswordStrength = ({ password } : PasswordStrengthProps): ReactElement => {
  const { t } = useTranslation('translation')
  const classes = useStyles()

  const progress: any = {
    strong: { label: 'login.strong', value: 100, colorClass: classes.progressGreen },
    medium: { label: 'login.medium', value: 75, colorClass: classes.progressYellow },
    weak: { label: 'login.weak', value: 50, colorClass: classes.progressOrange },
    veryWeak: { label: 'login.veryWeak', value: 25, colorClass: classes.progressRed }
  }

  const strength = analyze(password)

  const progressTitle = t('login.security') + ' : ' + t(progress[strength].label)
  const progressValue = progress[strength].value
  const progressColorClass = progress[strength].colorClass

  return (
    <div>
      <Typography className={classes.title} variant='body2'>
        {progressTitle}
      </Typography>
      <LinearProgress className={`${classes.progress} ${progressColorClass}`} variant='determinate' value={progressValue} />
      <Typography className={classes.info} variant='body2'>
        {t('login.securityLevel')}
      </Typography>
    </div>
  )
}

export default PasswordStrength
