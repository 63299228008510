// Types definitions
//
export enum ModuleType {
  POLL = 'poll',
  LIST = 'list',
  LIST_N = 'list_n',
  QUIZZ = 'quizz',
  INTRO = 'intro',
  UNREAD_BREAKLINE = 'unread_breakline'
}
export enum ModuleStatus {
  CREATED = 'created',
  STARTED = 'started',
  SUSPENDED = 'suspended',
  CLOSED = 'closed',
}
// INTERFACES
// Abstract representation of a Module in the system
// A Module is a structured collboration item
// These are the common properties to all modules
export interface Module {
  type: ModuleType // Module type, can be one of the ModuleType
  id: string // id@db.<module>
}

// Concrete representation of a Pod in the system
// A Pod is a container for interactions = users + chat + list of modules
export interface Pod {
  _id: string // id@db.pod
  title: string // pod title, used in glimpse for example
  isDraft : boolean // indicates if the pod is in draft mode, like in gmail drfats, not used yet
  creationDate? : Date // pod creation date
  lastModificationDate: Date // date of last modification (if we send a message in the chat for example)
  creatorId? : string // id@db.users
  description?: string // longer description of the pod, optionnal
  dateStart?: Date // start date of the pod when this has a meaning, optionnal
  dateEnd?: Date // end date of the pod when this has a meaning, optionnal
  location?: string // location the pod when this has a meaning, optionnal
  modules?: Module[] // modules (aka modules) used by the pod, it is a list of id@db.<module>
  usersConfirmed: string[] // users that have confirmed their participation, list of id@db.users, these users can participate (chat, etc...)
  usersPending: string[] // users that have confirmed their participation, list of id@db.users, these users need to confirm before participating
  usersDeclined: string[] // users that declined their participation, list of id@db.users, these users won't participate, the pod should not be listed
  usersMaybe: string[] // users that might confirm their participation, list of id@db.users, these users need to confirm before participating
}

export enum PodUpdateUserOperation {
  PENDING = 'pending',
  CONFIRM = 'confirmed',
  DECLINE = 'declined',
  MAYBE = 'maybe',
  LEAVE = 'leave'
}

export interface ProcessedMedia {
  url: string
  mimeType: string
  fileSize: number
  originalName: string
}

export interface Post extends PostData{
  title?: string
  closed?: boolean
  moduleId?: string
  moduleType?: ModuleType
  user?: any
  postType?: string
  userColor?: string
  processedMedias?: ProcessedMedia[]
}
interface PostData {
  _id?: string // TODO: define difference with moduleId
  podId: string
  message: string
  creatorId?: string
  creationDate: Date
  medias?: string[] | null
}

export interface User {
  _id: string
  firstname: string
  lastname: string
  avatar?: string
  isAdmin: boolean
  onboarding: number
  isVerified: boolean
  locale: string
  verifyToken: string
  email?: string
  isOnline?: boolean
}

export interface PollDataUser {
  role: string
  id: string
  status: string
}

export interface PollOption {
  id: string
  text: string
  creationDate: Date
  creatorId: string
}

export interface PollChoice {
  userId: string
  optionId: string
  strength: number
  creationDate: Date
}

export enum PollOptionOperation {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  TOGGLE_CHOICE = 'toggle-choice',
}
export interface PollOptionOperationParams {
  operation: PollOptionOperation
  optionText?: string
  optionId?: string
}

export interface PollConfigParams {
  enableMultipleChoices?: boolean // users vote for all propositions they want or limited number of votes
  maxMultipleChoicePerUser?: number // number max of propositions a user can select
  enableMultipleUserPerOption?: boolean // options only have multiple slots or only one slot free
  maxMultipleUserPerOption?: number // number max of voters per option
  enableUsersOptionProposal?: boolean // every user can add propositions or only users selected can
  maxUsersOptionProposal?: number // number max of users that can add options
  usersOptionProposalAllowedIDs?: string[] // list of users ids allowed to add propositions
  enableAnonymousChoice?: boolean // vote is anonymous
  enableAnonymousOptionProposal?: boolean // cannot see who proposed the option
}

export interface Poll {
  _id?: string
  title: string
  lastUpdate: Date
  glimpse: string
  nextId: number
  description: string
  creationDate: Date
  endDate?: Date
  closed?:boolean
  creatorId : string
  type: ModuleType
  podId: string
  optionOperationParams?: PollOptionOperationParams
  config: PollConfigParams
  options: PollOption[]
  choices: PollChoice[]
  users: PollDataUser[]
}

export interface Relationship {
  _id: string
  requesterId: string
  requestedId: string
  status: string
  creationDate: Date
  relationDate: Date
}

export interface Credentials {
  email: string
  password: string
}

export interface MsToken {
  token: string
}

export interface SignUpData {
  firstname: string
  lastname: string
  email: string
  password: string
  locale: string
}

export interface Media {
  _id: string
  creationDate: Date
  uri: string
  attributes: any
  creatorId: string
  trackPackIds: string[]
  originalName: string
  mimeType: string
  fileSize: number
  url: string
}

export enum NotificationType {
  NEW = 'new',
  UPDATE = 'update',
  DELETE = 'delete',
  CLOSE = 'close'
}
export enum NotificationObjectType {
  POD = 'pod',
  POST = 'post',
  MODULE = 'module',
  // TODO: add Proposition
}
export interface NotificationData {
  _id?: string
  type: NotificationType
  objectId: string
  objectType: NotificationObjectType
  parentIds: string[]
  userId: string
  creationDate: Date
  expirationDate?: Date
}
