// store/UserProvider.js
import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import LayoutSignUp from '../../components/Layouts/LayoutSignUp'
import { Redirect } from 'react-router-dom'
import { usersClient } from '../../App'
import { loginThunk, signupThunk, signUpUpdateThunk } from './loginSlice'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { FeathersServices, FeathersOpaqueData } from '../../interfaces/interfacesFeathers'
import { Credentials, SignUpData, User } from '../../interfaces/interfacesApp'
import i18n from '../../i18n'
import CookiesDialog from '../../components/ModalDialogs/CookiesDialog'
import { useModal } from 'mui-modal-provider'
import InformationDialog from '../../components/ModalDialogs/InformationDialog'
import { AppState } from '../../interfaces/AppState'
import { isPublicEmail, LOCAL_STORAGE_PUBLIC_EMAIL_KEY, LOCAL_STORAGE_PUBLIC_NAME_KEY } from '../../lib/utils'

type OnCreate = (data: SignUpData) => void

type OnUpdate = (id: string | undefined, data: User) => void

type LoginCall = (creds: Credentials) => void
export interface SignUpProps {
  users: FeathersServices
  onCreate: OnCreate
  login: LoginCall
  onUpdate: OnUpdate
}

const SignUp = (props: SignUpProps) => {
  const user = useSelector((state:AppState) => state.login.user)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [tried, setTried] = useState(false)
  const [title] = useState('Sign Up')
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const isPublicAccount = isPublicEmail(user?.email || '')

  // Matomo tracking
  const { trackPageView, trackEvent } = useMatomo()
  const { showModal } = useModal()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'SignUp'
    })
    if (!isPublicAccount) {
      const modal: any = showModal(CookiesDialog, { onConfirm: () => modal.hide() })
    }
  }, [])

  if (props.users.isFinished === true && open === false) {
    if (success) { return <Redirect to='/' /> }
    if (tried === true) {
      setTried(false)
      if (props.users.isError !== null) {
        /* setDescription(`Erreur de creation du compte \r\n ${props.users.isError.errorType || ''} - ${props.users.isError.message || ''}`)
        const modal: any = showModal(InformationDialog, {
          title: title,
          description: description,
          onClose: () => modal.hide()
        }) */
        trackEvent({ category: 'signup-page', action: `error ${props.users.isError.errorType || ''} - ${props.users.isError.message || ''}` })
      } else if (isPublicAccount) {
        setSuccess(true)
        trackEvent({ category: 'signup-page', action: 'success' })
        window.localStorage.removeItem(LOCAL_STORAGE_PUBLIC_EMAIL_KEY)
        window.localStorage.removeItem(LOCAL_STORAGE_PUBLIC_NAME_KEY)
      } else {
        setSuccess(true)
        trackEvent({ category: 'signup-page', action: 'success' })
        props.login({ email, password })
      }
    }
  }

  return (
    <div>
      <LayoutSignUp
        isPublicAccount={isPublicAccount}
        signUp={(data: SignUpData) => {
          if (data.firstname !== '' &&
                data.lastname !== '' &&
                data.password !== '' &&
                data.email !== ''
          ) {
            data.locale = i18n.language.slice(0, 2) // TODO use locale and not language
            const updatedUser = { ...user, ...data }
            isPublicAccount && user ? props.onUpdate(updatedUser._id, updatedUser as User) : props.onCreate(data); setTried(true)
          }
        }}
        setEmail={setEmail}
        setPassword={setPassword}
      />
    </div>
  )
}

interface RootState {
  login: {
    status: string
    email: string
  }
  users: FeathersServices
}

const mapStateToProps = (state: RootState) => ({
  status: state.login.status,
  email: state.login.email,
  users: state.users
})

const mapDispatchToProps = (dispatch: any) => ({
  onUpdate: (id: string | undefined, data: FeathersOpaqueData) => { dispatch(signUpUpdateThunk(id, data)) },
  onCreate: (data: FeathersOpaqueData) => { dispatch(signupThunk(data)) },
  login: (credentials: Credentials) => { dispatch(loginThunk(credentials, usersClient)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
