import { Badge, Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import React, { memo, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from '../../../interfaces/AppState'
import { ModuleType, NotificationData, NotificationObjectType, NotificationType, Pod, Poll } from '../../../interfaces/interfacesApp'
import { getColorFromID, getNumberOfHours, getNumberOfMinutes } from '../../../lib/utils'
import AvatarPod from '../../Widgets/AvatarPod'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  newIcon: {
    color: 'red'
  },
  unset: {
    overflow: 'hidden'
  },
  bold: {
    overflow: 'hidden',
    fontWeight: 'bold'
  }
}))

interface PodItemProps {
  pod: Pod,
  setPodId: any,
  setInviteId: any,
  isInvitation: boolean,
  notifications: NotificationData[]
}

const PodItem = ({ pod, setPodId, setInviteId, isInvitation, notifications }: PodItemProps): ReactElement => {
  const { t } = useTranslation('translation')

  const podNotifications = notifications.filter((notification:NotificationData) => notification.parentIds.includes(pod._id))
  const newNotifications: NotificationData[] | undefined = useSelector((state: AppState) => state.notifications.queryResult?.filter((notification: NotificationData) => notification.parentIds.includes(pod._id) && notification.objectType === NotificationObjectType.POD && notification.type === NotificationType.NEW))
  // const podOpenedModules : Poll[] | undefined = useSelector((state: AppState) => state.polls.queryResult?.filter((poll: Poll) => (poll.podId === pod._id) && !(poll?.closed)))
  const podOpenedPolls : Poll[] | undefined = useSelector((state: AppState) => state.polls.queryResult?.filter((module: Poll) => (module.podId === pod._id) && (module.type === ModuleType.POLL)))
  const podOpenedLists : Poll[] | undefined = useSelector((state: AppState) => state.polls.queryResult?.filter((module: Poll) => (module.podId === pod._id) && ((module.type === ModuleType.LIST) || (module.type === ModuleType.LIST_N))))
  // const podUsers: User[] | undefined = useSelector((state: AppState) => state.users.queryResult?.filter((user: User) => pod.usersConfirmed.includes(user._id)))

  const displayNotification = podNotifications?.length !== 0
  const displayNewNotification = newNotifications?.length !== 0
  const numberOfPodOpenedPollsText = (podOpenedPolls && podOpenedPolls.length > 0) ? t('pods.numberOfPollsInPod', { count: podOpenedPolls.length }) : ''
  const numberOfPodOpenedListsText = (podOpenedLists && podOpenedLists.length > 0) ? t('pods.numberOfListsInPod', { count: podOpenedLists.length }) : ''
  const numberOfPodOpenedModulesText = numberOfPodOpenedPollsText + ((numberOfPodOpenedPollsText && numberOfPodOpenedListsText) ? t('and') : '') + numberOfPodOpenedListsText
  // const numberOfPodUsersText = (podUsers && podUsers.length > 0) ? t('pods.podEdit.memberNav', { count: podUsers.length }) : undefined

  const classes = useStyles()
  const setId = isInvitation ? setInviteId : setPodId
  const url = `/pod/${pod._id}`

  const lastModificationDate = pod.lastModificationDate ? new Date(pod.lastModificationDate) : null // TODO: What if no lastModificationDate ?

  const today = new Date()
  const now = new Date()
  today.setHours(0, 0, 0, 0)

  const oneMonthAgo = new Date(today)
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

  const oneWeekAgo = new Date(today)
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

  const oneDayAgo = new Date(now)
  oneDayAgo.setDate(oneDayAgo.getDate() - 1)

  const oneHourAgo = new Date(now)
  oneHourAgo.setHours(oneHourAgo.getHours() - 1)

  const displayMinute = (getNumberOfMinutes(lastModificationDate, now))
  const displayHours = (getNumberOfHours(lastModificationDate, now))

  return (
    <React.Fragment key={pod._id}>
      <Link to={url} className={classes.link}>
        <ListItem button key={pod.title + Math.random()} onClick={() => setId(pod._id)}>
          <ListItemAvatar>
            <AvatarPod pod={pod} width='40px' height='40px' fontSize='1.5rem' opacity='30%' borderRadius='12px' backgroundColor={getColorFromID(pod._id)} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box>
                <Typography
                  style={{ width: '65vw' }}
                  className={displayNotification || isInvitation ? classes.bold : classes.unset}
                  noWrap
                >{pod.title}
                </Typography>
              </Box>
            }
            secondary={numberOfPodOpenedModulesText}
          />
          {!isInvitation && lastModificationDate !== null
            ? <Typography style={{ color: 'gray', fontSize: '11px' }}> {
              lastModificationDate.getFullYear() < now.getFullYear() ? new Intl.DateTimeFormat('fr-FR', {
                year: '2-digit',
                month: 'short',
                day: 'numeric'
              }).format(lastModificationDate)
                : lastModificationDate <= oneMonthAgo ? new Intl.DateTimeFormat('fr-FR', {
                  month: 'short',
                  day: 'numeric'
                }).format(lastModificationDate)
                  : lastModificationDate < oneDayAgo ? new Intl.DateTimeFormat('fr-FR', {
                    month: 'short',
                    day: 'numeric'
                  }).format(lastModificationDate)
                    : lastModificationDate <= oneHourAgo ? `${displayHours} h`
                      : `${displayMinute} min`
            } </Typography> : null}
          <Typography>
            {displayNewNotification
              ? <FiberNewIcon className={classes.newIcon} />
              : displayNotification
                ? <Badge style={{ marginLeft: '10px' }} color='secondary' overlap='circle' variant='dot' />
                : null}
          </Typography>
        </ListItem>
      </Link>
      <Divider />
    </React.Fragment>
  )
}

export default memo(PodItem)
