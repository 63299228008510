import React, { memo } from 'react'
import { List, ListItem, makeStyles, Paper } from '@material-ui/core'
import { ModuleType, Poll } from '../../../interfaces/interfacesApp'
import { ModuleItemPoll } from './ModuleItemPoll'
import { ModuleItemList } from './ModuleItemList'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  card: {
    borderRadius: '12px',
    width: '100%'
  },
  noUnderline: {
    textDecoration: 'none'
  }
}))

export interface ModuleListProps {
    users: string[],
    polls: Poll[],
    loggedInUserId: string,
    podId: string
}

export const ModuleList = ({ users, polls, loggedInUserId, podId }: ModuleListProps): any => {
  const classes = useStyles()

  // sort all polls by lastUpdate
  const orderedPolls = polls.sort((pollA: Poll, pollB: Poll) => ((!pollA.closed !== !pollB.closed) ? (pollA.closed ? 1 : -1) : ((pollA.lastUpdate < pollB.lastUpdate) ? 1 : -1)))

  return orderedPolls.map((poll: Poll) => {
    return (
      <ListItem style={{ display: 'inline-block' }} key={poll._id}>
        <Link className={classes.noUnderline} to={`/pod/${podId}/poll/${poll._id}?back=modules`}>
          <Paper className={classes.card} variant='outlined'>
            <List>
              {poll.type === ModuleType.POLL
                ? <ModuleItemPoll users={users} poll={poll} />
                : (poll.type === ModuleType.LIST || poll.type === ModuleType.LIST_N)
                  ? <ModuleItemList list={poll} loggedInUserId={loggedInUserId} />
                  : <ModuleItemPoll users={users} poll={poll} />}
            </List>
          </Paper>
        </Link>
      </ListItem>
    )
  })
}

export default memo(ModuleList)
