import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { podsServices, podsClient, usersServices, usersClient, postsServices, pollsServices } from '../../App'

import { Pod, PodUpdateUserOperation, User } from '../../interfaces/interfacesApp'

import { FeathersClient, FeathersServices } from '../../interfaces/interfacesFeathers'

export type State = {
  trackPodIdTarget: string
  trackPodId: string
  trackingState: string
  trackingStateError: string | null
  create: string
  createError: string | null
  update: string
  updateError: string | null
  podInviteId: string | null
  invitePage: string | null
  inviteStatus: string
  addUser: string
  addUserError: string | null
  usersQuery: string
  usersQueryError: string | null
}

const initialState: State = {
  trackPodIdTarget: '',
  trackPodId: '',
  trackingState: '',
  trackingStateError: null,
  create: '',
  createError: null,
  update: '',
  updateError: null,
  podInviteId: null,
  invitePage: null,
  inviteStatus: '',
  addUser: '',
  addUserError: null,
  usersQuery: '',
  usersQueryError: null
}

const podsSlice = createSlice({
  name: 'podsControl',
  initialState,
  reducers: {
    podTrackTarget (state, action: PayloadAction<any>) {
      return {
        ...state,
        trackPodIdTarget: action.payload
      }
    },
    podTrack (state, action: PayloadAction<any>) {
      return {
        ...state,
        trackPodId: action.payload.trackPodId,
        trackingState: 'ongoing',
        trackingStateError: null
      }
    },
    podTrackSuccess (state) {
      return {
        ...state,
        trackingState: 'done'
      }
    },
    podTrackError (state, action : any) {
      console.log(action)
      const errorMessage = action.payload.errorMessage
      return {
        ...state,
        trackingState: 'error',
        trackingStateError: errorMessage
      }
    },
    podsCreateRequest (state) {
      return {
        ...state,
        create: 'creating'
      }
    },
    podsCreateSuccess (state) {
      return {
        ...state,
        create: 'done'
      }
    },
    podsCreateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        create: 'error',
        createError: errorMessage
      }
    },
    podUpdateRequest (state) {
      return {
        ...state,
        update: 'updating'
      }
    },
    podUpdateSuccess (state) {
      return {
        ...state,
        update: 'updated'
      }
    },
    podUpdateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        update: 'error',
        updateError: errorMessage
      }
    },
    podInvite (state, action: PayloadAction<any>) {
      const podInviteId = action.payload.podInviteId
      const invitePage = action.payload.invitePage

      return {
        ...state,
        podInviteId,
        invitePage,
        inviteStatus: 'invited'
      }
    },
    podInviteSuccess (state) {
      return {
        ...state,
        podInviteId: null,
        invitePage: null,
        inviteStatus: 'done'
      }
    },
    podAddUserError (state, action : any) {
      console.log(action)
      const errorMessage = action.payload.errorMessage
      return {
        ...state,
        addUser: 'error',
        addUserError: errorMessage
      }
    },
    podUsersRequest (state) {
      return {
        ...state,
        usersQuery: 'querying'
      }
    },
    podUsersSuccess (state) {
      return {
        ...state,
        usersQuery: 'done'
      }
    },
    podUsersError (state, action: PayloadAction<any>) {
      return {
        ...state,
        usersQuery: 'error',
        usersQueryError: action.payload.errorMessage
      }
    }
  }
})

export const {
  podsCreateRequest, podsCreateSuccess, podsCreateError,
  podTrack, podTrackSuccess, podTrackError, podTrackTarget,
  podUpdateRequest, podUpdateSuccess, podUpdateError,
  podInvite, podInviteSuccess, podAddUserError,
  podUsersRequest, podUsersSuccess, podUsersError
} = podsSlice.actions

export type PodTrackTarget = (podId: string) => void

// TODO CLEANUP: Track useless podUpdateRequest & podUpdateSuccess
export const podEditThunk = (pod: Pod, data: any): AppThunk => async dispatch => {
  dispatch(podsServices.pods.patch(pod._id, data))
}

export type PodCreateThunk = (data: any, setIdCreate: any, title: string, date: Date, desc: string, user: User | null) => void

export const podCreateThunk = (data: any, setIdCreate: any, title: string, date: Date, desc: string, user: User | null): AppThunk => async dispatch => {
  try {
    // await podsServices.pods.create(data)
    dispatch(podsCreateRequest())
    data.title = title
    if (date !== null) {
      data.dateEnd = date
    }

    if (desc !== '') {
      data.description = desc
    }

    const ev = await (podsClient as FeathersClient).service('/pods').create(data)
    setIdCreate(ev._id)

    if (user?.onboarding === 1) {
      await (usersServices as FeathersServices).users.patch(user._id, { onboarding: 2 })
    }

    await dispatch((podsServices as FeathersServices).pods.find()) // TODO: use result TODO: do we need a new find BTW

    dispatch(podsCreateSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(podsCreateError(e.message))
  }
}

export type PodAddUserThunk = (data : any, userId : string, podId : string, userOnboarding?: number) => void

export const podAddUserThunk = (data : any, userId : string, podId : string, userOnboarding?: number): AppThunk => async dispatch => {
  try {
    data.userId = userId
    data.operation = PodUpdateUserOperation.PENDING
    await dispatch((podsServices as FeathersServices).podUser.update(podId, data))

    if ((userOnboarding != null) && (userOnboarding < 2)) {
      const partialUser: Partial<User> = { onboarding: 2 }
      await dispatch((usersServices as FeathersServices).users.patch(userId, partialUser))
    }
  } catch (e) {
    console.log('error', e)
  }
}
const removeFromArray = (a: any, v: any) => {
  const index = a.findIndex((e: any) => e === v)
  if (index !== -1) { a.splice(index, 1) }
}

export type PodLeaveUserThunk = (pod: Pod, userId: string) => void

export const podLeaveUserThunk = (pod: Pod, userId: string): AppThunk => async dispatch => { // TODO: SLF should be an API managing the arrays internally
//  const newPod = Object.assign({}, pod)
//  removeFromArray(newPod.usersConfirmed, userId)
  const data = {
    userId: userId,
    operation: PodUpdateUserOperation.LEAVE
  }
  try {
    await dispatch((podsServices as FeathersServices).podUser.update(pod._id, data))
    await dispatch((podsServices as FeathersServices).pods.find()) // Find required to remove the pod from the list
  } catch (e) {
    console.log('error', e)
  }
}

export type PodUpdateUserThunk = (pod: Pod, reply: boolean, userId: string) => void

export const podUpdateUserThunk = (pod: Pod, reply: boolean, userId: string): AppThunk => async dispatch => {
  const data = {
    userId: userId,
    operation: PodUpdateUserOperation.CONFIRM
  }

  try {
    dispatch(podUpdateRequest())
    await (podsServices as FeathersServices).podUser.update(pod._id, data)
    // await store.dispatch(podsServices.pods.find()) // TODO: use result TODO: do we need a new find BTW
    dispatch(podUpdateSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(podUpdateError(e.message))
  }
}

export type PodTrackThunk = (userId: string, podId: string) => void

export const podTrackThunk = (userId: string, podId: string): AppThunk => async dispatch => {
  try {
    dispatch(podTrack({ trackPodId: podId }))
    await (usersClient as FeathersServices).service('/podtracker').update(userId, { podId })
    if (podId != null && podId !== '') {
      await dispatch((postsServices as FeathersServices).posts.find({ query: { podId } }))
      await dispatch((pollsServices as FeathersServices).polls.find({ query: { podId } }))
    }
    dispatch(podTrackSuccess())
  } catch (e) {
    dispatch(podTrackError(e.message))
  }
}

export type PodUsersThunk = (pod: Pod) => void

export const podUsersThunk = (pod: Pod): AppThunk => async dispatch => {
  // the purpose of the thunk is to update users in the store
  try {
    dispatch(podUsersRequest())
    await dispatch((usersServices as FeathersServices).users.find({ query: { podId: pod._id } }))
    dispatch(podUsersSuccess())
  } catch (e) {
    console.log('error', e)
    dispatch(podUsersError(e.message))
  }
}

export type OnboardingThunk = (user: User) => void

export const onboardingThunk = (user: User): AppThunk => async dispatch => {
  // we are modifying the user, we need to use a copy in case
  let localOnboarding = user.onboarding
  // the purpose of the thunk is to update users in the store
  try {
    if (localOnboarding === 0) {
      localOnboarding = 1
    } else {
      localOnboarding = 2
    }

    await (usersServices as FeathersServices).users.patch(user._id, { onboarding: localOnboarding })
  } catch (e) {
    console.log('error', e)
  }
}

export default podsSlice.reducer
