import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined'
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined'
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

export enum MediaSwipeableSelection{
  IMAGE,
  VIDEO,
  AUDIO,
  DOCUMENT,
  NONE
}
export type OnMediaSwipeableSelectorHandleSelection = (selection: MediaSwipeableSelection) => void

export interface MediaSwipeableSelectorProps {
  open: boolean,
  onSelection: OnMediaSwipeableSelectorHandleSelection,
}

export const useStyles = makeStyles(() => ({
  CCC: {
    fontSize: '17px',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  }
}))

const MediaSwipeableSelector = ({ open, onSelection } : MediaSwipeableSelectorProps): any => {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const handleImageClick = () => {
    onSelection(MediaSwipeableSelection.IMAGE)
  }
  const handleVideoClick = () => {
    onSelection(MediaSwipeableSelection.VIDEO)
  }
  const handleAudioClick = () => {
    onSelection(MediaSwipeableSelection.AUDIO)
  }
  const handleDocumentClick = () => {
    onSelection(MediaSwipeableSelection.DOCUMENT)
  }
  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    onSelection(MediaSwipeableSelection.NONE)
  }

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      // disableBackdropTransition={!iOS}
      // disableDiscovery={iOS}
    >
      <div role='presentation' onClick={toggleDrawer()} onKeyDown={toggleDrawer()}>
        <List>
          <ListItem>
            <ListItemText primary={t('chat.mediaSelector.title')} />
          </ListItem>
        </List>
        <Divider />
        <List onClick={handleImageClick}>
          <ListItem button key='image'>
            <ListItemIcon><PhotoLibraryOutlinedIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.mediaSelector.image')}</ListItemText>
            </div>
          </ListItem>
        </List>
        <Divider />
        <List onClick={handleVideoClick}>
          <ListItem button key='video'>
            <ListItemIcon><VideoLibraryOutlinedIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.mediaSelector.video')}</ListItemText>
            </div>
          </ListItem>
        </List>
        <Divider />
        <List onClick={handleAudioClick}>
          <ListItem button key='audio'>
            <ListItemIcon><MicNoneOutlinedIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.mediaSelector.audio')}</ListItemText>
            </div>
          </ListItem>
        </List>
        <Divider />
        <List onClick={handleDocumentClick}>
          <ListItem button key='document'>
            <ListItemIcon><InsertDriveFileOutlinedIcon /></ListItemIcon>
            <div>
              <ListItemText className={classes.CCC}>{t('chat.mediaSelector.document')}</ListItemText>
            </div>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  )
}

export default MediaSwipeableSelector
