import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import { Post, ModuleType, NotificationData, NotificationObjectType, NotificationType } from '../../interfaces/interfacesApp'
import { StyledClosedModuleIcon, StyledListIcon, StyledPollIcon } from '../Styled/StyledIcons'
import DoneIcon from '@material-ui/icons/Done'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { useSelector } from 'react-redux'
import { AppState } from '../../interfaces/AppState'
import { WidgetModuleProgressStatus } from './WidgetModuleProgressStatus'
import { Badge, ListItemText, Typography } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  module: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    borderRadius: '12px',
    borderColor: 'lightgray',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    textDecoration: 'none',
    color: '#000000',
    backgroundColor: '#fff'
  },
  toolbar: {
    display: 'flex'
  },
  toolbarallsize: {
    flexGrow: 2,
    paddingLeft: '0.1rem'
  },
  toolbariconselected: {
    width: '16px',
    height: '16px',
    color: 'lightgrey'
  },
  toolbaricondeselected: {
    width: '16px',
    height: '16px'
  },
  allsize: {
    flexGrow: 2,
    paddingLeft: '1rem'
  },
  moduleTitle: {
    fontFamily: 'Roboto',
    fontSize: '17px'
  },
  moduleGlimpse: {
    fontWeight: 400,
    color: '#888888',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  postList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  link: {
    textDecoration: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid black',
    width: '70%',
    marginLeft: '10px',
    marginRight: '10px'
  },
  content: {
    padding: 'O 10px O 10px',
    fontSize: '14px'
  }
}))
interface FlowTypePollProps {
  post: Post
  messagesEndRef: any,
  podId: string
}

function FlowTypePoll ({ podId, post, messagesEndRef }: FlowTypePollProps): ReactElement {
  const newNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectId === post.moduleId &&
      notification.type === NotificationType.NEW &&
      notification.objectType === NotificationObjectType.MODULE))
  const displayNewNotification = newNotifications?.length !== 0

  const updateNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectId === post.moduleId &&
      notification.type === NotificationType.UPDATE &&
      notification.objectType === NotificationObjectType.MODULE))
  const displayUpdateNotification = updateNotifications?.length !== 0

  const classes = useStyles()
  // if later we have one microservice by ccc <Link className={classes.link} to={`/${post.moduleType}/${post.moduleId}`}>
  return (
    <Link className={classes.link} to={`/pod/${podId}/poll/${post.moduleId}`}>
      <ListItem disabled={post.closed} className={classes.postList}>
        <Box className={classes.module}>
          <StyledPollIcon />
          <div className={classes.allsize}>
            <div className={classes.moduleTitle} style={{ fontWeight: displayNewNotification || displayUpdateNotification ? 500 : 'unset' }}>{post.title}</div>
            <div className={classes.moduleGlimpse}>{post.message}</div>
            <div ref={messagesEndRef} />
          </div>
          {post.closed
            ? <StyledClosedModuleIcon />
            : displayNewNotification
              ? <FiberNewIcon color='error' />
              : displayUpdateNotification
                ? <Badge style={{ paddingLeft: '10px' }} variant='dot' color='error' />
                : null}
        </Box>
      </ListItem>
    </Link>
  )
}

interface FlowTypeListProps {
  post: Post
  messagesEndRef: any,
  podId: string
}

function FlowTypeList ({ podId, post, messagesEndRef }: FlowTypeListProps): ReactElement {
  const newNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectId === post.moduleId &&
      notification.type === NotificationType.NEW &&
      notification.objectType === NotificationObjectType.MODULE))
  const displayNewNotification = newNotifications?.length !== 0

  const updateNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectId === post.moduleId &&
      notification.type === NotificationType.UPDATE &&
      notification.objectType === NotificationObjectType.MODULE))
  const displayUpdateNotification = updateNotifications?.length !== 0

  const classes = useStyles()
  const progressValue = parseInt(post.message.substr(0, post.message.indexOf(' ')))
  return (
    <Link className={classes.link} to={`/pod/${podId}/poll/${post.moduleId}`}>
      <ListItem disabled={post.closed} className={classes.postList}>
        <Box className={classes.module}>
          <StyledListIcon />
          <div className={classes.allsize}>
            <div className={classes.moduleTitle} style={{ fontWeight: displayNewNotification || displayUpdateNotification ? 500 : 'unset' }}>{post.title}</div>
            {!isNaN(progressValue)
              ? (
                <div className={classes.toolbar}>
                  {post.message[post.message.length - 1] === '1' // last number of the string is '1' if currentUser voted, '0' otherwise
                    ? <DoneIcon className={classes.toolbaricondeselected} />
                    : <DoneIcon className={classes.toolbariconselected} />}
                  <div className={classes.toolbarallsize}>
                    <WidgetModuleProgressStatus value={progressValue} />
                  </div>
                </div>)
              : null}
            <div ref={messagesEndRef} />
          </div>
          {post.closed
            ? <StyledClosedModuleIcon />
            : displayNewNotification
              ? <FiberNewIcon color='error' /> : displayUpdateNotification
                ? <Badge style={{ paddingLeft: '10px' }} variant='dot' color='error' /> : null}
        </Box>
      </ListItem>
    </Link>
  )
}

interface FlowTypeNotificationProps {
  post: Post
}

function FlowTypeNotification ({ post }: FlowTypeNotificationProps): ReactElement {
  const classes = useStyles()
  return (
    <>
      <ListItem className={classes.container}>
        <ListItemText className={classes.border} />
        <Typography className={classes.content}>{post.message}</Typography>
        <ListItemText className={classes.border} />
      </ListItem>
    </>
  )
}

interface WidgetPostModuleProps {
    post: Post
    messagesEndRef: any,
    podId: string
}

export default function WidgetPostModule ({ podId, post, messagesEndRef } : WidgetPostModuleProps): any {
  switch (post.moduleType) {
    case ModuleType.POLL:
      return <FlowTypePoll podId={podId} post={post} messagesEndRef={messagesEndRef} />
    case ModuleType.LIST:
    case ModuleType.LIST_N:
      return <FlowTypeList podId={podId} post={post} messagesEndRef={messagesEndRef} />
    case ModuleType.UNREAD_BREAKLINE:
      return <FlowTypeNotification post={post} />
    case ModuleType.INTRO :
      return null
    default : return <FlowTypePoll podId={podId} post={post} messagesEndRef={messagesEndRef} />
  }
}
