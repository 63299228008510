import React, { ReactElement, useState } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import NavBar from '../Menus/NavBar'
import FeedbackDialog from '../Popup/FeedbackDialog'
import { ReactComponent as PersonRemoveIcon } from '../../assets/person_remove-24px.svg'
import { AppState } from '../../interfaces/AppState'
import { useDispatch, useSelector } from 'react-redux'
import { relationshipFeedbackReset } from '../../features/Pods/relationshipsSlice'

export const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'none'
    }
  },
  link: { textDecoration: 'none' },
  label: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginTop: '5rem'
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'large',
    marginTop: '5rem'
  },
  dot: {
    height: '50px',
    width: '50px',
    backgroundColor: 'black',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    padding: '5px',
    color: 'white',
    position: 'relative'
  },
  doted: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    padding: '5px',
    position: 'relative'
  },
  littletext: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    overflowWrap: 'break-word',
    textAlign: 'center',
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  hoveraction: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)'
    },
    '&action': {
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }
}))

export interface LayoutPersonDetailsProps {
  userName: string
  onAddFriend: any
  personId: string
  status: string
  onRemoveFriend: any
}

const LayoutPersonDetails = ({ userName, onAddFriend, personId, onRemoveFriend, status } : LayoutPersonDetailsProps): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const [message, setMessageDialog] = useState('')
  const dispatch = useDispatch()
  const successFeedback = useSelector((state: AppState) => state.relationshipSlice.feedback === 'success')
  // console.log('successFeedback', successFeedback)

  if (successFeedback) {
    // console.log('timeout feedbackReset')
    setTimeout(() => { dispatch(relationshipFeedbackReset()) }, 1000)
  }

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Viewing Invitation Link'
    })
  }, [])

  function Label () : ReactElement {
    return (
      <div className={classes.label}>
        <span>{userName}</span>
      </div>)
  }

  function IconAdd () : ReactElement {
    return (
      <div className={classes.icons}>
        <div className={classes.icon}>
          <IconButton
            className={classes.hoveraction}
            onClick={() => {
              setMessageDialog(t('invite.invitationSent'))
              onAddFriend({}, personId)
            }}
          >
            <PersonAddIcon className={classes.dot} />
          </IconButton>
          <span className={classes.littletext}>{t('invite.befriend')}</span>
        </div>
      </div>
    )
  }

  function IconRemove () : ReactElement {
    return (
      <div className={classes.icons}>
        <div className={classes.icon}>
          <IconButton
            className={classes.hoveraction}
            onClick={() => {
              setMessageDialog(t('invite.friendshipRemoved'))
              onRemoveFriend(personId)
            }}
          >
            <PersonRemoveIcon className={classes.doted} />
          </IconButton>
          <span className={classes.littletext}>{t('invite.removefriend')}</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <NavBar back title={t('invite.profile')} />
      <Label />
      {status === 'Nothing'
        ? <IconAdd />
        : <IconRemove />}
      <FeedbackDialog open={successFeedback} success description={message} />
    </>
  )
}

export default LayoutPersonDetails
