import React, { ReactElement, useState } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useTranslation } from 'react-i18next'
import PasswordStrength from './PasswordStrength'

export interface InputPasswordWithLevelProps {
  password: string,
  setPassword: any
}

const InputPasswordWithLevel = ({ password, setPassword } : InputPasswordWithLevelProps): ReactElement => {
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation('translation')

  return (
    <>
      <OutlinedInput
        id='outlined-adornment-password'
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={e => { setPassword(e.target.value) }}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        }
        label={t('login.password') + 1}
      />
      {(password.length > 0) && <PasswordStrength password={password} />}

    </>
  )
}

export default InputPasswordWithLevel
