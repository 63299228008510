import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import NavBar, { NavBarMenuProps } from '../Menus/NavBar'
import PollOptionList from './Poll/PollOptionList'
import { LayoutPollChoices } from './LayoutPollChoices'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { User, Poll, ModuleType, PollOption, NotificationData, NotificationObjectType, Pod } from '../../interfaces/interfacesApp'
import ListItemAddOption from '../Widgets/ListItemAddOption'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getServicesStatus } from '../../lib/feathers-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../interfaces/AppState'
import makeDebug from 'debug'
import WidgetSwipeableDrawerPollEdit from '../Widgets/WidgetSwipeableDrawerPollEdit'
import i18n from '../../i18n'
import WidgetTextField from '../Widgets/WidgetTextField'
import { pollDeleteThunk, pollEditThunk } from '../../features/Polls/pollsSlice'
import WidgetSwipeableDrawerVote from '../Widgets/WidgetSwipeableDrawerVote'
import { StyledFab } from '../Styled/StyledFab'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { useModal } from 'mui-modal-provider'
import ConfirmationDialog from '../ModalDialogs/ConfirmationDialog'
import { userNotificationDeleteThunk } from '../../features/userNotifications/userNotificationsSlice'
import { copyToClipboard, showShareAPI, useQuery } from '../../lib/utils'
import { urlApp } from '../../App'
import { showFeedbackDialog } from '../Popup/FeedbackDialog'
import { Snackbar } from '@material-ui/core'
import { StyledClosedModuleIcon } from '../Styled/StyledIcons'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '80px'
  },
  title: {
    flexGrow: 1
  },
  noUnderline: {
    textDecoration: 'none'
  },
  actions: {
    justifyContent: 'space-between'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    paddingRight: '1rem',
    color: 'darkgrey'
  },
  menuDelete: {
    color: 'red'
  },
  bottomIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: '1rem',
    marginRight: '1rem',
    position: 'fixed',
    bottom: '20px',
    right: '0',
    zIndex: 1100
  },
  icon: {
    display: 'flex',
    flexDirection: 'column'
  },
  disabledLayout: {
    opacity: theme.palette.action.disabledOpacity,
    pointerEvents: 'none'
  }
}))

export interface LayoutPollProps {
  poll: Poll
  onAddOption: any
  nbUsers: number
  userId: string
  friendIds: string[]
  onToggleChoice: any
  podId: string
  users: User[]
  moduleType: ModuleType
}
const debug = makeDebug('LayoutPoll:')

export default function LayoutPoll ({ poll, onAddOption, nbUsers, userId, friendIds, onToggleChoice, podId, users, moduleType }: LayoutPollProps): ReactElement|null {
  const notifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectType === NotificationObjectType.MODULE &&
      notification.type &&
      notification.objectId === poll._id))
  const pod: Pod | undefined = useSelector((state: AppState) => state.pods.queryResult?.find((pod: Pod) => pod._id === podId))
  const user: User | undefined = useSelector((state: AppState) => state.users.queryResult?.find((user: User) => user._id === userId))

  // Keep latest notifications in a ref.
  const latestNotifications = useRef(notifications)
  useEffect(() => {
    latestNotifications.current = notifications
  })

  const classes = useStyles()
  const { t } = useTranslation('translation')
  const { showModal } = useModal()
  const [removeTriggered, SetRemoveTriggered] = useState(false)
  const removePending = useSelector((state: AppState) => state.polls.removePending)
  const [updateTriggered, SetUpdateTriggered] = useState(false)
  const updatePending = useSelector((state: AppState) => state.polls.patchPending)
  const data = useSelector((state: AppState) => state.polls.data)
  const isError = useSelector((state: AppState) => state.polls.isError)
  const dispatch = useDispatch()
  const history = useHistory()

  const [togglePollEditDrawer, setTogglePollEditDrawer] = useState(false)
  const [toggleOptionDrawer, setToggleOptionDrawer] = useState(false)
  const [selectedOptionId, setSelectedOptionId] = useState('')

  const [toggleChoice, setToggleChoice] = useState(false)

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const enableUsersOptionProposal = poll?.config?.enableUsersOptionProposal || true
  const usersOptionProposalAllowedIDs = poll?.config?.usersOptionProposalAllowedIDs || [userId]
  const pollDate = poll?.endDate ? new Date(poll?.endDate) : undefined
  const formatedDate = !isNaN(Date.parse(pollDate as any)) ? Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric'
  }).format(pollDate) : undefined

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: `Viewing poll "${poll?.title}"`
    })
  }, [])

  // Delete poll notifications on unmount
  useEffect(() => {
    debug(`mount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
    return () => {
      debug(`unmount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
      if (latestNotifications.current?.length) {
        dispatch(userNotificationDeleteThunk(latestNotifications.current))
      }
    }
  }, [])

  let navBarTitle = ''
  switch (moduleType) {
    case ModuleType.LIST:
      navBarTitle = t('navbar.title.list')
      break
    case ModuleType.POLL:
      navBarTitle = t('navbar.title.poll')
      break
    case ModuleType.LIST_N:
      navBarTitle = t('navbar.title.list_n')
      break
  }

  function LayoutBottomIcons () : ReactElement {
    return (
      <>
        <div className={classes.bottomIcons}>
          <div className={classes.icon}>
            <StyledFab
              variant='extended'
              onClick={() => setToggleChoice(true)}
            ><AddBoxIcon style={{ marginRight: '10px' }} />
              {t('polls.fabButton')}
            </StyledFab>
          </div>
        </div>
      </>
    )
  }

  const handleOpenClosePoll = () => {
    if (poll?._id && !updateTriggered) {
      SetUpdateTriggered(true)
      const open = !(poll?.closed)
      dispatch(pollEditThunk(poll._id, { closed: open }))
      debug('%s poll', open ? 'Open' : 'Close')
    }
  }

  const handleRemovePollWithConfirmation = () => {
    const modal = showModal(ConfirmationDialog, {
      title: t('polls.deletePollConfirmation.title'),
      description: t('polls.deletePollConfirmation.description'),
      confirmButtonLabel: t('polls.deletePollConfirmation.confirmButton'),
      onConfirm: () => {
        if (!removeTriggered) {
          SetRemoveTriggered(true)
          dispatch(pollDeleteThunk(poll)) // will set isError to null
          debug('Removing poll')
          history.push(`/pod/${pod?._id}/modules`)
        }
        modal.hide()
      },
      onCancel: () => {
        modal.hide()
      }
    })
  }

  debug(`render: poll=${poll}, removeTriggered=${removeTriggered}, removePending=${removePending}`)

  if (updateTriggered && !updatePending) {
    if (isError) {
      const pollsStatus = getServicesStatus({ polls: { isError } }, 'polls')
      debug(`Poll not updated because of error: ${pollsStatus.message}`)
    } else if (data?.podId) {
      debug('Poll updated')
    } else {
      debug('SHOULD NEVER HAPPEN')
    }
    SetUpdateTriggered(false)
  }

  if (removeTriggered && !removePending) {
    if (isError) {
      const pollsStatus = getServicesStatus({ polls: { isError } }, 'polls')
      debug(`Poll not removed because of error: ${pollsStatus.message}`)
      // TO DISPLAY A CUSTOM ERROR MESSAGE based on a specific error
      // 1- Check error
      // 2- Display an understandable error popup
      // 3- Reset Poll error to clear data & isError (will prevent system error popup)
      /* if (pollsStatus.message === 'polls: Poll contains other user activity') {
        console.log('TODO: display an Error dialog')
        dispatch(pollsServices.polls.reset(true)) // CAUTION: do not clear queryResult
      } */
    } else if (data?.podId) {
      const to = `/pod/${data.podId}/modules`
      debug(`Poll removed, redirect to pod page: ${to}`)
      history.replace(to)
      return null
    } else {
      debug('SHOULD NEVER HAPPEN')
    }
    SetRemoveTriggered(false)
  }

  if (!poll) {
    if (removeTriggered && removePending) {
      debug('poll remove in progress, wait for removed event')
    } else {
      // TODO: implement a poll not found page ?
      debug('Unknown poll, redirect to pods page')
      history.replace('/pods')
    }
    return null
  }

  const navBarMenu : NavBarMenuProps = {
    menuItems: [
      { label: t('polls.pollMenuNavSettings'), callBack: () => setTogglePollEditDrawer(true) },
      { label: poll?.closed ? t('polls.pollMenuNavOpen') : t('polls.pollMenuNavClose'), callBack: handleOpenClosePoll },
      { label: t('polls.pollMenuNavDelete'), callBack: handleRemovePollWithConfirmation, colorProps: 'red' }
    ]
  }
  const handleTitleSave = (newPollTitle: string) => {
    if (poll?._id && (newPollTitle !== poll.title)) {
      dispatch(pollEditThunk(poll._id, { title: newPollTitle }))
    }
  }

  const handleDescSave = (newPollDesc: string) => {
    if (poll?._id && (newPollDesc !== poll.description)) {
      dispatch(pollEditThunk(poll._id, { description: newPollDesc }))
    }
  }

  const backPage = useQuery().get('back')
  const podUrl = backPage ? `/pod/${poll.podId}/${backPage}` : `/pod/${poll.podId}`
  const link = `${urlApp}/podinvite/${pod?._id}/module/${poll._id}`
  const selectedOption = poll.options.find((option : PollOption) => option.id === selectedOptionId)

  return (
    <>
      <NavBar
        backUrl={podUrl}
        title={navBarTitle}
        iconParametersPollChoices={userId === poll.creatorId}
        shareIcon={() => navigator.share !== undefined
          ? showShareAPI(link, ({ podTitle: pod?.title, podDesc: pod?.description, modTitle: poll.title, modDesc: poll.description, lang: user?.locale }))
          : (copyToClipboard(link, { podTitle: pod?.title, podDesc: pod?.description, modTitle: poll.title, modDesc: poll.description, lang: user?.locale })
            ? showFeedbackDialog(1500, t('invite.copy_success'), setMessage, setOpen) : null)}
        navBarMenu={userId === poll.creatorId ? navBarMenu : undefined}
      />
      <div className={poll.closed ? classes.disabledLayout : ''}>
        <List component='nav' className={classes.root}>
          <ListItem>
            <WidgetTextField value={poll.title} style={{ underline: true, sizeFont: '2em', multiline: true }} save={handleTitleSave} />
            {poll.closed ? <StyledClosedModuleIcon /> : null}
          </ListItem>
          {poll.description
            ? (
              <ListItem>
                <WidgetTextField value={poll.description} style={{ underline: true, sizeFont: '1em' }} save={handleDescSave} />
              </ListItem>)
            : null}
          {formatedDate
            ? (
              <ListItem>
                {formatedDate}
              </ListItem>)
            : null}
          <PollOptionList
            type={moduleType}
            friendIds={friendIds}
            nbUsers={nbUsers}
            users={users}
            poll={poll}
            selectedItemCallback={(option : PollOption) => { setSelectedOptionId(option.id); setToggleOptionDrawer(true) }}
          />
          {enableUsersOptionProposal && toggleChoice
            ? <ListItemAddOption onAddOption={onAddOption} setToggleChoice={setToggleChoice} />
            : (usersOptionProposalAllowedIDs.includes(userId) && toggleChoice
              ? <ListItemAddOption onAddOption={onAddOption} />
              : null)}
          <LayoutPollChoices
            type={moduleType}
            userId={userId}
            friendIds={friendIds}
            onToggleChoice={onToggleChoice}
            users={users}
            poll={poll}
            selectedItemCallback={(option : PollOption) => { setSelectedOptionId(option.id); setToggleOptionDrawer(true) }}
          />
        </List>
        <WidgetSwipeableDrawerPollEdit
          users={users}
          poll={poll}
          moduleType={moduleType}
          onOpen={() => { setTogglePollEditDrawer(true) }}
          onClose={() => { setTogglePollEditDrawer(false) }}
          open={togglePollEditDrawer}
        />
        {selectedOptionId &&
          <WidgetSwipeableDrawerVote
            poll={poll}
            users={users}
            option={selectedOption}
            onOpen={() => setToggleOptionDrawer(true)}
            onClose={() => { setToggleOptionDrawer(false); setSelectedOptionId('') }}
            open={toggleOptionDrawer}
          />}
        <LayoutBottomIcons />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          message={message}
          key={'bottom' + ' ' + 'center'}
        />
      </div>
    </>
  )
}
