import React, { useEffect, ReactElement, memo } from 'react'
import Debug from 'debug'

const debug = Debug('Listeners:ListenerBodyScroll')
const debugRender = Debug('render:ListenerBodyScroll')
const debugMount = Debug('mount:ListenerBodyScroll')

export enum ScrollStatus {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

export interface ScrollPosition {
  status: ScrollStatus
  y: number
  height: number
}

export type OnMove = (scrollPos: ScrollPosition) => void

export interface ListenerWindowScrollProps {
  onMove: OnMove
  delay?: number
  offset?: number
}

// scroll callback called from debouncer, checks stauts and calls top callback accordingly
const scrollCallback = (onMove : OnMove, scrollY: number, offset: number) => {
  let status = ScrollStatus.MIDDLE

  if (document.body.scrollHeight + offset - window.innerHeight - scrollY <= 0) { status = ScrollStatus.BOTTOM }
  if (scrollY <= 0) { status = ScrollStatus.TOP }

  if (onMove != null) { onMove({ status, y: scrollY, height: document.body.scrollHeight + offset - window.screen.height }) }
  debug('on ', offset, document.body.scrollHeight, window.innerHeight, document.body.clientHeight, scrollY, document.body.scrollHeight + offset - window.innerHeight - scrollY, status)
}

// debouncer function that returns both the deboucer and a clear debouncer function
// check https://chrisboakes.com/how-a-javascript-debounce-function-works/
const debouncer = (callback: any, delay: number) => {
  // timeout closure variable
  let timeout : NodeJS.Timeout

  // the debouncer function, clears the timeout and will callback after the delay
  const debouncerFunc = (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback.apply(this, args), delay)
  }

  // the clear function simply clears the timeout, clearTimeout is null resilient
  const clearDebouncerFunc = () => { clearTimeout(timeout) }

  return ({ debouncerFunc, clearDebouncerFunc })
}

// we use a debouncer to avoid multiple rerenders when scrolling
const registerDebouncedListener = ({ onMove, delay = 500, offset = 0 } : ListenerWindowScrollProps) => {
  // get the debouncerFunc and clearFunc
  // the debouncer callback calls the scrollCallback when invoked with the current window scroll position
  const { debouncerFunc, clearDebouncerFunc } = debouncer(() => scrollCallback(onMove, window.scrollY, offset), delay)
  window.addEventListener('scroll', debouncerFunc)

  const clearFunc = () => {
    debug('clear')
    window.removeEventListener('scroll', debouncerFunc)
    clearDebouncerFunc()
  }

  // return the clear function to be called when the component is unmount
  return clearFunc
}

const ListenerWindowScroll = ({ onMove, delay, offset }: ListenerWindowScrollProps) : ReactElement => {
  useEffect(() => {
    debugMount('ListenerWindowScroll mount')
    const clearFunc = registerDebouncedListener({ onMove, delay, offset })
    return clearFunc
  })

  debugRender('ListenerWindowScroll render')
  return <></>
}

export default memo(ListenerWindowScroll)
