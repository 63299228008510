import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppThunk } from '../..'
import { UploadComplete, UploadProgressionCallback } from '../../components/Widgets/MediaLoader'

// MEDIAS ADD
const mediasSlice = createSlice({
  name: 'mediasControl',
  initialState: {
    isError: null,
    isSaving: false
  },
  reducers: {
    mediasAddPending (state) {
      return {
        ...state,
        isError: null,
        isSaving: true
      }
    },
    mediasAddFulfilled (state) {
      return {
        ...state,
        isError: null,
        isSaving: false
      }
    },
    mediasAddRejected (state, action: any) {
      // action.payload = { name: "NotFound", message: "No record found for id 'G6HJ45'",
      //   code:404, className: "not-found" }
      const error = action.payload
      return {
        ...state,
        isError: error,
        isSaving: false
      }
    },
    mediasAddReset (state) {
      return {
        ...state,
        isError: null,
        isSaving: false
      }
    }
  }
})

export const {
  mediasAddPending, mediasAddFulfilled, mediasAddRejected, mediasAddReset
} = mediasSlice.actions

export default mediasSlice.reducer

export const mediasAddThunk = (file: File, metadata: any, token: string, uploadProgressionCallback: UploadProgressionCallback, uploadComplete: UploadComplete) : AppThunk => async dispatch => {
  // on ne va pas pouvoir faire du multipart avec feathersjs il y a un bug pour l'instant: https://github.com/feathersjs/feathers/issues/1744
  // await dispatch(mediasServices.medias.create(data))

  const urlApiMedias = process.env.REACT_APP_API_MEDIAS || 'http://localhost:3070'
  const formData = new FormData()
  formData.append('uri', file as any) // FIXME
  formData.append('metadata', JSON.stringify(metadata))

  dispatch(mediasAddPending())
  try {
    const result = await axios.post(`${urlApiMedias}/medias`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      onUploadProgress: (v: any) => { uploadProgressionCallback(Math.floor((100 * v.loaded) / v.total)) }
    })
    // throw new Error('Test Error')
    console.log('upload success', result)
    const id = result.data._id
    dispatch(mediasAddFulfilled())
    uploadComplete(id, 'success')
  } catch (error) {
    console.log('upload failure', error)
    dispatch(mediasAddRejected({ name: 'UploadFailure', message: 'Upload failure', code: 0, className: 'upload-failure' }))
    uploadComplete(null, 'failure')
  }
}
