import i18next from 'i18next'
import { ModuleType, Poll } from '../interfaces/interfacesApp'

// returns a glimpse for a pool item
// creates a string with number of options and number of choices made so far
const pollGlimpse = (data: Poll) => {
  const optionCount = data.options.length
  const choiceCount = data.choices.length

  return `${i18next.t('glimpse.polls.option', { count: optionCount })} - ${i18next.t('glimpse.polls.choice', { count: choiceCount })}`
}

// returns a glimpse for list item
// creates a string with percentage of selected options / nb options, later renders a progression bar
export const listGlimpse = (data: Poll, loginId: string): string => {
  const nbOptions = data.options.length
  let nbSelectedOptions = 0
  let optionsSelectedId : string[] = []
  let userVotedOrNot = '0'
  for (let i = 0; i < data.choices.length; i++) {
    if (data.choices[i].userId === loginId) { userVotedOrNot = '1' }
    if (!optionsSelectedId.includes(data.choices[i].optionId)) {
      optionsSelectedId = [...optionsSelectedId, data.choices[i].optionId]
      nbSelectedOptions += 1
    }
  }
  return (nbSelectedOptions / nbOptions * 100).toString() + ' ' + userVotedOrNot
}

// default glimpse "router", routes to appropriate glimpse according to the module type
export default (type: ModuleType, data: Poll, loginId: string): string => {
  switch (type) {
    case ModuleType.POLL:
      return pollGlimpse(data)
    case ModuleType.LIST:
    case ModuleType.LIST_N:
      return listGlimpse(data, loginId)
    default :
      return pollGlimpse(data)
  }
}
