import { Avatar, makeStyles } from '@material-ui/core'
import React, { memo } from 'react'
import { User } from '../../interfaces/interfacesApp'
import getInitials from '../../lib/utils'

export interface AvatarUserProps {
  user: User,
  backgroundColor? : string,
  width?: string,
  height?: string,
  fontSize?: string,
  withBadge?: boolean
}

const AvatarUser = ({ user, backgroundColor = 'lightgray', width, height, fontSize } : AvatarUserProps): any => {
  return (
    user.avatar
      ? <Avatar style={{ width: width, height: height, fontSize: fontSize }} src={user.avatar} alt={getInitials(user.firstname + ' ' + user.lastname)} />
      : <Avatar style={{ backgroundColor: backgroundColor, width: width, height: height, fontSize: fontSize }}>{getInitials(user.firstname + ' ' + user.lastname)}</Avatar>
  )
}

export default memo(AvatarUser)
