import React from 'react'
import LayoutInvitationLink from '../../components/Layouts/LayoutInvitationLink'
import { Pod } from '../../interfaces/interfacesApp'
import { FeathersReduxifyModel } from '../../interfaces/interfacesFeathers'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'

export interface InvitationLinkProps {
  pod: Pod
}

const InvitationLink = ({ pod }: InvitationLinkProps) => {
  if (pod == null) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <LayoutInvitationLink pod={pod} />
  )
}

interface RootState {
  pods: any // TODO: FeathersReduxifyModel
}

const mapState = (state: RootState, ownProps: any) => {
  const id = ownProps?.match?.params?.id
  const pod = (id != null && state?.pods?.queryResult?.find != null)
    ? state.pods.queryResult.find((pod: Pod) => pod._id === id)
    : null

  return ({
    pod
  })
}

export default connect(mapState)(InvitationLink)
