import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Switch, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import i18n from '../../i18n'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { ModuleType, Poll, User } from '../../interfaces/interfacesApp'
import { pollEditThunk } from '../../features/Polls/pollsSlice'
import CloseIcon from '@material-ui/icons/Close'
import MaximizeIcon from '@material-ui/icons/Maximize'
import WidgetTextField from './WidgetTextField'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useModal } from 'mui-modal-provider'
import InformationDialog from '../ModalDialogs/InformationDialog'
import ConfirmationDialog from '../ModalDialogs/ConfirmationDialog'

export const useStyles = makeStyles((theme) => ({
  textfield: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  textfields: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    margin: '10px auto'
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.5rem'
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '16px'
  },
  subs: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  closeIcon: {
    marginRight: '10px'
  },
  iconSwip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '-20px'
  },
  disabledLayout: {
    opacity: theme.palette.action.disabledOpacity,
    pointerEvents: 'none'
  }
}))

export type onOpen = () => void
export type onClose = () => void

interface WidgetSwipeableDrawerPollEditProps {
  users : User[]
  open: boolean
  onOpen: onOpen
  onClose: onClose
  poll: Poll
  moduleType: ModuleType
}

const WidgetSwipeableDrawerPollEdit = ({ open, onOpen, onClose, poll, moduleType, users }: WidgetSwipeableDrawerPollEditProps) : ReactElement | null => {
  if (poll?.config == null) { return null } // Test input values

  const { t } = useTranslation('translation')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showModal } = useModal()

  // SaveDate Format.
  const [datePickerValue, setDatePickerValue] = useState(poll.endDate ? new Date(poll.endDate) : null)

  // Display CreationDate.
  const creationDateToFormat = new Date(poll.creationDate)
  const formatedDateCreation = !isNaN(Date.parse(creationDateToFormat as any)) ? Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric'
  }).format(creationDateToFormat) : undefined

  const handleDateSave = (newDate: Date | null) => {
    const oldTime = poll.endDate ? new Date(poll.endDate).getTime() : 0
    const newTime = newDate ? newDate.getTime() : 0
    if (poll?._id && (newTime !== oldTime)) {
      const partialPoll = newDate ? { endDate: newDate } : { $unset: { endDate: poll.endDate } } // Workaround: https://github.com/feathersjs/feathers/issues/2553
      dispatch(pollEditThunk(poll._id, partialPoll))
    }
  }

  const disableAnonymousPollSwitch = poll.config.enableAnonymousChoice && (poll.choices.length > 0)

  type PollSetting = {
    enableMultipleUserPerOption?: boolean
    enableMultipleChoices?: boolean
    enableUsersOptionProposal?: boolean
    enableAnonymousChoice?: boolean
  }
  const handleChoiceSave = (pollSetting : PollSetting) => {
    const pollEditThunkAction = () => {
      const config = { ...poll.config, ...pollSetting }
      if (poll?._id) { dispatch(pollEditThunk(poll._id, { config })) }
    }

    // Display a confirmation popup before poll anonymisation
    if ((pollSetting.enableAnonymousChoice === true) && (poll.choices.length > 0)) {
      const modal = showModal(ConfirmationDialog, {
        title: t('polls.anonymisePollConfirmation.title'),
        description: t('polls.anonymisePollConfirmation.description'),
        confirmButtonLabel: t('polls.anonymisePollConfirmation.confirmButton'),
        onConfirm: () => {
          pollEditThunkAction()
          modal.hide()
        },
        onCancel: () => {
          modal.hide()
        }
      })

    // Display a popup to explain why unanonymise is not possible
    } else if ((pollSetting.enableAnonymousChoice === false) && (poll.choices.length > 0)) {
      const modal = showModal(InformationDialog, {
        title: t('polls.pollDeanonymizationNotPossible.title'),
        description: t('polls.pollDeanonymizationNotPossible.description'),
        onClose: () => {
          modal.hide()
        }
      })

    // No popup needed
    } else {
      pollEditThunkAction()
    }
  }

  const handleTitleSave = (newPollTitle : string) => {
    if (poll?._id && (poll.title !== newPollTitle)) {
      dispatch(pollEditThunk(poll._id, { title: newPollTitle }))
    }
  }
  const handleDescSave = (newPollDesc : string) => {
    if (poll?._id && (poll.description !== newPollDesc)) {
      dispatch(pollEditThunk(poll._id, { description: newPollDesc }))
    }
  }

  return (
    <SwipeableDrawer
      style={{ zIndex: 1200 }}
      anchor='bottom'
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      PaperProps={{
        style: { borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }
      }}
    >
      <ListItemIcon className={classes.iconSwip}>
        <MaximizeIcon fontSize='large' style={{ color: 'gray' }} />
      </ListItemIcon>
      <ListItem>
        <IconButton
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon color='primary' />
        </IconButton>
        <Typography variant='h5'>{t('polls.pollMenuNavSettings')}</Typography>
      </ListItem>
      <div className={poll.closed ? classes.disabledLayout : ''}>
        <List>
          <ListItem>
            <WidgetTextField
              value={poll.title}
              save={handleTitleSave}
              label={t('polls.pollCreate.purpose')}
              style={{ variantProps: 'filled' }}
            />
          </ListItem>
          <ListItem>
            <MuiPickersUtilsProvider locale={i18n.language === 'en' ? en : fr} utils={DateFnsUtils}>
              <KeyboardDatePicker
                onChange={(date: MaterialUiPickersDate) => { setDatePickerValue(date) }}
                value={datePickerValue}
                autoOk
                format='dd/MM/yyyy'
                inputVariant='filled'
                onAccept={(date: MaterialUiPickersDate) => { handleDateSave(date) }}
                variant='inline'
                fullWidth
                label={t('polls.pollCreate.date')}
                onBlur={() => {
                  if ((datePickerValue === null) || !isNaN(Date.parse(datePickerValue as any))) { // Check 'Invalid Date'
                    handleDateSave(datePickerValue)
                  } else {
                    setDatePickerValue(poll.endDate ? new Date(poll.endDate) : null)
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </ListItem>
          <ListItem>
            <WidgetTextField
              value={poll.description}
              save={handleDescSave}
              label={t('polls.pollCreate.description')}
              style={{ variantProps: 'filled' }}
            />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Typography variant='h6'>{t('polls.pollCreate.parameters')}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary={t('polls.pollCreate.multipleSelection')} secondary={t('polls.pollCreate.multipleSelectionSub')} />
            <Switch
              color='primary'
              checked={poll.config.enableMultipleChoices}
              onChange={() => { handleChoiceSave({ enableMultipleChoices: !poll.config.enableMultipleChoices }) }}
            />
          </ListItem>
          {moduleType === ModuleType.POLL ? null : (
            <ListItem>
              <ListItemText primary={t('polls.pollCreate.oneVote')} secondary={t('polls.pollCreate.oneVoteSub')} />
              <Switch
                color='primary'
                checked={!poll.config.enableMultipleUserPerOption}
                onChange={() => { handleChoiceSave({ enableMultipleUserPerOption: !poll.config.enableMultipleUserPerOption }) }}
              />
            </ListItem>)}
          <ListItem>
            <ListItemText primary={t('polls.pollCreate.allVote')} secondary={t('polls.pollCreate.allVoteSub')} />
            <Switch
              color='primary'
              checked={poll.config.enableUsersOptionProposal}
              onChange={() => { handleChoiceSave({ enableUsersOptionProposal: !poll.config.enableUsersOptionProposal }) }}
            />
          </ListItem>
          <ListItem disabled={disableAnonymousPollSwitch}>
            <ListItemText primary={t('polls.pollCreate.anonymous')} secondary={t('polls.pollCreate.anonymousSub')} />
            <Switch
              color='primary'
              checked={poll.config.enableAnonymousChoice}
              onChange={() => handleChoiceSave({ enableAnonymousChoice: !poll.config.enableAnonymousChoice })}
            />
          </ListItem>
        </List>
        <Divider />
        <ListItem>
          {users.map((user, index) => {
            return (
              <Typography
                key={index}
              >{user._id === poll.creatorId ? t('polls.pollDrawer.creation', { name: user.firstname, date: formatedDateCreation }) : null}
              </Typography>
            )
          })}
        </ListItem>
      </div>
    </SwipeableDrawer>
  )
}

export default WidgetSwipeableDrawerPollEdit
