import { makeStyles } from '@material-ui/core'
import React, { ReactElement, useState, useContext } from 'react'
import { ProcessedMedia } from '../../interfaces/interfacesApp'
import MediaPreview from '../Widgets/MediaPreview'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import ImageLoader from './ImageLoader'
import { ScrollToBottomContext } from './Posts'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export interface MediaInPostProps {
  medias: ProcessedMedia[]
}

export const useStyles = makeStyles(() => ({
  picture: {
    width: '200px'
  },
  document: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'lightgrey'
  },
  docName: {
    blockOverflow: 'hidden',
    color: '#158D78',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: '#404040'
  },
  docInfos: {
    backgroundColor: 'lightgrey',
    fontSize: '10px',
    paddingLeft: '22px',
    color: 'black'
  },
  link: {
    underline: 'hover'
  }
}))

const MediaInPost = ({ medias } : MediaInPostProps): ReactElement => {
  if (medias.length === 0) return <></>
  const classes = useStyles()

  const [fullScreen, setFullScreen] = useState(false)
  const [pictureToShow, setPictureToShow] = useState(medias)
  const scrollToBottom = useContext(ScrollToBottomContext)

  if (fullScreen) return (<MediaPreview open onClose={() => { setFullScreen(false) }} medias={pictureToShow} />)

  return (
    <>
      {medias.map(media => {
        // File size in KB
        const newFileSize = (media.fileSize / 1000).toFixed(2)
        // Get extension from original name
        const splitString = media.originalName?.split('.')
        const newExtension = splitString?.length > 1 ? splitString[1].toUpperCase() : ''

        return (media.mimeType !== undefined && (media.mimeType as string).match(/image/) != null
          ? (
            <ImageLoader onLoaded={scrollToBottom} className={classes.picture} src={media.url} key={media.url} onClick={() => { setFullScreen(true); setPictureToShow([media]) }} />)
          : (
            <Link className={classes.link} href={media.url} key={media.url}>
              <Card className={classes.document}>
                <CardMedia className={classes.docName}>
                  <InsertDriveFileIcon className={classes.icon} />
                  <Typography variant='body2'>{media.originalName}</Typography>
                </CardMedia>
              </Card>
              <Typography className={classes.docInfos}> {newExtension} - {newFileSize} KB </Typography>
            </Link>
          )
        )
      })}
    </>
  )
}

export default MediaInPost
