import React, { ReactElement, useContext, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getServicesStatus } from './lib/feathers-redux'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { mediasServices, podsServices, pollsServices, postsServices, usersServices } from './App'
import { mediasAddReset } from './features/Medias/mediasSlice'
import { useModal } from 'mui-modal-provider'
import InformationDialog from '../src/components/ModalDialogs/InformationDialog'
import { LogoutContext } from './features/Login/ConnectionProvider'
import { loginCleanUp } from './features/Login/loginSlice'

// Global Redux loading & error state management
// for now edit pod & edit poll are the only tested use cases
const reduxServiceNames = ['login', 'authManagement', 'users', 'relationships', 'notifications', 'pods', 'podUser', 'polls', 'posts', 'mediasSlice', 'medias'] // services ordered by priority
// TODO: add more use cases
// const reduxServiceNames = ['tokenretriever']

const useStyles = makeStyles(() => ({
  progressContainer: {
    position: 'fixed',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface ErrMgmtBaseProps{
  reduxState : any
}
const ErrMgmtBase = ({ reduxState }: ErrMgmtBaseProps): ReactElement => {
  const servicesStatus = getServicesStatus(reduxState, reduxServiceNames)
  const statusIdle = (servicesStatus.message === '' && servicesStatus.className === '' && servicesStatus.serviceName === '')
  const statusPending = !statusIdle && (servicesStatus.className === 'isLoading' || servicesStatus.className === 'isSaving')
  const statusError = !statusIdle && !statusPending
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation')
  const { showModal } = useModal()
  const logoutContext = useContext(LogoutContext)

  const resetSliceError = () => {
    // Move following code in lib if needed more than once
    const reduxService = usersServices[servicesStatus.serviceName] ? usersServices
      : podsServices[servicesStatus.serviceName] ? podsServices
        : pollsServices[servicesStatus.serviceName] ? pollsServices
          : postsServices[servicesStatus.serviceName] ? postsServices
            : postsServices[servicesStatus.serviceName] ? mediasServices
              : null

    if (servicesStatus.serviceName === 'login') {
      dispatch(loginCleanUp())
    } else if (reduxService) {
      dispatch(reduxService[servicesStatus.serviceName].reset(true))
    } else if (servicesStatus.serviceName === 'mediasSlice') {
      dispatch(mediasAddReset())
    } else {
      console.log(`Error: Unhandled service ${servicesStatus.serviceName} reset error`)
    }
  }

  useEffect(() => {
    if (statusError) {
      // Login slice
      if (servicesStatus.serviceName === 'login') {
        if (servicesStatus.message.indexOf('jwt expired') !== -1) {
          const modal = showModal(InformationDialog, {
            title: t('systemError.tokenExpire.title'),
            description: t('systemError.tokenExpire.desc'),
            onClose: () => { resetSliceError(); modal.hide(); logoutContext() }
          })
        } else if (servicesStatus.message.indexOf('Invalid login') !== -1) {
          const modal = showModal(InformationDialog, {
            title: t('login.signinError.title'),
            description: t('login.signinError.desc'),
            onClose: () => { resetSliceError(); modal.hide() }
          })
        } else { // Including 'Timeout of 5000ms exceeded'
          const modal = showModal(InformationDialog, {
            title: t('systemError.title'),
            description: t('systemError.desc', { error: servicesStatus.message }),
            onClose: () => { resetSliceError(); modal.hide() }
          })
        }
      // users slice
      } else if (servicesStatus.message.indexOf('email already exist') !== -1) {
        const modal = showModal(InformationDialog, {
          title: t('login.signupError.title'),
          description: t('login.signupError.desc'),
          onClose: () => { resetSliceError(); modal.hide() }
        })
      // any reduxify slice
      } else if (servicesStatus.message.indexOf('Not authenticated') !== -1) {
        const modal = showModal(InformationDialog, {
          title: t('systemError.tokenExpire.title'),
          description: t('systemError.tokenExpire.desc'),
          onClose: () => { resetSliceError(); modal.hide(); logoutContext() }
        })
      // any slice
      } else {
        const modal = showModal(InformationDialog, {
          title: t('systemError.title'),
          description: t('systemError.desc', { error: servicesStatus.message }),
          onClose: () => { resetSliceError(); modal.hide() }
        })
      }
    }
  }, [statusError])

  return (
    <>
      {statusPending && (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}

// TODO: more generic implementation based on reduxServiceNames
interface RootState {
  users: { // no need for isFinished
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  /* tokenretriever: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  }, */
  relationships: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  notifications: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  authManagement: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  login: {
    error: any,
  },
  pods: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  podUser: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  polls: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  posts: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  },
  medias: {
    isLoading: boolean
    isSaving: boolean
    isError: any
  }
  mediasSlice: {
    isSaving: boolean
    isError: any
  },
}

const mapState = (state: RootState) => {
  const reduxState = { // no need for isFinished
    users: {
      isLoading: state.users.isLoading,
      isSaving: state.users.isSaving,
      isError: state.users.isError
    },
    /* tokenretriever: {
      isLoading: state.tokenretriever.isLoading,
      isSaving: state.tokenretriever.isSaving,
      isError: state.tokenretriever.isError
    }, */
    relationships: {
      isLoading: state.relationships.isLoading,
      isSaving: state.relationships.isSaving,
      isError: state.relationships.isError
    },
    notifications: {
      isLoading: state.notifications.isLoading,
      isSaving: state.notifications.isSaving,
      isError: state.notifications.isError
    },
    authManagement: {
      isLoading: state.authManagement.isLoading,
      isSaving: state.authManagement.isSaving,
      isError: state.authManagement.isError
    },
    login: {
      isError: { serviceName: 'login', message: state.login.error }
    },
    pods: {
      isLoading: state.pods.isLoading,
      isSaving: state.pods.isSaving,
      isError: state.pods.isError
    },
    podUser: {
      isLoading: state.podUser.isLoading,
      isSaving: state.podUser.isSaving,
      isError: state.podUser.isError
    },
    polls: {
      isLoading: state.polls.isLoading,
      isSaving: state.polls.isSaving,
      isError: state.polls.isError
    },
    posts: {
      isLoading: state.posts.isLoading,
      isSaving: state.posts.isSaving,
      isError: state.posts.isError
    },
    medias: {
      isLoading: state.medias.isLoading,
      isSaving: state.medias.isSaving,
      isError: state.medias.isError
    },
    mediasSlice: {
      isSaving: state.mediasSlice.isSaving,
      isError: { serviceName: 'mediasSlice', message: state.mediasSlice.isError?.message }
    }
  }
  return {
    reduxState
  }
}

const ErrMgmt = connect(
  mapState
)(ErrMgmtBase)

export default ErrMgmt
