import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import { App, rootReducer } from './App'
import { RootState } from './rootReducer'
import { configureStore, Action } from '@reduxjs/toolkit'
import thunk, { ThunkAction } from 'redux-thunk'
// import { applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import promise from 'redux-promise-middleware'
// import logger from 'redux-logger'
import 'fontsource-roboto'

// import { createEpicMiddleware } from 'redux-observable'

// const epicMiddleware = createEpicMiddleware()

export const store = configureStore({ reducer: rootReducer, middleware: [/* logger, */promise, thunk] })

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

// epicMiddleware.run(rootEpic)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
