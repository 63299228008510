import { useLocation } from 'react-router-dom'
import UAParser from 'ua-parser-js'
/* Full list of utils that can be used anywhere

*/

import { PollChoice, PollOption, Pod, Relationship, User, NotificationData, NotificationType } from '../interfaces/interfacesApp'
import { v4 as uuid } from 'uuid'
import makeDebug from 'debug'

const debug = makeDebug('libUtils:')

// ********** email REGEXP ************
export const emailRegex = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$'

export default function getInitials (name: string) : string {
  return name.split(' ').map((n) => n[0]).join('')
}

// Color table
// for user color in chat,user color is determined by position in usersConfirmed
// for pod Colors too a simle hash is used to find index in the list
const colors = ['#8C8E2A', '#B28C49', '#C14D3B', '#AF4775', '#8B4F9B', '#7D6BA5', '#6E9AB4', '#158D78',
  '#B5B834', '#F5BF5F', '#FF7863', '#FF63A8', '#CE66E8', '#9263FF', '#6381FF', '#00B294']

export function getColorFromID (id : string | undefined) : string {
  let colorIndex = 0
  if (id == null) { return colors[0] }
  for (let i = 0; i < id.length; i++) {
    colorIndex += id[i].charCodeAt(0)
  }
  return colors[colorIndex % colors.length]
}

export function sortUsersByAlphabeticalOrderId (usersIds: string[], users: User[]): string[] {
  const sortedUsers: string[] = usersIds.sort(
    (a: string, b: string) => {
      const firstnameA = users.find((user: User) => user._id === a)?.firstname || ''
      const firstnameB = users.find((user: User) => user._id === b)?.firstname || ''
      if (firstnameA > firstnameB) {
        return 1
      } else if (firstnameA < firstnameB) {
        return -1
      } else {
        const lastnameA = users.find((user: User) => user._id === a)?.lastname || ''
        const lastnameB = users.find((user: User) => user._id === b)?.lastname || ''
        if (lastnameA > lastnameB) {
          return 1
        } else {
          return -1
        }
      }
    }
  )
  return sortedUsers
}

export function sortUsersByAlphabeticalOrderUser (users: User[]): User[] {
  const sortedUsers: User[] = users.sort(
    (a: User, b: User) => {
      const firstnameA = a.firstname || ''
      const firstnameB = b.firstname || ''
      if (firstnameA > firstnameB) {
        return 1
      } else if (firstnameA < firstnameB) {
        return -1
      } else {
        const lastnameA = a.lastname || ''
        const lastnameB = b.lastname || ''
        if (lastnameA > lastnameB) {
          return 1
        } else {
          return -1
        }
      }
    }
  )
  return sortedUsers
}

// get firstnames and lastnames of users confirmed in the pod
export function getUsersConfirmedNames (pod: Pod, users: User[]) : string[] {
  const usersConfirmedIds = pod?.usersConfirmed != null ? pod.usersConfirmed : []
  let usersConfirmed : string[] = []
  for (let i = 0; i < usersConfirmedIds.length; i++) {
    const cur = users.find((u : User) => (u._id === usersConfirmedIds[i]))
    usersConfirmed = [...usersConfirmed, cur?.firstname + ' ' + cur?.lastname]
  }
  return usersConfirmed
}

// get users confirmed in the pod
export function getUsersConfirmed (pod: Pod, users: User[]) : User[] {
  let usersConfirmed : User[] = []
  const usersConfirmedIds = pod?.usersConfirmed != null ? pod.usersConfirmed : []
  for (let i = 0; i < usersConfirmedIds.length; i++) {
    const cur = users.find((u : User) => (u._id === usersConfirmedIds[i]))
    if (cur) {
      usersConfirmed = [...usersConfirmed, cur]
    }
  }
  return sortUsersByAlphabeticalOrderUser(usersConfirmed)
}

// get firstnames and lastnames of pending users in the pod
export function getUsersPendingNames (pod: Pod, users: User[]) : string[] {
  const usersPendingIds = pod?.usersPending != null ? pod.usersPending : []
  let usersPending : string[] = []
  for (let i = 0; i < usersPendingIds.length; i++) {
    const cur = users.find((u : User) => (u._id === usersPendingIds[i]))
    usersPending = [...usersPending, cur?.firstname + ' ' + cur?.lastname]
  }
  return usersPending
}

// get pending users in the pod
export function getUsersPending (pod: Pod, users: User[]) : User[] {
  const usersPendingIds = pod?.usersPending != null ? pod.usersPending : []
  let usersPending : User[] = []
  for (let i = 0; i < usersPendingIds.length; i++) {
    const cur = users.find((u : User) => (u._id === usersPendingIds[i]))
    if (cur) {
      usersPending = [...usersPending, cur]
    }
  }
  return sortUsersByAlphabeticalOrderUser(usersPending)
}

// get user's friends, needed in PageInvitationLink
export function getUserFriends (relationships: Relationship[], curUserId: string) : string[] {
  let userRelationships : string[] = []
  const temp1 = relationships?.filter((r: Relationship) => (r.requestedId === curUserId && r.status === 'Confirmed')) // get .requesterId
  const temp2 = relationships?.filter((r: Relationship) => (r.requesterId === curUserId && r.status === 'Confirmed')) // get .requestedId
  if (temp1 != null) {
    temp1.map((t: Relationship) => { userRelationships = [...userRelationships, t.requesterId] })
  }
  if (temp2 != null) {
    temp2.map((t: Relationship) => { userRelationships = [...userRelationships, t.requestedId] })
  }

  return userRelationships
}

// used in InvitationFriends
export function removeFriendsAlreadyInPod (userFriends: string[], usersConfirmedIds: string[], usersPendingIds: string[]): string[] {
  let friendsNotInPod: string[] = []
  userFriends.forEach(friend => {
    if ((!usersConfirmedIds.find((userId: string) => userId === friend)) && (!usersPendingIds.find((userId: string) => userId === friend))) {
      friendsNotInPod = [...friendsNotInPod, friend]
    }
  })
  return friendsNotInPod
}

// used in InvitationFriends
export function getFriendsAlreadyInPod (userFriends: string[], usersConfirmedIds: string[], usersPendingIds: string[]): string[] {
  let friendsAlreadyInPod: string[] = []
  userFriends.forEach(friend => {
    if ((usersConfirmedIds.find((userId: string) => userId === friend)) || (usersPendingIds.find((userId: string) => userId === friend))) {
      friendsAlreadyInPod = [...friendsAlreadyInPod, friend]
    }
  })
  return friendsAlreadyInPod
}

// Sort options
export const sortOptions = (options: PollOption[], choices: PollChoice[]) : PollOption[] => {
  return options.sort(
    (a: PollOption, b: PollOption) => {
      const voteA = choices.filter((choice : PollChoice) => choice.optionId === a.id).length
      const voteB = choices.filter((choice : PollChoice) => choice.optionId === b.id).length
      if (voteA > voteB) {
        return -1
      } else if (voteA === voteB) {
        if (a.creationDate < b.creationDate) return -1; else return 1
      } else {
        return 1
      }
    }
  )
}

// return the options that are checked
export const getChosenOptions = (options: PollOption[], choices: PollChoice[]): PollOption[] => {
  const ret: PollOption[] = []

  choices.forEach((choice: PollChoice) => {
    const optIndex = options.findIndex((option: PollOption) => option.id === choice.optionId)

    if ((optIndex !== -1) && (ret.findIndex((option: PollOption) => option.id === choice.optionId) === -1)) {
      ret.push(options[optIndex])
    }
  })

  return ret
}

// opposite of getChosenOptions()
export const excludeChosenOptions = (options: PollOption[], choices: PollChoice[]): PollOption[] => {
  const ret: PollOption[] = []

  options.forEach((option: PollOption) => {
    const optIndex = choices.findIndex((choice: PollChoice) => option.id === choice.optionId)

    if (optIndex === -1) {
      ret.push(option)
    }
  })

  return ret
}

// get number of times a user voted in a poll
export const getNumberOfVotes = (choices: PollChoice[], userId: string): number => {
  let count = 0
  choices.forEach((choice : PollChoice) => { if (choice.userId === userId) { count += 1 } })
  return count
}

// check if the user voted for this option
export const didUserVotedOption = (choices: PollChoice[], optionId: string, userId: string): boolean => {
  let hasVoted = false
  choices.forEach((choice : PollChoice) => {
    if (choice.optionId === optionId && choice.userId === userId) { hasVoted = true }
  })
  return hasVoted
}

// check if the option is checked at least one time
export const isOptionChecked = (choices: PollChoice[], optionId: string) : boolean => {
  let isChecked = false
  choices.forEach((choice: PollChoice) => {
    if (choice.optionId === optionId) { isChecked = true }
  })
  return isChecked
}

// check if user voted at least one time
export const didUserVoted = (choices: PollChoice[], userId: string) : boolean => {
  let hasVotedOnce = false
  choices.forEach((choice: PollChoice) => {
    if (choice.userId === userId) { hasVotedOnce = true }
  })
  return hasVotedOnce
}

// get nb votes
export const getVotes = (option: string, choices: PollChoice[]) : number => {
  return choices.filter((choice : PollChoice) => choice.optionId === option).length
}

// get users who voted for an option
export const getVotersByOption = (choices : PollChoice[], optionId : string, users : User[]) : User[] => {
  let voters : User[] = []
  choices = choices.filter((choice : PollChoice) => choice.optionId === optionId)
  choices = choices.sort((a: PollChoice, b: PollChoice) => (a.creationDate < b.creationDate) ? -1 : 1)
  choices.map((choice : PollChoice) => {
    for (let i = 0; i < users.length; i++) {
      if (choice.userId === users[i]._id) {
        voters = [...voters, users[i]]
        break
      }
    }
  })
  return voters
}

// Has the logged in user checked this option
export const isLoggedInUserChoice = (optionId: string, choices: PollChoice[], userId: string): boolean => {
  const myChoice = choices.find((choice: PollChoice) => choice.optionId === optionId && choice.userId === userId)
  return !!myChoice
}

// get number of minutes between two dates
export const getNumberOfMinutes = (date1: Date | null, date2: Date | null): number | undefined => {
  if (date1 !== null && date2 !== null) {
    let diff = date2.getTime() - date1.getTime()
    return (diff = Math.round(((diff % 86400000) % 3600000) / 60000))
  }
}

// get number of hours between two dates
export const getNumberOfHours = (date1: Date | null, date2: Date | null): number | undefined => {
  if (date1 !== null && date2 !== null) {
    let diff = date2.getTime() - date1.getTime()
    return (diff = Math.floor((diff % 86400000) / 3600000))
  }
}

// get number to display in MainBottomBar for notifications
export const numberOfUpdatedPods = (notif: NotificationData[] | undefined): number => {
  const numberToDisplay: string[] = []
  if (notif) {
    for (let i = 0; i < notif?.length; i++) {
      const podId = notif[i].parentIds[0]
      if (podId && numberToDisplay.indexOf(podId) === -1 && notif[i].type !== NotificationType.DELETE && notif[i].type !== NotificationType.CLOSE) {
        numberToDisplay.push(podId)
      }
    }
  }
  return numberToDisplay.length
}

// see 'https://css-tricks.com/how-to-use-the-web-share-api/' to know which browsers are supported
export function isShareAPISupported () : boolean {
  const parser = new UAParser()
  const result = parser.getResult()

  // Desktop detector on Mac. Desktop = (result.device.type === undefined)
  if (result.device.type === undefined && result.os.name === 'Mac OS' && result.browser.name === 'Chrome') {
    return false
  }
  if (result.device.type === undefined && result.os.name === 'Mac OS' && result.browser.name === 'Safari') {
    return true
  }

  if (result.device.type === 'mobile' || result.device.type === 'tablet') {
    if (result.browser.name === 'Safari' || result.browser.name === 'Mobile Safari' || result.browser.name === 'Chrome' || result.browser.name === 'Firefox' || result.browser.name === 'Edge') {
      return true
    }
  }
  return false
}

interface metaData {
  podTitle: string | undefined,
  podDesc: string | undefined,
  modTitle?: string | undefined,
  modDesc?: string | undefined,
  moduleId?: string | undefined,
  lang?: string | undefined
}

export function copyToClipboard (link: string, metaData?: metaData) : boolean {
  if (!navigator.clipboard) {
    return false
  }

  if (metaData) {
    const linkMetaData = link + '?' +
      (metaData.podTitle ? `podtitle=${metaData.podTitle}` : '') +
        (metaData.podTitle && metaData.podDesc ? `&poddesc=${metaData.podDesc}`
          : metaData.podDesc ? `poddesc=${metaData.podDesc}` : '') +
            (metaData.modTitle ? `&modtitle=${metaData.modTitle}` : '') +
              (metaData.modTitle && metaData.modDesc ? `&moddesc=${metaData.modDesc}`
                : metaData.modDesc ? `&moddesc=${metaData.modDesc}` : '') +
                  (metaData.lang ? `&lang=${metaData.lang}` : '')
    navigator.clipboard.writeText(linkMetaData)
  } else {
    navigator.clipboard.writeText(link)
  }
  return true
}

export function showShareAPI (link: string, metaData?: metaData) : void {
  if (navigator.share) {
    try {
      if (metaData?.podTitle) { // Issues on iOS with the title field.
        navigator.share({
          text: metaData.podTitle +
            (metaData.podDesc ? '\r\n' + metaData.podDesc : '') +
            (metaData.modTitle ? '\r\n' + metaData.modTitle : '') +
            (metaData.modDesc ? '\r\n' + metaData.modDesc : ''),
          url: link + '?' +
          (metaData.podTitle ? `podtitle=${metaData.podTitle}` : '') +
            (metaData.podTitle && metaData.podDesc ? `&poddesc=${metaData.podDesc}`
              : metaData.podDesc ? `poddesc=${metaData.podDesc}` : '') +
                (metaData.modTitle ? `&modtitle=${metaData.modTitle}` : '') +
                  (metaData.modTitle && metaData.modDesc ? `&moddesc=${metaData.modDesc}`
                    : metaData.modDesc ? `&moddesc=${metaData.modDesc}` : '') +
                      (metaData.lang ? `&lang=${metaData.lang}` : '')
        })
      } else {
        navigator.share({
          url: link
        })
      }
    } catch (error) {
      console.log('Fallback: copy to clipboard')
      copyToClipboard(link, ({ podTitle: metaData?.podTitle, podDesc: metaData?.podDesc, lang: metaData?.lang }))
    }
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.

export function useQuery () : URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const PUBLIC_EMAIL_PATTERN = '.public@symly.fr'
export const LOCAL_STORAGE_PUBLIC_EMAIL_KEY = 'email'
export const LOCAL_STORAGE_PUBLIC_NAME_KEY = 'nickname'

export const generateUniquePublicEmail = (): string => {
  const uemail = `${uuid()}${PUBLIC_EMAIL_PATTERN}`
  debug(`Generated unique public email: ${uemail}`)
  return uemail
}

export const isPublicEmail = (email:string): boolean => {
  const status = (email.length > 0) && (email.indexOf(PUBLIC_EMAIL_PATTERN) !== -1)
  debug(`${email} email is ${status ? '' : 'NOT '}PUBLIC`)
  return status
}

export const getPublicEmailFromLocalStorage = (): string | null => {
  const email = window.localStorage.getItem(LOCAL_STORAGE_PUBLIC_EMAIL_KEY)
  debug(`Get public email:${email} from localStorage key:${LOCAL_STORAGE_PUBLIC_EMAIL_KEY}`)
  return email
}

export const getPublicNameFromLocalStorage = (): string | null => {
  const name = window.localStorage.getItem(LOCAL_STORAGE_PUBLIC_NAME_KEY)
  debug(`Get public email:${name} from localStorage key:${LOCAL_STORAGE_PUBLIC_NAME_KEY}`)
  return name
}
