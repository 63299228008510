import React, { useState, ReactElement } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import Logo from '../../assets/logo_symbol.svg'
import FacebookLogo from '../../assets/facebookLogo.svg'
import GoogleLogo from '../../assets/googleLogo.svg'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { urlApiUsers } from '../../App'
import { emailRegex } from '../../lib/utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  logo: {
    margin: theme.spacing(3),
    backgroundColor: 'white'
  },
  connexion: {
    fontSize: '20px',
    marginTop: '1rem',
    fontWeight: 'bold'
  },
  symly: {
    fontSize: '24px',
    fontFamily: 'Futura PT'
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    marginBottom: 20,
    height: 56,
    textTransform: 'none',
    fontSize: '18px'
  },
  signupLink: {
    marginBottom: theme.spacing(4),
    width: '100%',
    textDecoration: 'none'
  },
  textfield: {
    backgroundColor: 'white',
    width: '100%'
  },
  divDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%'
  },
  divider: {
    flexGrow: 2
  },
  labelDivider: {
    color: '#707070',
    fontSize: '14px',
    fontFamily: 'Roboto',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },
  buttonFacebook: {
    margin: theme.spacing(3, 0, 2),
    marginTop: 20,
    height: 56,
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    textTransform: 'none'
  },
  buttonGoogle: {
    margin: theme.spacing(0, 0, 2),
    height: 56,
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    textTransform: 'none'
  },
  signupText: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '1rem',
    color: 'black'
  },
  password: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
    marginTop: '1rem',
    color: 'black',
    marginBottom: '1rem'
  }
}))

export interface SignInProps {
  pacerText: string | null
  onLogin?: any
}

export default function LayoutLogin (props : SignInProps): ReactElement {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation('translation')

  const emailError = ((email !== '') && !((new RegExp(emailRegex)).test(email)))

  // useEffect(() => bottomBarContext.hide(true))
  const googleAuth = `${urlApiUsers}/oauth/google`
  const facebookAuth = `${urlApiUsers}/oauth/facebook`

  if (props.pacerText != null) {
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <img src={Logo} className={classes.logo} />
          <Typography>
            {props.pacerText}
          </Typography>
          <CircularProgress />
        </div>
      </Container>
    )
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Logo} className={classes.logo} />
        <Typography className={classes.connexion} component='h1' variant='h5'>
          {t('login.signinTitle')}
        </Typography>
        <Button fullWidth variant='contained' startIcon={<img src={FacebookLogo} />} className={classes.buttonFacebook} href={facebookAuth}>{t('login.facebook')}</Button>
        <Button fullWidth variant='contained' startIcon={<img src={GoogleLogo} />} className={classes.buttonGoogle} href={googleAuth}>{t('login.google')}</Button>
        <div className={classes.divDivider}>
          <Divider className={classes.divider} />
          <span className={classes.labelDivider}>{t('login.divider')}</span>
          <Divider className={classes.divider} />
        </div>

        <TextField className={classes.textfield} onChange={e => setEmail(e.target.value)} type='email' error={emailError} {...(emailError && { helperText: t('login.emailError') })} variant='outlined' margin='normal' fullWidth id='email' label={t('login.email')} name='email' autoComplete='email' autoFocus />
        <FormControl className={classes.form} variant='outlined'>
          <InputLabel htmlFor='outlined-adornment-password'>{t('login.password')}</InputLabel>
          <OutlinedInput
            id='outlined-adornment-password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(!showPassword)}
                  edge='end'
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            label={t('login.password') + 1}
          />
        </FormControl>

        <Button color='primary' disabled={email === '' || emailError || password === ''} onClick={() => props.onLogin({ email: email.toLowerCase(), password })} type='submit' fullWidth variant='contained' className={classes.submit}> {t('login.signin')} </Button>
        <Link to='/resetpassword' className={classes.password}>{t('login.passwordForgotten')}</Link>
        <Link to='/signup' className={classes.signupLink}>
          <div className={classes.signupText}>
            <span>{t('login.noAccount')}</span>
            <span style={{ textDecoration: 'underline', marginLeft: '0.5rem' }}>{t('login.signup')}</span>
          </div>
        </Link>
      </div>
    </Container>
  )
}
