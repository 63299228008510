import { memo, ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { podAddUserThunk, podInvite, podInviteSuccess } from '../../features/Pods/podsSlice'
import { AppState } from '../../interfaces/AppState'
import makeDebug from 'debug'
import { Pod } from '../../interfaces/interfacesApp'

const debug = makeDebug('ListenerPodInvite:')

const ListenerPodInvite = (): ReactElement | null => {
  const userId = useSelector((state:AppState) => state.login.user?._id)
  const userOnboarding = useSelector((state:AppState) => state.login.user?.onboarding)

  const inviteStatus = useSelector((state:AppState) => state.podsSlice.inviteStatus)
  const podInviteId = useSelector((state:AppState) => state.podsSlice.podInviteId)
  const invitePage = useSelector((state:AppState) => state.podsSlice.invitePage)

  const [updateTriggered, setUpdateTriggered] = useState(false)
  const podUserUpdatePending = useSelector((state:AppState) => state.podUser.updatePending)
  const podUserUpdateError = useSelector((state: AppState) => state.podUser.isError)
  const podUserUpdated = useSelector((state:AppState) => (userId && Array.isArray(state.pods.queryResult) && (state.pods.queryResult.filter((pod :Pod) => pod.usersPending.includes(userId)).length > 0)))

  const history = useHistory()
  const dispatch = useDispatch()

  debug(`Render podId:${podInviteId}, userId:${userId}, inviteStatus:${inviteStatus}, updateTriggered:${updateTriggered}, podUserUpdatePending:${podUserUpdatePending}, podUserUpdated:${podUserUpdated}, podUserUpdateError:${podUserUpdateError}, userOnboarding:${userOnboarding}`)

  if (inviteStatus !== 'invited') {
    debug(`Not invited in redux, inviteStatus:${inviteStatus}`)
    const storedPodInviteId = window.localStorage.getItem('podInviteId')
    const storedInvitePage = window.localStorage.getItem('invitePage')
    if (storedPodInviteId) {
      debug(`Refill redux podInviteId:${storedPodInviteId} invitePage:${storedInvitePage}`)
      dispatch(podInvite({ podInviteId: storedPodInviteId, invitePage: storedInvitePage }))
    }
  } else {
    if (!updateTriggered && userId && podInviteId) {
      debug(`User invited, podAddUser to pod:${podInviteId}`)
      dispatch(podAddUserThunk({}, userId, podInviteId, userOnboarding))
      setUpdateTriggered(true)
    } else if (updateTriggered && !podUserUpdatePending) {
      if (podUserUpdateError) {
        debug('PodAddUser Error: redirect to pods')
        history.replace('/pods/')
      } else if (!podUserUpdated) {
        debug(`Wait for podUserUpdated:${podUserUpdated}`)
      } else {
        debug('Cleanup state & localStorage')
        dispatch(podInviteSuccess())
        setUpdateTriggered(false)
        window.localStorage.removeItem('podInviteId')
        window.localStorage.removeItem('invitePage')
        if (invitePage && (invitePage.length > 0)) {
          debug(`Redirect to /pod/${podInviteId}/${invitePage}`)
          history.replace(`/pod/${podInviteId}/${invitePage}`)
        } else {
          debug(`Redirect to pod:${podInviteId}, `)
          history.replace(`/pod/${podInviteId}`)
        }
      }
    }
  }

  return null
}

export default memo(ListenerPodInvite)
