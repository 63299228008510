import React, { memo, ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'

import AttachIcon from '@material-ui/icons/AttachFile'
import { styled } from '@material-ui/core/styles'
import MediaSwipeableSelector, { OnMediaSwipeableSelectorHandleSelection, MediaSwipeableSelection } from './MediaSwipeableSelector'
import MediaPreview, { OnMediaPreviewHandleClose } from './MediaPreview'
import { mediasAddThunk } from '../../features/Medias/mediasSlice'
import Debug from 'debug'
import { IconButton } from '@material-ui/core'

const debug = Debug('widgets:MediaLoader')

export enum MediaLoaderAction {
  none,
  upload,
  detach
}

export enum MediaLoaderState {
  none,
  upload,
  Left,
  Right,
}

const MediaAttachIcon = styled(AttachIcon)({
  position: 'relative',
  fontSize: '30'
})

export type OnMediaUploaded = (id : string) => void
export type OnMediaAttached = () => void

export interface MediaLoaderProps {
  action: MediaLoaderAction,
  onMediaUploaded: any,
  onMediaAttached: any,
  onMediaDetached: any,
  podId: string,
  token: string
}

export type UploadProgressionCallback = (percents: number) => void
export type UploadComplete = (id: string | null, status: string) => void

const MediaLoader = (props: MediaLoaderProps) : ReactElement => {
  const [dataUrl, setDataUrl] = useState('')
  const [, setUploadProgression] = useState(0)
  const [file, setFile] = useState<File|null>(null)
  const [status, setStatus] = useState('idle')
  const [swipeableOpen, setSwipeableOpen] = useState(false)
  const dispatch = useDispatch()

  const { onMediaUploaded, action, onMediaAttached, onMediaDetached } = props as MediaLoaderProps

  const upload = (file: File | null, metadata: any, token: string, uploadProgressionCallback: UploadProgressionCallback, uploadComplete: UploadComplete) => {
    if (file == null) {
      debug('no file to upload')
      uploadComplete(null, 'no file')
      return
    }

    const reader = new FileReader()

    debug('upload start', file)

    reader.readAsBinaryString(file as any) // FIXME

    reader.onload = async () => {
      dispatch(mediasAddThunk(file, metadata, token, uploadProgressionCallback, uploadComplete))
    }
  }

  type VoidVoidCallback = () => void
  type StringVoidCallback = (data: string) => void

  const statusStateMachine = (status: string,
    action: MediaLoaderAction,
    dataUrl: string,
    uploadFunction: VoidVoidCallback,
    setStatus: StringVoidCallback,
    resetMedia: VoidVoidCallback,
    setDataUrl: StringVoidCallback) => {
    switch (status) {
      case 'attached':
        switch (action) {
          case MediaLoaderAction.upload:
            uploadFunction()
            break
          case MediaLoaderAction.detach:
            setDataUrl('')
            window.URL.revokeObjectURL(dataUrl)
            resetMedia()
            setStatus('idle')
            break
          case MediaLoaderAction.none: // FIXME we should avoid rerenders
            break
          default:
            debug(`OUPS ${action} in ${status} don't know waht to do...`)
            break
        }
        break
      case 'uploading':
        switch (action) {
          case MediaLoaderAction.upload: // FIXME we should avoid rerenders
          case MediaLoaderAction.none: // FIXME we should avoid rerenders
            break
            break
          default:
            debug(`OUPS ${action} in ${status} don't know waht to do...`)
            break
        }
        break
      case 'idle':
        switch (action) {
          case MediaLoaderAction.upload:
            uploadFunction()
            break
          case MediaLoaderAction.detach:
          case MediaLoaderAction.none: // FIXME we should avoid rerenders
            break
          default:
            debug(`OUPS ${action} in ${status} don't know waht to do...`)
            break
        }
        break
      default:
        if (action !== MediaLoaderAction.none) {
          debug(`OUPS ${action} in ${status} don't know waht to do...`)
        }
        break
    }
  }

  const fileReadyCallback = (url : string, file: any) => {
    setDataUrl(url)
    setFile(file)
    setStatus('attached')
    onMediaAttached()
  }

  const fileUploaded = (id: string | null) => {
    if (id == null) {
      return
    }

    window.URL.revokeObjectURL(dataUrl)
    setDataUrl('')
    debug('upload done')

    onMediaUploaded(id)
    setFile(null)
    setStatus('idle')
  }

  const doUpload = () => upload(
    file,
    { trackPackIds: [props.podId] },
    props.token,
    p => {
      setStatus('uploading')
      setUploadProgression(p)
    },
    fileUploaded)

  statusStateMachine(status,
    action,
    dataUrl,
    doUpload,
    (status: string) => setStatus(status),
    () => setFile(null),
    (dataUrl: string) => setDataUrl(dataUrl)
  )
  let fileref : any = null

  const swipeableSelection : OnMediaSwipeableSelectorHandleSelection = (selection : MediaSwipeableSelection) => {
    setSwipeableOpen(false)
    if (fileref !== null) {
      fileref.value = null // dirty trick to reset file when reselecting it
      switch (selection) {
        case MediaSwipeableSelection.IMAGE:
          fileref.setAttribute('accept', 'image/*')
          fileref.click()
          break
        case MediaSwipeableSelection.VIDEO:
          fileref.setAttribute('accept', 'video/*')
          fileref.click()
          break
        case MediaSwipeableSelection.AUDIO:
          fileref.setAttribute('accept', 'audio/*')
          fileref.click()
          break
        case MediaSwipeableSelection.DOCUMENT:
          fileref.removeAttribute('accept')
          fileref.click()
      }
    }
  }

  const cancelImageUpload: OnMediaPreviewHandleClose = () => {
    setDataUrl('')
    if (fileref !== null) {
      fileref.value = null // dirty to reset file when reselecting it
    }
    onMediaDetached()
  }

  const attach = (evt : any, urlToRevoke: any, fileReady: any) => {
    debug('attach start', evt, urlToRevoke)
    const files = evt.currentTarget.files

    if (files == null || files.length !== 1) { debug('no file found'); return }

    const url = window.URL.createObjectURL(files[0])

    // replace the revokable url
    if (urlToRevoke !== '') {
      window.URL.revokeObjectURL(urlToRevoke)
    }

    fileReady(url, files[0])
    debug('attach done', url)
  }

  return (
    <>
      {(dataUrl !== '')
        ? (
          <MediaPreview
            open
            onClose={cancelImageUpload}
            medias={[{ url: dataUrl, mimeType: file?.type || 'none', fileSize: file?.size || 0, originalName: file?.name || 'none' }]}
          />
        )
        : null}

      <input
        multiple={false}
        type='file'
        hidden ref={r => { fileref = r }}
        onChange={(evt: any) => attach(evt, dataUrl, fileReadyCallback)}
      />
      <IconButton onClick={() => setSwipeableOpen(true)}>
        <MediaAttachIcon
          color='primary'
        />
      </IconButton>
      <MediaSwipeableSelector
        open={swipeableOpen}
        onSelection={swipeableSelection}
      />
    </>
  )
}

export default memo(MediaLoader)
