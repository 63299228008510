import { Button, Container, CssBaseline, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Logo from '../../assets/logo_symbol.svg'
import SubjectIcon from '@material-ui/icons/Subject'
import { useQuery } from '../../lib/utils'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import { ParamTypes } from '../Pages/PagePodInvite'
import { useTranslation } from 'react-i18next'
import { podInvite } from '../../features/Pods/podsSlice'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(3),
    backgroundColor: 'white'
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    marginBottom: 20,
    height: 56,
    textTransform: 'none',
    fontSize: '18px'
  },
  icon: {
    fontSize: '28px',
    color: 'grey',
    marginRight: '10px'
  }
})
)

const LayoutInviteAccess = () : ReactElement => {
  const classes = useStyles()
  const { t, i18n } = useTranslation('translation')
  const { podid, page, moduleid } = useParams<ParamTypes>()

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const query = useQuery()
  useEffect(() => {
    if (query.get('lang') === 'fr') {
      i18n.changeLanguage('fr')
    } else {
      i18n.changeLanguage('en-US')
    }
  }, [])
  const dispatch = useDispatch()

  const handleClick = () => {
    const invitePage = (page === undefined) ? null : (page === 'module') ? `poll/${moduleid}` : page

    // Store invite info in local storage
    if (podid) {
      window.localStorage.setItem('podInviteId', podid)
    }
    if (invitePage) {
      window.localStorage.setItem('invitePage', invitePage)
    }

    // Store invite info in redux
    dispatch(podInvite({ podInviteId: podid, invitePage }))
  }

  return (
    <>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <img src={Logo} className={classes.logo} />
        <ListItem>
          <ListItemText>
            <Typography style={{ fontWeight: 'bold' }} variant='h5'>{query.get('podtitle')}</Typography>
          </ListItemText>
        </ListItem>
        {query.get('poddesc')
          ? (
            <ListItem>
              <SubjectIcon className={classes.icon} />
              <ListItemText>
                <Typography variant='body1'>{query.get('poddesc')}</Typography>
              </ListItemText>
            </ListItem>
          )
          : null}
        {query.get('modtitle')
          ? (
            <ListItem>
              <PollOutlinedIcon className={classes.icon} />
              <ListItemText>
                <Typography style={{ fontWeight: 'bold' }} variant='h6'>{query.get('modtitle')}</Typography>
              </ListItemText>
            </ListItem>
          )
          : null}
        {query.get('moddesc')
          ? (
            <ListItem>
              <SubjectIcon className={classes.icon} />
              <ListItemText>
                <Typography variant='body1'>{query.get('moddesc')}</Typography>
              </ListItemText>
            </ListItem>
          )
          : null}
        <Button onClick={handleClick} color='primary' fullWidth variant='contained' className={classes.submit}>{page === 'module' ? t('invite.pollInvite') : t('invite.podInvite')}</Button>
      </Container>
    </>
  )
}
export default LayoutInviteAccess
