import AvatarGroup from '@material-ui/lab/AvatarGroup'
import React, { ReactElement } from 'react'
import { User } from '../../interfaces/interfacesApp'
import { getColorFromID } from '../../lib/utils'
import AvatarUser from './AvatarUser'
import { StyledBadge } from '../Styled/StyledBadge'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  size: {
    alignItems: 'center',
    '& .MuiAvatarGroup-avatar': {
      width: '24px',
      height: '24px',
      fontSize: '11px'
    }
  }
})
)
export interface AvatarGroupUserProps {
  users: User[],
  currentUserId : string,
  friendsIDs : string[],
}

const AvatarGroupUser = ({ users } : AvatarGroupUserProps): any => {
  const classes = useStyles()
  return (
    <AvatarGroup className={classes.size} max={3} spacing={4}>
      {users.map((user : User) => {
        return (
          <StyledBadge invisible={!(true && user.isOnline)} style={{ borderStyle: 'hidden' }} key={user._id} overlap='circle' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant='dot'>
            <AvatarUser width='24px' height='24px' fontSize='11px' user={user} backgroundColor={getColorFromID(user._id)} />
          </StyledBadge>
        )
      })}
    </AvatarGroup>
  )
}

export default AvatarGroupUser
