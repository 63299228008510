import { TextField } from '@material-ui/core'
import React, { memo, ReactElement, useState } from 'react'
import makeDebug from 'debug'

const debug = makeDebug('WidgetTextField')

type OnSave = (newOptionText: string) => void

type StyleProps = {
  numberOfRows?: number
  multiline?: boolean
  underline?: boolean
  sizeFont?: string
  readOnly?: boolean
  variantProps?: any
  textLineThrough?: boolean
  disabledProps?: boolean
  fontWeight?: boolean
}
export interface WidgetTextFieldProps {
  label?: string
  placeHolder?: string
  value?: string | undefined
  save: OnSave
  style: StyleProps
}

const WidgetTextField = ({ label, value, save, placeHolder, style }: WidgetTextFieldProps) : ReactElement => {
  const strValue = value || ''
  const strVariant = style.variantProps || 'standard'
  const [focusedValue, setFocusedValue] = useState(strValue)
  const [isFocused, setIsFocused] = useState(false)

  debug('RENDER')
  return (
    <TextField
      placeholder={placeHolder}
      multiline={style.multiline}
      rowsMax={style.numberOfRows}
      fullWidth
      label={label}
      disabled={style.disabledProps}
      onFocus={() => { setIsFocused(true); setFocusedValue(strValue) }}
      value={isFocused ? focusedValue : strValue}
      onChange={e => setFocusedValue(e.target.value)}
      onBlur={() => { setIsFocused(false); (focusedValue !== value) && save(focusedValue) }}
      InputProps={{
        readOnly: style.readOnly,
        disableUnderline: style.underline,
        style: {
          fontSize: style.sizeFont,
          textUnderlineOffset: 0,
          textDecoration: style.textLineThrough ? 'line-through' : 'inherit',
          fontWeight: style.fontWeight ? 'bold' : 'inherit'
        }
      }}
      variant={strVariant}
    />
  )
}

export default memo(WidgetTextField)
