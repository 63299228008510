import React, { memo, ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Badge from '@material-ui/core/Badge'
import { getVotersByOption, getNumberOfVotes, didUserVotedOption, isLoggedInUserChoice } from '../../../lib/utils'
import { PollOption, User, ModuleType, Poll } from '../../../interfaces/interfacesApp'
import AvatarGroupUser from '../../Widgets/AvatarGroupUser'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../interfaces/AppState'
import { pollToggleChoiceThunk } from '../../../features/Polls/pollsSlice'

const useStyles = makeStyles(() => ({
  roundedCorner: {
    borderRadius: '25px',
    background: '#F2F2F2',
    textAlign: 'center',
    position: 'relative',
    width: '30px',
    height: '20px'
  },
  roundedCornerChecked: {
    borderRadius: '25px',
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    width: '30px',
    height: '20px'
  },
  centerText: {
    position: 'relative',
    bottom: 2
  },
  checkedOption: {
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none'
  },
  customBadge: {
    backgroundColor: 'lightGray',
    color: 'white'
  },
  clickable: {
    pointerEvents: 'auto' // remains clickable even if disabled
  }
}))

interface OptionItemProps {
  type: ModuleType,
  friendIds: string[]
  users : User[],
  poll : Poll,
  option: PollOption,
  selectedItemCallback: (option : PollOption) => void
}

const OptionItem = ({ type, friendIds, users, poll, option, selectedItemCallback } : OptionItemProps): ReactElement => {
  const userId = useSelector((appState: AppState) => appState?.login?.user?._id || '')
  const classes = useStyles()
  const choices = poll.choices
  const enableAnonymousChoice = poll.config ? poll.config?.enableAnonymousChoice : false
  const enableMultipleChoices = poll.config ? poll.config?.enableMultipleChoices : true
  const voters = getVotersByOption(choices, option.id, users)
  const checked = (type === ModuleType.POLL) ? isLoggedInUserChoice(option.id, choices, userId) : false
  const dispatch = useDispatch()
  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation() // Block DOM event.
    dispatch(pollToggleChoiceThunk(poll, option.id, userId))
  }
  const listItemTextPrimaryClass = checked ? { primary: classes.checkedOption } : { }
  const listItemTextClass = { root: classes.clickable, ...listItemTextPrimaryClass }

  return (
    <ListItem divider key={option.id} button onClick={() => selectedItemCallback(option)}>
      <ListItemIcon>
        {enableMultipleChoices || ((getNumberOfVotes(choices, userId) > 0) ? didUserVotedOption(choices, option.id, userId) : true)
          ? (
            <Checkbox
              color='primary'
              edge='start'
              checked={checked}
              tabIndex={-1}
              disableRipple
              onClick={handleClick}
            />)
          : null}
      </ListItemIcon>
      <ListItemText primary={option.text} classes={listItemTextClass} />
      {(voters.length !== 0 && type === ModuleType.POLL)
        ? (
          <div className={classes.clickable}>
            {enableAnonymousChoice
              ? (voters.length > 0 ? <Badge badgeContent={voters.length} classes={{ badge: classes.customBadge }} /> : null)
              : <AvatarGroupUser users={voters} currentUserId={userId} friendsIDs={friendIds} />}
          </div>
        )
        : null}
    </ListItem>
  )
}

export default memo(OptionItem)
