import React, { ReactElement } from 'react'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

type ConfirmationDialogProps = DialogProps & {
  title: string
  description: string
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCloseButtonLabel?: string
}

const ConfirmationDialog = ({ title, description, onClose, onCloseButtonLabel, ...props }: ConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation('translation')
  return (
    <Dialog {...props} TransitionComponent={Transition} keepMounted aria-labelledby='alert-dialog-slide-title' aria-describedby='alert-dialog-slide-description'>
      <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {onCloseButtonLabel || t('popup.alert.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
