import React, { ReactElement } from 'react'
import PagePodEdit from '../../components/Pages/PagePodOptions'
import { podLeaveUserThunk, PodLeaveUserThunk, podTrackTarget, PodTrackTarget } from './podsSlice'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Pod, Relationship, User } from '../../interfaces/interfacesApp'
import { getUserFriends } from '../../lib/utils'
import { useTranslation } from 'react-i18next'
import { useModal } from 'mui-modal-provider'
import ConfirmationDialog from '../../components/ModalDialogs/ConfirmationDialog'

const mapDispatch = { podLeaveUserThunk, podTrackTarget }

export interface PodEditProps {
  pod: Pod
  users: User[]
  podLeaveUserThunk: PodLeaveUserThunk
  email: string
  curUserId: string
  curFriendIds: string[]
  podTrackTarget: PodTrackTarget // Action to update the target pod id to track
  trackPodIdTarget: string // Curren tvalue of the pod id to track
}

const PodEdit = (props: PodEditProps): ReactElement | null => {
  const history = useHistory()
  const { t } = useTranslation('translation')
  const { showModal } = useModal()

  const handleConfirmationDialog = () => {
    const modal = showModal(ConfirmationDialog, {
      title: t('pods.podEdit.leavePodConfirmationPopup.title'),
      description: t('pods.podEdit.leavePodConfirmationPopup.description'),
      confirmButtonLabel: t('pods.podEdit.leavePodConfirmationPopup.confirmButton'),
      onConfirm: () => {
        props.podLeaveUserThunk(props.pod, props.curUserId)
        history.replace('/pods')
        modal.hide()
      },
      onCancel: () => {
        modal.hide()
      }
    })
  }

  if (props.pod == null) { return null }

  // update pod id to track only if necessary
  if (props.pod?._id != null && props.pod?._id !== props.trackPodIdTarget) { props.podTrackTarget(props.pod._id) }

  return (
    <PagePodEdit
      users={props.users}
      curUserId={props.curUserId}
      pod={props.pod}
      onLeave={handleConfirmationDialog}
    />
  )
}

interface RootState {
  polls: any // TODO: FeathersReduxifyModel
  pods: any // TODO: FeathersReduxifyModel
  users: any // TODO: FeathersReduxifyModel
  login : {
    email: string,
    user : User
  }
  relationships: any // TODO: FeathersReduxifyModel
  podsSlice : {
    trackPodIdTarget: string
  }
}

const mapState = (state: RootState, ownProps: any) => {
  const id = ownProps?.match?.params?.id
  const pod = (id != null && state?.pods?.queryResult?.find != null)
    ? state.pods.queryResult.find((pod: Pod) => pod._id === id)
    : null
  const curUserId = state.login?.user?._id
  const rootRelationships: Relationship[] = state?.relationships?.queryResult
  const curFriendIds: string[] = (Array.isArray(rootRelationships) && curUserId) ? getUserFriends(rootRelationships, curUserId) : []

  return ({
    email: state.login.email,
    pod,
    curUserId,
    curFriendIds,
    users: state.users?.queryResult,
    trackPodIdTarget: state.podsSlice.trackPodIdTarget
  })
}

export default connect(mapState, mapDispatch)(PodEdit)
