import { pollsServices } from '../../App'
import { AppThunk } from '../../index'
import { Poll, PollOption, ModuleType, PollConfigParams, PollOptionOperationParams, PollOptionOperation } from '../../interfaces/interfacesApp'
import { FeathersError } from '@feathersjs/errors'

export type PollCreateThunk = (data: Partial<Poll>, podId: string, type: ModuleType, title?: string, description?: string, endDate?: Date, config?: PollConfigParams) => void

export const pollCreateThunk = (data: Partial<Poll>, podId: string, type: ModuleType, creationDate: Date, title?: string, description?: string, endDate?: Date, config?: PollConfigParams): AppThunk => async dispatch => {
  try {
    data.title = title
    data.description = description
    data.endDate = endDate
    data.podId = podId
    data.options = []
    data.choices = []
    data.type = type
    data.config = config
    data.creationDate = creationDate

    await dispatch(pollsServices.polls.create(data))
  } catch (e) {
    console.log('error', e)
  }
}

export const pollEditThunk = (id: string, data: Partial<Poll>) : AppThunk => async dispatch => {
  try {
    await dispatch(pollsServices.polls.patch(id, data))
    // TODO: dispatch an action for custom success feedback
  } catch (e) {
    const errorMessage = (e instanceof FeathersError) ? e.message : e as string
    console.log('Cannot patch poll because of error %s', errorMessage)
    // TODO: on a specific error, dispatch an action to display a custom feedback
    /* if (e.message === 'specific error') {
      dispatch(specificErrorFeedback())
      dispatch(usersServices.relationships.reset(true))
    } */
  }
}

export type PollAddOptionThunk = (poll :Poll, option: string) => void

export const pollAddOptionThunk = (poll :Poll, option: string): AppThunk => async dispatch => {
  const optionOperationParams: PollOptionOperationParams = {
    operation: PollOptionOperation.CREATE,
    optionText: option
  }
  const updatePoll: Partial<Poll> = { optionOperationParams }

  try {
    await dispatch(pollsServices.polls.patch(poll._id, updatePoll))
  } catch (e) {
    console.log('error', e)
  }
}

export type PollToggleChoiceThunk = (poll: Poll, optionId: string, userId: string) => void

export const pollToggleChoiceThunk = (poll: Poll, optionId: string, userId: string) : AppThunk => async dispatch => {
  const optionOperationParams: PollOptionOperationParams = {
    operation: PollOptionOperation.TOGGLE_CHOICE,
    optionId
  }
  const updatePoll: Partial<Poll> = { optionOperationParams }

  try {
    await dispatch(pollsServices.polls.patch(poll._id, updatePoll))
  } catch (e) {
    console.log('error', e)
  }
}

export type PollDeleteOptionThunk = (poll: Poll, optionId: string) => void

export const pollDeleteOptionThunk = (poll :Poll, optionId: string): AppThunk => async dispatch => {
  const optionOperationParams: PollOptionOperationParams = {
    operation: PollOptionOperation.DELETE,
    optionId: optionId
  }
  const updatePoll: Partial<Poll> = { optionOperationParams }

  try {
    await dispatch(pollsServices.polls.patch(poll._id, updatePoll))
  } catch (e) {
    console.log('error', e)
  }
}

export const pollUpdateOptionThunk = (poll: Poll, changedOption: PollOption): AppThunk => async dispatch => {
  const optionOperationParams: PollOptionOperationParams = {
    operation: PollOptionOperation.UPDATE,
    optionId: changedOption.id,
    optionText: changedOption.text
  }
  const updatePoll: Partial<Poll> = { optionOperationParams }

  try {
    await dispatch(pollsServices.polls.patch(poll._id, updatePoll))
  } catch (e) {
    console.log('error', e)
  }
}

export type PollDeleteThunk = (poll: Poll) => void

export const pollDeleteThunk = (poll: Poll): AppThunk => async dispatch => {
  try {
    await dispatch(pollsServices.polls.remove(poll._id))
  } catch (e) {
    console.log('error', e)
  }
}
