import React from 'react'

import { sortOptions, excludeChosenOptions } from '../../../lib/utils'
import { PollOption, User, ModuleType, Poll } from '../../../interfaces/interfacesApp'
import PollOptionItem from './PollOptionItem'

interface OptionListProps {
    type: ModuleType,
    friendIds: string[]
    nbUsers: number,
    users : User[],
    poll : Poll,
    selectedItemCallback: (option : PollOption) => void
}

export const OptionList = ({ type, friendIds, users, poll, selectedItemCallback } : OptionListProps): any => {
  const options = poll.options
  const choices = poll.choices

  let sortedOption = []

  if (type === ModuleType.POLL) {
    sortedOption = sortOptions(options, choices)
  } else {
    sortedOption = excludeChosenOptions(options, choices)
  }

  return sortedOption.map((option: PollOption) => {
    return (
      <PollOptionItem
        type={type}
        friendIds={friendIds}
        users={users}
        poll={poll}
        option={option}
        key={option.id}
        selectedItemCallback={selectedItemCallback}
      />
    )
  })
}

export default OptionList
