import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import { Post } from '../../interfaces/interfacesApp'
import Linkify from 'react-linkify'
import MediaInPost from './MediaInPost'
import { IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'

export const useStyles = makeStyles(() => ({
  postGroup: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    borderRadius: '12px',
    backgroundColor: '#F2F2F2',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word' // overflowWrap: 'anywhere' not yet supported on safari
  },
  postList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  loggedInUserPostGroup: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    borderRadius: '12px',
    backgroundColor: 'rgba(0, 124, 137, 0.2)',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word' // overflowWrap: 'anywhere' not yet supported on safari
  },
  loggedInUserPostList: {
    paddingTop: '0px',
    paddingBottom: '0px',
    justifyContent: 'flex-end'
  },
  deletedMessage: {
    color: 'grey'
  },
  icon: {
    color: 'lightgrey'
  }
}))

interface LayoutPostGroupProps {
    post : Post
    messagesEndRef : any
    isLoggedInUserPost: boolean
    postToDelete: (post: Post) => void
  }

export default function LayoutPostGroup ({ post, messagesEndRef, isLoggedInUserPost, postToDelete } : LayoutPostGroupProps) : ReactElement {
  const classes = useStyles()
  const listClass = isLoggedInUserPost ? classes.loggedInUserPostList : classes.postList
  const boxClass = isLoggedInUserPost ? classes.loggedInUserPostGroup : classes.postGroup
  const { t } = useTranslation()

  const message = post.message
  const deletedMessage = t('chat.deletePost.postDeleted')
  const media = post.medias

  return (
    <ListItem className={listClass}>
      {(isLoggedInUserPost && message) || (isLoggedInUserPost && !message && media)
        ? (
          <IconButton size='small' aria-label='delete' color='inherit' onClick={() => postToDelete(post)}>
            <DeleteIcon className={classes.icon} fontSize='small' />
          </IconButton>
        ) : null}
      <Box className={boxClass}>
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target='_blank' rel='noreferrer' href={decoratedHref} key={key}>
            {decoratedText}
          </a>)}
        >
          <Typography className={message || classes.deletedMessage}>
            {media ? message : message || deletedMessage}
          </Typography>
          {media != null
            ? (
              <MediaInPost medias={post.processedMedias || []} />
            )
            : null}
        </Linkify>
        <div ref={messagesEndRef} />
      </Box>
    </ListItem>
  )
}
