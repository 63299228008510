import React, { memo, useState, useEffect, ReactElement } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export interface ImageLoadeProps {
  src: string // url of the image to load
  className?: string // classname for image
  onClick?: () => void // onclick handler
  onLoaded?: () => void // onLoaded callback
}
const ImageLoader = ({ src, className, onClick, onLoaded } : ImageLoadeProps) : ReactElement => {
  const [loading, setLoading] = useState(true)
  const [currentSrc, updateSrc] = useState('')

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image()
    imageToLoad.src = src
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false)
      updateSrc(src)
      if (onLoaded != null) { onLoaded() }
    }
  }, [src])

  return (
    loading
      ? (<CircularProgress />)
      : <img onClick={() => { if (onClick != null) { onClick() } }} className={className} src={currentSrc} />
  )
}

export default memo(ImageLoader)
