import React, { useState, ReactElement } from 'react'
import NavBar from '../Menus/NavBar'
import { useTranslation } from 'react-i18next'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { MainBottomBarSelectedItemType } from '../Menus/MainBottomBar'
import PodList from './Pod/PodList'
import { NotificationData, NotificationObjectType, NotificationType, Pod, User } from '../../interfaces/interfacesApp'
import LayoutPodCreate from './LayoutPodCreate'
import WidgetCreateFabButton from '../Widgets/WidgetCreateFabButton'
import { isPublicEmail } from '../../lib/utils'
import { useSelector } from 'react-redux'
import { AppState } from '../../interfaces/AppState'

export interface LayoutPodsProps {
  onCreatePod: any
  pods: Pod[]
  user: User
  notifyUserRequest: boolean
  onboarding: number
  onResendVerifyToken: any
}

export default function LayoutPods ({ onCreatePod, pods, user, notifyUserRequest, onboarding, onResendVerifyToken } : LayoutPodsProps): ReactElement {
  const [redirect, setRedirect] = useState(onboarding === 1)
  const { t } = useTranslation('translation')
  const { trackPageView } = useMatomo()
  const notifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) => !(
      (notification.type === NotificationType.DELETE) || // do not display delete notif
      (notification.type === NotificationType.CLOSE) || // do not display delete notif
      ((notification.type === NotificationType.UPDATE) && (notification.objectType === NotificationObjectType.POST)) // do not display post update notif
    ))
  )

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Pods list'
    })
  }, [])

  if (redirect) {
    return (
      <LayoutPodCreate
        setRedirect={setRedirect}
        onCreatePod={onCreatePod}
        onboarding={onboarding}
      />
    )// (<Redirect to={`/podcreate`} />)
  }

  return (
    <>
      <NavBar
        title={t('pods.groups')}
        onResendVerifyToken={onResendVerifyToken}
        mainBottomBar={MainBottomBarSelectedItemType.PODS}
        isPublicAccount={isPublicEmail(user.email || '')}
        notifications={notifications}
      />
      <PodList
        pods={pods}
        user={user}
        notifications={notifications || []}
      />
      <WidgetCreateFabButton onAdd={() => setRedirect(true)} type='poll' />
    </>
  )
}
