import React, { useState, ReactElement, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import Switch from '@material-ui/core/Switch'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ModuleType, Pod, PollConfigParams } from '../../interfaces/interfacesApp'

import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../interfaces/AppState'
import { pollCreateThunk } from '../../features/Polls/pollsSlice'
import { useHistory, useParams } from 'react-router-dom'
import makeDebug from 'debug'
import i18n from '../../i18n'
import NavBar from '../Menus/NavBar'
import { getServicesStatus } from '../../lib/feathers-redux'
import { useQuery } from '../../lib/utils'

export const useStyles = makeStyles(() => ({
  textfield: {
    marginTop: '1.5rem'
  },
  textfields: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: 'auto'
  },
  params: {
    fontWeight: 'bold'
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    margin: 'auto',
    width: '90%'
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.5rem'
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '16px'
  },
  subs: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.5)'
  }
}))

export type OnCreatePoll = (title: string, moduleType: ModuleType, description?: string, endDate?: Date, config?: PollConfigParams) => void

interface ParamTypes {
  id: string
  type: string
}

const debug = makeDebug('PageModuleCreate:')

const PageModuleCreate = (): ReactElement|null => {
  const { id, type } = useParams<ParamTypes>()
  debug(`params id:${id} type:${type}`)

  // Check moduletype
  const moduleType = (type === ModuleType.POLL || type === ModuleType.LIST) ? type : ModuleType.POLL

  const pod: Pod | undefined = useSelector((state: AppState) => state.pods.queryResult?.find((pod: Pod) => pod._id === id))
  const loggedInUserId: string | undefined = useSelector((state: AppState) => state.login?.user?._id)

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [date, setDate] = useState(null)
  const [desc, setDesc] = useState('')
  const [openDatePicker, setOpenDatePicker] = useState(false)

  const [multipleChoices, setMultipleChoices] = useState(true)
  const [multipleUserPerOption, setMultipleUserPerOption] = useState(false)
  const [usersOptionProposal, setUsersOptionProposal] = useState(true)
  const [anonymousChoice, setAnonymousChoice] = useState(false)

  const [createTriggered, setCreateTriggered] = useState(false)
  const createPending = useSelector((state: AppState) => state.polls.createPending)
  const data = useSelector((state: AppState) => state.polls.data)
  const isError = useSelector((state: AppState) => state.polls.isError)
  const dispatch = useDispatch()

  const classes = useStyles()
  const { t } = useTranslation('translation')

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    debug('Mount')
    trackPageView({
      documentTitle: 'Viewing poll creation'
    })
    return () => debug('Unmount')
  }, [])

  debug(`Rendered loggedInUserId=${loggedInUserId}, podId=${pod?._id}, createTriggered:${createTriggered}, createPending:${createPending}`)

  if (!loggedInUserId || !pod) {
    // use case N°1: user is not logged in
    // use case N°2: boot is not over (state.pods.queryResult === null)
    // use cane N°3: id is unknown (id not found in state.pods.queryResult)
    return null
  }

  const endDate = date || undefined
  const creationDate = new Date()
  const config : PollConfigParams = {
    enableMultipleChoices: multipleChoices,
    enableMultipleUserPerOption: multipleUserPerOption,
    enableUsersOptionProposal: usersOptionProposal,
    enableAnonymousChoice: anonymousChoice,
    usersOptionProposalAllowedIDs: [loggedInUserId],
    maxMultipleChoicePerUser: multipleChoices ? -1 : 1 // -1 represents the infinite
  }

  const handleCreatePollClick = () => {
    if (!createTriggered) {
      setCreateTriggered(true)
      dispatch(pollCreateThunk({}, pod._id, moduleType, creationDate, title, desc, endDate, config)) // will set isError to null
      debug('Creating poll')
    }
  }

  const backPage = useQuery().get('back')

  if (createTriggered && !createPending) {
    setCreateTriggered(false)
    if (isError) {
      const pollsStatus = getServicesStatus({ polls: { isError } }, 'polls')
      debug(`Poll not created because of error: ${pollsStatus.message}`)
      // TO DISPLAY A CUSTOM ERROR MESSAGE based on a specific error
      // 1- Check error
      // 2- Display an understandable error popup
      // 3- Reset Poll error to clear data & isError (will prevent system error popup)
      // dispatch(pollsServices.polls.reset(true)) // CAUTION: do not clear queryResult
    } else if (data?._id) {
      const to = backPage ? `/pod/${pod._id}/poll/${data._id}?back=${backPage}` : `/pod/${pod._id}/poll/${data._id}`
      debug(`Poll created, redirect to poll page: ${to}`)
      history.replace(to)
      return null
    } else {
      debug('SHOULD NEVER HAPPEN')
    }
  }

  return (
    <>
      <NavBar
        back
        title={moduleType === ModuleType.POLL ? t('navbar.title.poll') : t('navbar.title.list')}
        actionButtonTitle={t('pods.podCreate.button')}
        isActionButtonDisabled={title === ''}
        onActionButton={handleCreatePollClick}
      />
      <div className={classes.textfields}>
        <TextField
          fullWidth onChange={(text: any) => setTitle(text.target.value)}
          id='filled-basic' label={t('polls.pollCreate.purpose')} value={title} variant='filled' className={classes.textfield}
          autoFocus
        />
        <MuiPickersUtilsProvider locale={i18n.language === 'en' ? en : fr} utils={DateFnsUtils}>
          <KeyboardDatePicker
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            fullWidth
            autoOk
            variant='inline'
            format='dd/MM/yyyy'
            label={t('pods.podCreate.defaultDate')}
            onChange={(date: any) => setDate(date)}
            value={date}
            inputVariant='filled'
            className={classes.textfield}
            InputProps={{ readOnly: true, onClick: () => setOpenDatePicker(true) }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          fullWidth onChange={(text: any) => setDesc(text.target.value)}
          className={classes.textfield} label={t('polls.pollCreate.description')} value={desc} variant='filled'
        />
      </div>
      <div className={classes.options}>
        <span className={classes.params}>{t('polls.pollCreate.parameters')}</span>
        <div className={classes.option}>
          <div className={classes.texts}>
            <span className={classes.text}>{t('polls.pollCreate.multipleSelection')}</span>
            <span className={classes.subs}>{t('polls.pollCreate.multipleSelectionSub')}</span>
          </div>
          <Switch color='primary' checked={multipleChoices} onChange={() => setMultipleChoices(!multipleChoices)} />
        </div>
        {moduleType === ModuleType.POLL ? null : (
          <div className={classes.option}>
            <div className={classes.texts}>
              <span className={classes.text}>{t('polls.pollCreate.oneVote')}</span>
              <span className={classes.subs}>{t('polls.pollCreate.oneVoteSub')}</span>
            </div>
            <Switch color='primary' checked={!multipleUserPerOption} onChange={() => setMultipleUserPerOption(!multipleUserPerOption)} />
          </div>)}
        <div className={classes.option}>
          <div className={classes.texts}>
            <span className={classes.text}>{t('polls.pollCreate.allVote')}</span>
            <span className={classes.subs}>{t('polls.pollCreate.allVoteSub')}</span>
          </div>
          <Switch color='primary' checked={usersOptionProposal} onChange={() => setUsersOptionProposal(!usersOptionProposal)} />
        </div>
        <div className={classes.option}>
          <div className={classes.texts}>
            <span className={classes.text}>{t('polls.pollCreate.anonymous')}</span>
            <span className={classes.subs}>{t('polls.pollCreate.anonymousSub')}</span>
          </div>
          <Switch color='primary' checked={anonymousChoice} onChange={() => setAnonymousChoice(!anonymousChoice)} />
        </div>
      </div>
    </>
  )
}

export default memo(PageModuleCreate)
