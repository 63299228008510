import React, { ReactElement, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { urlApp } from '../../App'
import NavBar from '../Menus/NavBar'
import { Pod, Relationship, User } from '../../interfaces/interfacesApp'
import ListItem from '@material-ui/core/ListItem'
import { copyToClipboard, getFriendsAlreadyInPod, getUserFriends, isShareAPISupported, removeFriendsAlreadyInPod, showShareAPI, sortUsersByAlphabeticalOrderId } from '../../lib/utils'
import ListItemUser from '../Widgets/ListItemUser'
import FeedbackDialog, { showFeedbackDialog } from '../Popup/FeedbackDialog'
import { Button, ListItemText, Snackbar } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import { useHistory } from 'react-router-dom'

const ShareButton = withStyles({
  root: {
    textTransform: 'none', fontSize: '12'
  }
})(Button)

export const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
    fontSize: '16px',
    fontFamily: 'Roboto',
    overflow: 'hidden'
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: 'darkgrey'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  friends: {
    paddingLeft: '10px',
    paddingTop: '5px',
    color: 'grey'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

export interface PersonProps {
  user: User
  index: number
  setToAdd: any
  toAdd: string[]
}

function changeAddList (toAdd: string[], checked: boolean, setToAdd: any, user: User): void {
  if (!checked) {
    // add user to list of people to add in pod
    setToAdd([...toAdd, user._id])
  } else {
    // remove user from list of people to add in pod
    let newToAdd : string[] = []
    for (let i = 0; i < toAdd.length; i++) {
      if (toAdd[i] !== user._id) {
        newToAdd = [...newToAdd, toAdd[i]]
      }
    }
    setToAdd(newToAdd)
  }
}

const Person = ({ user, index, setToAdd, toAdd } : PersonProps) : any => {
  const [checked, setChecked] = useState(false)
  return (
    <div onClick={() => { setChecked(!checked); changeAddList(toAdd, checked, setToAdd, user) }}>
      {checked
        ? (
          <ListItemUser
            user={user}
            index={index}
            circleChecked='checked'
          />
        )
        : (
          <ListItemUser
            user={user}
            index={index}
            circleChecked='unchecked'
          />
        )}
    </div>
  )
}

export interface FriendsProps {
  users: User[]
  friendsNotInPod: string[]
  setToAdd: any
  toAdd: string[]
}

const Friends = ({ friendsNotInPod, users, setToAdd, toAdd }: FriendsProps): any => {
  return friendsNotInPod.map((friend: string, index: number) => {
    const user = users.find((u: User) => u._id === friend)
    return (
      (user != null) ? <Person key={index} user={user} index={index} setToAdd={setToAdd} toAdd={toAdd} /> : null)
  })
}

export interface FriendsAlreadyInPodProps {
  users: User[]
  friendsAlreadyInPod: string[]
}

const FriendsAlreadyInPod = ({ friendsAlreadyInPod, users }: FriendsAlreadyInPodProps): any => {
  return friendsAlreadyInPod.map((friend: string, index: number) => {
    const user = users.find((u: User) => u._id === friend)
    return (
      (user != null) ? <ListItemUser key={uuidv4()} index={index} user={user} circleChecked='checked' /> : null)
  })
}

export interface LayoutInvitationFriendsProps {
  pod: Pod
  users: User[]
  relationships: Relationship[]
  curUserId: string
  onUpdateAddFriendToPod: any
}

const LayoutInvitationFriends = ({ pod, users, relationships, curUserId, onUpdateAddFriendToPod } : LayoutInvitationFriendsProps): any => {
  const classes = useStyles()
  const [toAdd, setToAdd] = useState([])
  const [openFeedback, setOpenFeedback] = useState(false)
  const [messageFeedback, setMessageFeedback] = useState('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const { t } = useTranslation('translation')
  const podId = pod._id
  const link = `${urlApp}/podinvite/${podId}`
  const usersConfirmedIds = pod.usersConfirmed
  const usersPendingIds = pod.usersPending
  const userFriends = getUserFriends(relationships, curUserId)
  const user = users.find((u: User) => u._id === curUserId)
  const history = useHistory()

  function ShareLinkButton () : ReactElement {
    return (
      <ShareButton onClick={() => navigator.share !== undefined
        ? showShareAPI(link, ({ podTitle: pod?.title, podDesc: pod?.description, lang: user?.locale }))
        : (copyToClipboard(link, { podTitle: pod?.title, podDesc: pod?.description, lang: user?.locale })
          ? showFeedbackDialog(1500, t('invite.copy_success'), setMessage, setOpen) : null)}
      >
        <ShareIcon style={{ paddingRight: '16px' }} />
        <ListItemText primary={t('pods.podEdit.shareLinkButton')} />
      </ShareButton>
    )
  }
  return (
    <>
      <NavBar
        title={t('pods.podEdit.navBarTitle')}
        back
        actionButtonTitle={t('pods.invite.add')}
        onActionButton={() => {
          // for each friend in toAdd[], call the thunk to add a user to the pod, then display a pop up
          if (toAdd.length !== 0) {
            onUpdateAddFriendToPod({}, toAdd, podId)
          }
          if (toAdd.length === 1) {
            showFeedbackDialog(1000, t('addfriend.onefriend'), setMessageFeedback, setOpenFeedback)
          } else if (toAdd.length > 0) {
            showFeedbackDialog(1000, t('addfriend.multiplefriends'), setMessageFeedback, setOpenFeedback)
          }
          history.goBack()
        }}
      />

      <ListItem divider>
        <ShareLinkButton />
      </ListItem>
      <ListItemText className={classes.friends}>{t('pods.podEdit.friends')}</ListItemText>
      <Friends
        friendsNotInPod={sortUsersByAlphabeticalOrderId(removeFriendsAlreadyInPod(userFriends, usersConfirmedIds, usersPendingIds), users)}
        users={users}
        setToAdd={setToAdd}
        toAdd={toAdd}
      />
      <FriendsAlreadyInPod
        friendsAlreadyInPod={sortUsersByAlphabeticalOrderId(getFriendsAlreadyInPod(userFriends, usersConfirmedIds, usersPendingIds), users)}
        users={users}
      />
      <FeedbackDialog open={openFeedback} success description={messageFeedback} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        message={message}
        key={'bottom' + ' ' + 'center'}
      />
    </>
  )
}

export default LayoutInvitationFriends
