import React, { ReactElement } from 'react'
import LayoutInvites from '../../components/Layouts/LayoutInvites'
import { connect } from 'react-redux'
import { User } from '../../interfaces/interfacesApp'

export interface InvitesProps {
  pods: any
  userId: string
}

const Invites = (props: InvitesProps): ReactElement => {
  const pods = props.pods.queryResult.data || props.pods.queryResult

  return (
    <div>
      <LayoutInvites
        pods={pods.filter((e: any) =>
          ((e.usersPending.findIndex((e: any) => e === props.userId) !== -1) ||
          (e.usersMaybe.findIndex((e: any) => e === props.userId) !== -1)))}
      />
    </div>
  )
}

interface RootState {
  pods: any
  login : {
    user: User
  }
}

const mapState = (state: RootState) => ({
  pods: state.pods,
  userId: state.login?.user?._id
})

export default connect(
  mapState
)(Invites)
