import React, { memo, ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import { Redirect } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Poll, Pod, ModuleType } from '../../interfaces/interfacesApp'
import { useTranslation } from 'react-i18next'
import WidgetDisplayInfo from '../Widgets/WidgetDisplayInfo'
import EmptyModuleIcon from '../../assets/054---task-work-flow-.svg'
import WidgetSwipeableDrawer from '../Widgets/WidgetSwipeableDrawer'
import { Container } from '@material-ui/core'
import { ModuleList } from './Modules/ModuleList'
import WidgetCreateFabButton from '../Widgets/WidgetCreateFabButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  screenSize: {
    position: 'relative',
    maxWidth: '100vw',
    padding: '0px',
    height: 'calc(100vh - 124px)'
  },
  paddingBottom: {
    paddingBottom: '60px'
  }
}))

export interface LayoutPodModulesProps {
  pod: Pod
  polls: Poll[]
  loggedInUserId: string,
  onAdd?: any,
  onFabAdd?: any
}

const LayoutPodModules = ({ pod, polls, loggedInUserId }: LayoutPodModulesProps): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const [poll, setPoll] = useState(false)
  const [list, setList] = useState(false)
  const [listN, setListN] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false)

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: `Viewing pod "${pod.title}"`
    })
  }, [])
  type Anchor = 'bottom'

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setOpenDrawer(false)
  }
  const handlePoll = () => {
    setPoll(true)
  }

  const handleList = () => {
    setList(true)
  }
  if (poll || list || listN) {
    let setFunction
    let moduleType
    if (poll) {
      setFunction = setPoll
      moduleType = ModuleType.POLL
    } else if (list) {
      setFunction = setList
      moduleType = ModuleType.LIST
    } else /* if (list_N) */{
      setFunction = setListN
      moduleType = ModuleType.LIST_N
    }
    return (<Redirect push to={`/pod/${pod._id}/modulecreate/${moduleType}?back=modules`} />)
  }
  return (
    <>
      <Container className={classes.screenSize}>
        <div className={classes.paddingBottom}>
          <List component='nav' className={classes.root}>
            {(polls.length !== 0)
              ? <ModuleList podId={pod._id} users={pod.usersConfirmed} polls={polls} loggedInUserId={loggedInUserId} />
              : <WidgetDisplayInfo title={t('emptyModule.title')} desc1={t('emptyModule.desc1')} image={EmptyModuleIcon} />}
          </List>
          <WidgetSwipeableDrawer
            open={openDrawer}
            onClose={toggleDrawer('bottom', false)}
            onOpen={toggleDrawer('bottom', true)}
            handleList={handleList}
            handlePoll={handlePoll}
            toggleDrawer={toggleDrawer}
          />
        </div>
        <WidgetCreateFabButton onAdd={() => setOpenDrawer(true)} type='module' />
      </Container>
    </>
  )
}

export default memo(LayoutPodModules)
