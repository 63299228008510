import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../../index'

import { usersClient, usersServices } from '../../App'
import { FeathersClient, FeathersReduxifyModel } from '../../interfaces/interfacesFeathers'

export type State = {
  feedback: string,
  feedbackMessage: string
}

const relationshipsSlice = createSlice({
  name: 'relationshipsControl',
  initialState: { feedback: 'idle', feedbackMessage: '' },
  reducers: {
    relationshipFeedbackSuccess (state) {
      return {
        ...state,
        feedback: 'success'
      }
    },
    relationshipFeedbackError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        feedback: 'error',
        feedbackMessage: errorMessage
      }
    },
    relationshipFeedbackReset (state) {
      return {
        ...state,
        feedback: 'idle',
        feedbackMessage: ''
      }
    },
    relationshipCreateRequest (state, action: PayloadAction<any>) {
      return {
        ...state,
        create: 'creating'
      }
    },
    relationshipCreateSuccess (state, action: PayloadAction<any>) {
      return {
        ...state,
        create: 'done'
      }
    },
    relationshipCreateError (state, action: PayloadAction<any>) {
      const errorMessage = action.payload
      return {
        ...state,
        create: 'error',
        createError: errorMessage
      }
    },
    relationshipRemoveRequest (state) {
      return {
        ...state,
        remove: 'removing'
      }
    },
    relationshipRemoveSuccess (state) {
      return {
        ...state,
        remove: 'done'
      }
    },
    relationshipRemoveError (state, action: PayloadAction<any>) {
      return {
        ...state,
        remove: 'error',
        removeError: action.payload
      }
    }
  }
}
)

export const {
  relationshipFeedbackSuccess, relationshipFeedbackError, relationshipFeedbackReset,
  relationshipCreateError, relationshipCreateRequest, relationshipCreateSuccess,
  relationshipRemoveRequest, relationshipRemoveSuccess, relationshipRemoveError
} = relationshipsSlice.actions

export const relationshipCreateThunk = (data: any, requesterId: string, requestedId: string): AppThunk => async dispatch => {
  try {
    data.requestedId = requestedId
    data.requesterId = requesterId
    await dispatch(usersServices.relationships.create(data))
    dispatch(relationshipFeedbackSuccess())
  } catch (e) {
    console.log(e.message)
    // TODO: on a specific error, dispatch an action to display a custom feedback
    /* if (e.message === 'specific error') {
      dispatch(specificErrorFeedback())
      dispatch(usersServices.relationships.reset(true))
    } */
  }
}

export const relationshipRemoveThunk = (relationshipId: string): AppThunk => async dispatch => {
  try {
    await dispatch(usersServices.relationships.remove(relationshipId))
    dispatch(relationshipFeedbackSuccess())
  } catch (e) {
    console.log(e.message)
    // TODO: on a specific error, dispatch an action to display a custom feedback
    /* if (e.message === 'specific error') {
      dispatch(specificErrorFeedback())
      dispatch(usersServices.relationships.reset(true))
    } */
  }
}

export default relationshipsSlice.reducer
