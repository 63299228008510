import React, { memo } from 'react'
import { Badge, ListItem, makeStyles } from '@material-ui/core'
import { NotificationData, NotificationObjectType, NotificationType, Poll } from '../../../interfaces/interfacesApp'
import { listGlimpse } from '../../../lib/glimpse'
import { useSelector } from 'react-redux'
import { AppState } from '../../../interfaces/AppState'
import { StyledClosedModuleIcon, StyledListIcon } from '../../Styled/StyledIcons'
import DoneIcon from '@material-ui/icons/Done'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { WidgetModuleProgressStatus } from '../../Widgets/WidgetModuleProgressStatus'

const useStyles = makeStyles(() => ({
  postList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  allsize: {
    flexGrow: 2,
    paddingLeft: '1rem'
  },
  moduleTitle: {
    fontFamily: 'Roboto',
    fontSize: '17px'
  },
  toolbar: {
    display: 'flex'
  },
  toolbarallsize: {
    flexGrow: 2,
    paddingLeft: '0.1rem'
  },
  toolbariconselected: {
    width: '16px',
    height: '16px',
    color: 'lightgrey'
  },
  toolbaricondeselected: {
    width: '16px',
    height: '16px'
  },
  newIcon: {
    color: 'red'
  }
}))

export interface ModuleItemListProps {
    list: Poll,
    loggedInUserId: string
}

export const ModuleItemList = ({ list, loggedInUserId }: ModuleItemListProps): any => {
  const newNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectType === NotificationObjectType.MODULE &&
      notification.type === NotificationType.NEW &&
      notification.objectId === list._id))
  const displayNewNotification = newNotifications?.length !== 0

  const updateNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectType === NotificationObjectType.MODULE &&
      notification.type === NotificationType.UPDATE &&
      notification.objectId === list._id))
  const displayUpdateNotification = updateNotifications?.length !== 0

  const glimpse = listGlimpse(list, loggedInUserId)
  const progressValue = parseInt(glimpse.substr(0, glimpse.indexOf(' ')))

  const classes = useStyles()

  return (
    <ListItem disabled={list.closed} className={classes.postList}>
      <StyledListIcon />
      <div className={classes.allsize}>
        <div className={classes.moduleTitle} style={{ fontWeight: displayNewNotification || displayUpdateNotification ? 'bold' : 'unset' }}>{list.title}</div>
        {!isNaN(progressValue)
          ? (
            <div className={classes.toolbar}>
              {glimpse[glimpse.length - 1] === '1' // last number of the string is '1' if currentUser voted, '0' otherwise
                ? <DoneIcon className={classes.toolbaricondeselected} />
                : <DoneIcon className={classes.toolbariconselected} />}
              <div className={classes.toolbarallsize}>
                <WidgetModuleProgressStatus value={progressValue} />
              </div>
            </div>)
          : null}
      </div>
      {list.closed
        ? <StyledClosedModuleIcon />
        : displayNewNotification
          ? <FiberNewIcon className={classes.newIcon} />
          : displayUpdateNotification
            ? <Badge style={{ paddingLeft: '10px' }} variant='dot' color='error' /> : null}
    </ListItem>
  )
}

export default memo(ModuleItemList)
