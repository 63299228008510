import { Badge, ListItem, makeStyles, Typography } from '@material-ui/core'
import React, { memo, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../interfaces/AppState'
import { ModuleType, NotificationData, NotificationObjectType, NotificationType, Poll, PollChoice, PollOption } from '../../../interfaces/interfacesApp'
import { getVotes, sortOptions } from '../../../lib/utils'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { useTranslation } from 'react-i18next'
import { LinearBarStatus } from '../../Widgets/WidgetModuleProgressStatus'
import { StyledClosedModuleIcon, StyledPollIcon } from '../../Styled/StyledIcons'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    paddingLeft: '1rem',
    paddingRight: 0
  },
  moduleGlimpse: {
    fontWeight: 400,
    color: '#888888',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  newIcon: {
    color: 'red'
  },
  options: {
    width: '100%'
  },
  option: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0
  },
  optionFlex: {
    display: 'flex',
    width: '100%',
    marginBottom: '2px'
  },
  optionTitle: {
    marginLeft: '0',
    fontFamily: 'Roboto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    whiteSpace: 'nowrap'
  },
  poll: {
    flexDirection: 'column',
    alignItems: 'baseline'
  }
}))

const renderOptions = (nbVoters : number, options : PollOption[], choices : PollChoice[], classes : any): any => {
  return options.map((option : PollOption) =>
    <ListItem key={option.id} className={classes.option}>
      <div className={classes.optionFlex}>
        <div className={classes.optionTitle}>{option.text}</div>
        <div>{getVotes(option.id, choices)}/{nbVoters}</div>
      </div>
      <LinearBarStatus variant='determinate' value={getVotes(option.id, choices) / nbVoters * 100} />
    </ListItem>
  )
}
export interface ModuleItemPollProps {
  users: string[],
  poll: Poll
}

export const ModuleItemPoll = ({ users, poll } : ModuleItemPollProps): ReactElement => {
  const newNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectType === NotificationObjectType.MODULE &&
      notification.type === NotificationType.NEW &&
      notification.objectId === poll._id))
  const displayNewNotification = newNotifications?.length !== 0

  const updateNotifications: NotificationData[] | undefined = useSelector((state: AppState) =>
    state.notifications.queryResult?.filter((notification: NotificationData) =>
      notification.objectType === NotificationObjectType.MODULE &&
      notification.type === NotificationType.UPDATE &&
      notification.objectId === poll._id))
  const displayUpdateNotifications = updateNotifications?.length !== 0

  const options = sortOptions(poll.options, poll.choices).slice(0, 2)

  const classes = useStyles()
  const { t } = useTranslation('translation')

  return (
    <>
      <ListItem disabled={poll.closed} className={classes.poll}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <StyledPollIcon />
          <Typography variant='h5' className={classes.title} style={{ fontWeight: displayNewNotification || displayUpdateNotifications ? 'bold' : 'unset' }}>
            {poll.title}
          </Typography>
          {poll.closed
            ? <StyledClosedModuleIcon />
            : displayNewNotification
              ? <FiberNewIcon className={classes.newIcon} />
              : displayUpdateNotifications
                ? <Badge style={{ paddingLeft: '10px' }} variant='dot' color='error' /> : null}
        </div>
        <div className={classes.options}>
          {poll.type === ModuleType.POLL ? renderOptions(users.length, options, poll.choices, classes) : null}
          {poll.options.length > 2 ? <Typography className={classes.moduleGlimpse}>{t('pods.remainingOptions', { count: poll.options.length - 2 })}</Typography> : null}
        </div>
      </ListItem>
    </>
  )
}

export default memo(ModuleItemPoll)
