import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { podTrackThunk, PodTrackThunk } from '../Pods/podsSlice'
import { User } from '../../interfaces/interfacesApp'
import makeDebug from 'debug'

const debug = makeDebug('PodTracker:')

const mapDispatch = { podTrackThunk }

export interface PodTrackerProps {
  loginId: string // id of the user
  trackPodId: string // currently tracked pod
  podTrackThunk: PodTrackThunk // Thunk to call on a pod change
  trackPodIdTarget: string // Current value of the pod id to track
}

const PodTracker = ({ trackPodId, trackPodIdTarget, loginId, podTrackThunk }: PodTrackerProps) : ReactElement => {
  debug(`Render: trackPodId=${trackPodId}, trackPodIdTarget=${trackPodIdTarget}, loginId:${loginId}`)

  // call the thunk only if the track target is differnt of the current track
  if (trackPodId !== trackPodIdTarget) { podTrackThunk(loginId, trackPodIdTarget) }

  return <div />
}

interface RootState {
  podsSlice: {
    trackPodIdTarget: string
    trackPodId: string
    trackingState: string
  },
  login : {
    user: User
  }
}

const mapState = (state: RootState) => {
  return ({
    trackPodIdTarget: state.podsSlice?.trackPodIdTarget,
    trackPodId: state.podsSlice?.trackPodId,
    trackingState: state.podsSlice?.trackingState,
    loginId: state.login?.user?._id
  })
}

export default connect(mapState, mapDispatch)(PodTracker)
