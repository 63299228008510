import ListIcon from '../CustomIcons/ListIcon'
import PollIcon from '@material-ui/icons/PollOutlined'
import { withStyles } from '@material-ui/core'
import ClosedModuleIcon from '@material-ui/icons/LockOutlined'

export const StyledListIcon = withStyles(() => ({
  root: {
    color: '#0008'
  }
}))(ListIcon)

export const StyledPollIcon = withStyles(() => ({
  root: {
    color: '#0008'
  }
}))(PollIcon)

export const StyledClosedModuleIcon = withStyles(() => ({
  root: {
    color: '#0008',
    paddingLeft: '1rem'
  }
}))(ClosedModuleIcon)
