import React, { useEffect, ReactElement } from 'react'
import LayoutLogin from '../../components/Layouts/LayoutLogin'
import { connect } from 'react-redux'
import { loginCleanUp, LoginCleanUp, loginThunk, LoginThunk, LoginState } from './loginSlice'

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Credentials } from '../../interfaces/interfacesApp'

import Debug from 'debug'
import { usersClient } from '../../App'
// import { useModal } from 'mui-modal-provider'
// import InformationDialog from '../../components/ModalDialogs/InformationDialog'

const debug = Debug('Login')

const mapDispatch = { loginCleanUp, loginThunk }

let logoutDone = false

export interface LoginProps {
  status: LoginState
  accessTokenFromURL?: string

  error: string
  loginThunk: LoginThunk
  loginCleanUp: LoginCleanUp
}

const Login = (props: LoginProps) : ReactElement => {
  // useEffect(() => bottomBarContext.setLogout(logout))

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: 'Login'
    })
  }, [])

  useEffect(() => {
    if (!logoutDone) {
      logoutDone = true
    }
  }) // TODO: find a better way to set logout in context once

  // const { showModal } = useModal()
  // useEffect(() => {
  //   if (props.error != null) {
  //     const modal: any = showModal(InformationDialog, {
  //       title: 'Login',
  //       description: props.error,
  //       onClose: () => { props.loginCleanUp(); modal.hide() }
  //     })
  //   }
  // }, [props.error])

  debug('Render')

  return (
    <div>
      <LayoutLogin
        pacerText={props.status !== LoginState.USER_NOT_LOGGED_IN ? props.status : null}
        onLogin={(creds: Credentials) => { props.loginThunk(creds, usersClient) }}
      />
    </div>
  )
}

interface RootState {
  login : {
    status: LoginState
    error: string
    token: string,
  }
}

const ACCESS_TOKEN_STR = '#access_token='

const mapState = (state : RootState) => {
  // retrieve token from the URL (used in SSO)
  const urlHash = window.location.hash.toString()
  const tokenPosition = urlHash.indexOf(ACCESS_TOKEN_STR)
  let accessTokenFromURL = ''
  let error = null
  if (tokenPosition >= 0) {
    accessTokenFromURL = urlHash.substr(tokenPosition + ACCESS_TOKEN_STR.length)
  } else {
    error = urlHash // In case of error, the error is provided in the URL
  }

  return ({
    accessTokenFromURL,
    status: state.login.status,
    error: error || state.login.error
  })
}

export default connect(mapState, mapDispatch)(Login)
