import { Button, Container, List, ListItem, ListItemText, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Logo from '../../assets/logo_symbol.svg'
import SubjectIcon from '@material-ui/icons/Subject'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import { generateUniquePublicEmail, getPublicEmailFromLocalStorage, getPublicNameFromLocalStorage, LOCAL_STORAGE_PUBLIC_EMAIL_KEY, LOCAL_STORAGE_PUBLIC_NAME_KEY, useQuery } from '../../lib/utils'
import { ParamTypes } from '../Pages/PagePodInvite'
import { useTranslation } from 'react-i18next'
import { LoginState, loginThunk, signupThunk } from '../../features/Login/loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import { usersClient } from '../../App'
import { AppState } from '../../interfaces/AppState'
import makeDebug from 'debug'
import { podInvite } from '../../features/Pods/podsSlice'

const debug = makeDebug('LayoutPublicAccesSignup:')

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(3),
    backgroundColor: 'white'
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    marginBottom: 20,
    height: 56,
    textTransform: 'none',
    fontSize: '18px',
    borderRadius: '10px',
    color: 'white',
    boxShadow: 'inherit'
  },
  icon: {
    fontSize: '28px',
    color: 'grey',
    marginRight: '10px'
  },
  logInSignUp: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paper: {
    borderRadius: '15px',
    boxShadow: 'inherit',
    border: '1px solid lightgray'
  }
})
)

const LayoutPublicAccesSignup = () : ReactElement | null => {
  const classes = useStyles()
  const { podid, page, moduleid } = useParams<ParamTypes>()
  const { t, i18n } = useTranslation('translation')
  const dispatch = useDispatch()
  const [nickname, setNickname] = useState(getPublicNameFromLocalStorage() || '')
  const [email] = useState(getPublicEmailFromLocalStorage() || generateUniquePublicEmail())
  const [createtriggered, setCreatetriggered] = useState(false)
  const createPending = useSelector((state: AppState) => state.users.createPending)
  const createError = useSelector((state: AppState) => state.users.isError)

  const [logintriggered, setLogintriggered] = useState(false)
  const loginStatus = useSelector((state: AppState) => state.login.status)

  const history = useHistory()
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const query = useQuery()
  useEffect(() => {
    if (query.get('lang') === 'fr') {
      i18n.changeLanguage('fr')
    } else {
      i18n.changeLanguage('en-US')
    }
    debug('LayoutPublicAccesSignup#didMount')
    return () => {
      debug('LayoutPublicAccesSignup#willUnmout')
    }
  }, [])

  const storeInvitation = () => {
    const invitePage = (page === undefined) ? null : (page === 'module') ? `poll/${moduleid}` : page

    // Store invite info in local storage
    if (podid) {
      window.localStorage.setItem('podInviteId', podid)
    }
    if (invitePage) {
      window.localStorage.setItem('invitePage', invitePage)
    }
    // Store invite info in redux
    dispatch(podInvite({ podInviteId: podid, invitePage }))
  }

  const handleSigninClick = () => {
    storeInvitation()
    const url = '/signin'
    debug(`Store Invitation and redirect to ${url}`)
    history.replace(url)
  }

  const handleSignupClick = () => {
    storeInvitation()
    const url = '/signup'
    debug(`Store Invitation and redirect to ${url}`)
    history.replace(url)
  }

  const handlePublicAccountClick = () => {
    if (getPublicEmailFromLocalStorage() !== null && getPublicNameFromLocalStorage() !== null) {
      debug('Trigger login')
      dispatch(loginThunk({ email, password: email }, usersClient))
      setCreatetriggered(true)
      setLogintriggered(true)
    } else if (!createtriggered) {
      debug('Create public account')
      dispatch(signupThunk({ firstname: nickname, lastname: '', email: email, password: email }))
      setCreatetriggered(true)
    }
  }

  debug(`Render: createtriggered:${createtriggered}, createPending:${createPending}, logintriggered:${logintriggered}, loginStatus:${loginStatus}`)

  if (createtriggered && !logintriggered && !createPending) {
    if (createError) {
      debug(`Failed to create public account because of: ${createError}`)
      setCreatetriggered(false)
    } else {
      debug('Succeeded to create public account, store email & nickname in local storage & trigger login')
      window.localStorage.setItem(LOCAL_STORAGE_PUBLIC_NAME_KEY, nickname)
      window.localStorage.setItem(LOCAL_STORAGE_PUBLIC_EMAIL_KEY, email)
      storeInvitation()
      dispatch(loginThunk({ email, password: email }, usersClient))
      setLogintriggered(true)
    }
    return null
  } else if (createtriggered && logintriggered && (loginStatus !== LoginState.USER_AUTH_ONGOING)) {
    if (loginStatus !== LoginState.USER_AUTH_OK) {
      debug(`Failed to login status:${loginStatus}`)
      setLogintriggered(false)
      setCreatetriggered(false)
    } else {
      // This code won't be executed because LayoutPublicAccessSignup component will be unmounted by the Routing strategy
      debug('Succeeded to login, Redirection will be performed by ListenerPodInvite')
      // const url = page === 'module' ? `/pod/${podid}/poll/${moduleid}` : `/pod/${podid}`
      // history.replace(url)
    }
    return null
  }

  return (
    <>
      <Container component='main' maxWidth='xs'>
        <img src={Logo} className={classes.logo} />
        <List>
          <ListItem>
            <ListItemText>
              <Typography style={{ fontWeight: 'bold' }} variant='h5'>{query.get('podtitle')}</Typography>
            </ListItemText>
          </ListItem>
          {query.get('poddesc') ? (
            <ListItem>
              <SubjectIcon className={classes.icon} />
              <ListItemText>
                <Typography variant='body1'>{query.get('poddesc')}</Typography>
              </ListItemText>
            </ListItem>
          ) : null}
          {query.get('modtitle') ? (
            <ListItem>
              <PollOutlinedIcon className={classes.icon} />
              <ListItemText>
                <Typography style={{ fontWeight: 'bold' }} variant='h6'>{query.get('modtitle')}</Typography>
              </ListItemText>
            </ListItem>
          ) : null}
          {query.get('moddesc') ? (
            <ListItem>
              <SubjectIcon className={classes.icon} />
              <ListItemText>
                <Typography variant='body1'>{query.get('moddesc')}</Typography>
              </ListItemText>
            </ListItem>
          ) : null}
        </List>
        <TextField disabled={getPublicNameFromLocalStorage() !== null || false} variant='filled' fullWidth label='Nom' value={nickname} onChange={e => { setNickname(e.target.value); e.preventDefault() }} />
        <Button onClick={handlePublicAccountClick} disabled={nickname === ''} color='primary' fullWidth variant='contained' className={classes.submit}>{t('invite.publicInviteAccess')}</Button>
        <Paper className={classes.paper}>
          <ListItem>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>{t('invite.publicInviteAccessTitle')}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2'>{t('invite.publicInviteAccessDesc')}</Typography>
          </ListItem>
          <ListItem className={classes.logInSignUp}>
            <Button onClick={handleSigninClick} color='primary' style={{ fontWeight: 'bold' }}>{t('login.signin')}</Button>
            <Button onClick={handleSignupClick} color='primary' style={{ fontWeight: 'bold' }}>{t('login.signup')}</Button>
          </ListItem>
        </Paper>
      </Container>
    </>
  )
}
export default LayoutPublicAccesSignup
