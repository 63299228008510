import React, { ReactElement, useContext, useState } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import { ListItem, List } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ListItemText from '@material-ui/core/ListItemText'
import NavBar from '../Menus/NavBar'
import { User } from '../../interfaces/interfacesApp'
import AvatarUser from '../Widgets/AvatarUser'
import { getColorFromID, isPublicEmail } from '../../lib/utils'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import { LogoutContext } from '../../features/Login/ConnectionProvider'
import { useDispatch } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { appVersion, appBuild, usersServices } from '../../App'
import { useHistory } from 'react-router-dom'
import { MainBottomBarSelectedItemType } from '../Menus/MainBottomBar'
import ConfirmationDialog from '../ModalDialogs/ConfirmationDialog'
import { useModal } from 'mui-modal-provider'

export const useStyles = makeStyles(() => ({
  username: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '20px'
  },
  useremail: {
    fontSize: '16px',
    color: '#404040'
  },
  userContainer: {

    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    borderBottom: 'solid 1px lightgrey'
  },
  avatar: { height: '80px', width: '80px' },
  listRoot: {
    width: '100%'
  },
  listItemRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'unset',
    paddingLeft: '50px'
  },
  listItemText: {
    borderBottom: 'solid 1px lightgrey',
    flex: '0',
    paddingBottom: '20px',
    color: '#404040'
  },
  icon: {
    position: 'absolute',
    left: '0px',
    top: '12px',
    color: 'lightgrey'
  },
  iconExpand: {
    color: 'black',
    backgroundColor: 'red'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  }
}))

export interface LayoutProfileProps {
  user: User
}
const LayoutProfile = ({ user } : LayoutProfileProps): ReactElement => {
  const [userLanguage, setUserLanguage] = useState(user.locale)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const history = useHistory()
  const isPublic = isPublicEmail(user.email || '')
  const { showModal } = useModal()

  // Disconnect
  const logoutContext = useContext(LogoutContext)
  const handleLogOut = () => {
    if (isPublic) {
      const modal = showModal(ConfirmationDialog, {
        title: t('popup.logout.title'),
        description: t('popup.logout.desc'),
        onConfirm: () => {
          logoutContext()
          history.push('/')
        },
        onCancel: () => {
          modal.hide()
        }
      })
    } else {
      logoutContext()
      history.push('/')
    }
  }

  // Change Language
  const handleLanguage = (event: React.ChangeEvent<{ value : unknown}>) => {
    const newLanguage = event.target.value as string
    setUserLanguage(newLanguage)
    // Language change in the interface is performed in ListenerLoginUser
    dispatch(usersServices.users.patch(user._id, { locale: newLanguage }))
  }

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Viewing User Profil'
    })
  }, [])
  return (
    <>
      <NavBar title={t('profile.title')} mainBottomBar={MainBottomBarSelectedItemType.PROFILE} />
      <Container className={classes.userContainer}>
        <AvatarUser withBadge={false} user={user} width='80px' height='80px' fontSize='24px' backgroundColor={(getColorFromID(user._id))} />
        <Typography className={classes.username}>{user.firstname} {' '} {user.lastname} </Typography>
        <Typography className={classes.useremail}>{isPublic ? t('profile.publicMail') : user.email} </Typography>
      </Container>
      <Container>
        <List className={classes.listRoot}>
          <ListItem disabled button className={classes.listItemRoot}>
            <SettingsIcon className={classes.icon} />
            <ListItemText className={classes.listItemText} primary={t('profile.settings')} />
          </ListItem>
          <ListItem button className={classes.listItemRoot}>
            <ExitToAppOutlinedIcon className={classes.icon} />
            <ListItemText onClick={handleLogOut} className={classes.listItemText} primary={t('profile.logout')} />
          </ListItem>
          <ListItem button className={classes.listItemRoot}>
            <InfoOutlinedIcon className={classes.icon} />
            <ListItemText onClick={() => { window.open('http://www.symly.fr') }} className={classes.listItemText} primary={t('profile.about', { version: appVersion })} secondary={t('profile.build', { build: appBuild })} />
          </ListItem>
          <ListItem className={classes.listItemRoot}>
            <LanguageOutlinedIcon className={classes.icon} />
            <FormControl className={classes.formControl}>
              <InputLabel>{t('profile.language')}</InputLabel>
              <Select
                native
                value={userLanguage}
                onChange={handleLanguage}
              >
                <option value='fr'>Français</option>
                <option value='en'>English</option>
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Container>
    </>
  )
}

export default LayoutProfile
