import { Button, Container, CssBaseline, TextField } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NavBar from '../Menus/NavBar'
import { makeStyles } from '@material-ui/core/styles'
import PasswordStrength from '../Widgets/PasswordStrength'
import { ResetPasswordThunk } from '../../features/ResetPassword/resetPasswordSlice'
import ReCAPTCHA from 'react-google-recaptcha'
import i18n from '../../i18n'
import { captchaSitekey } from '../../App'
import { emailRegex } from '../../lib/utils'
import WidgetDisplayInfo from '../Widgets/WidgetDisplayInfo'
import EmailIcon from '../../assets/icon-email.svg'
import { useModal } from 'mui-modal-provider'
import InformationDialog from '../ModalDialogs/InformationDialog'

const useStyles = makeStyles(() => ({
  part: {
    marginTop: '1.5rem',
    display: 'flex',
    position: 'relative',
    margin: 'auto'
  },
  button: {
    marginTop: '1.5rem',
    display: 'flex',
    position: 'relative',
    margin: 'auto',
    height: '3.5rem'
  }
}))

interface LayoutResetPasswordProps {
  resetPasswordThunk: ResetPasswordThunk
  resetStatus: string,
  resetError: string
}

export default function LayoutResetPassword (props: LayoutResetPasswordProps) : ReactElement {
  const [email, setEmail] = useState('')
  const [clientStatus, setClientStatus] = useState('idle')
  const [open, setOpen] = useState(false)
  const [captcha, setCaptcha] = useState(null)

  const language = i18n.language
  const handleCaptchaChange = (value: any) => {
    console.log('Captcha value:', value)
    setCaptcha(value)
  }

  const emailError = ((email !== '') && !((new RegExp(emailRegex)).test(email)))

  const { t } = useTranslation('translation')
  const classes = useStyles()
  const { showModal } = useModal()
  const disabled = (email === '' || emailError || captcha === null)
  const dialogTitle = (props.resetError === 'User not found.') ? t('resetPassword.errorEmailTitle') : t('systemError.title')
  const dialogDesc = (props.resetError === 'User not found.') ? t('resetPassword.errorEmailDesc', { email: email }) : (props.resetError ? t('systemError.desc', { error: props.resetError }) : '')

  if (clientStatus === 'waiting for server response') {
    if (props.resetStatus === 'success') {
      return (
        <WidgetDisplayInfo
          barTitle={t('resetPassword.title')}
          title={t('resetPassword.sentTitle')}
          desc1={t('resetPassword.sent') + `${email}`}
          image={EmailIcon}
          buttonLabel='OK'
          link='/login'
        />
      )
    } else if (props.resetStatus === 'error') {
      setClientStatus('idle')
      const modal = showModal(InformationDialog, {
        title: dialogTitle,
        description: dialogDesc,
        onClose: () => {
          modal.hide()
        }
      })
    } // else (props.resetStatus === 'requesting')
  }

  return (
    <>
      <NavBar back title={t('resetPassword.title')} />
      <Container maxWidth='xs'>
        <CssBaseline />
        {(email === '') && <span className={classes.part}>{t('resetPassword.desc')}</span>}
        <TextField id='reset-password' label={t('resetPassword.textfield')} variant='outlined' type='email' error={emailError} {...(emailError && { helperText: t('login.emailError') })} className={classes.part} onChange={msg => setEmail(msg.target.value)} />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <ReCAPTCHA sitekey={captchaSitekey} hl={language} onChange={handleCaptchaChange} />
        </div>
        <Button disabled={disabled} fullWidth variant='contained' color='primary' className={classes.button} onClick={() => { props.resetPasswordThunk(email.toLowerCase()); setClientStatus('waiting for server response') }}>{t('resetPassword.send')}</Button>
        <span className={classes.part}>{t('resetPassword.note')}</span>
      </Container>
    </>
  )
}

interface LayoutChangePasswordProps {
  onChangePassword: any
}

export function LayoutChangePassword (props: LayoutChangePasswordProps) : ReactElement {
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')

  const { t } = useTranslation('translation')
  const classes = useStyles()
  const error = (password !== '' && confirm !== '' && password !== confirm)
  const disabled = (password === '' || password !== confirm)

  return (
    <>
      <NavBar title={t('resetPassword.changeTitle')} />
      <Container maxWidth='xs'>
        <CssBaseline />
        <TextField type='password' id='change-password' label={t('resetPassword.changePassword')} variant='outlined' className={classes.part} value={password} onChange={msg => setPassword(msg.target.value)} />
        {(password.length > 0) && <PasswordStrength password={password} />}
        <TextField error={error} {...(error && { helperText: t('resetPassword.errorPassword') })} type='password' id='confim-password' label={t('resetPassword.confirmPassword')} variant='outlined' className={classes.part} value={confirm} onChange={msg => setConfirm(msg.target.value)} />
        <Button fullWidth variant='contained' color='primary' disabled={disabled} onClick={() => props.onChangePassword(password)} className={classes.button}>{t('resetPassword.changeButton')} </Button>
      </Container>
    </>
  )
}
