import React, { ReactElement } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export interface FeedbackDialog {
  error?: boolean
  success?: boolean
  timeout?: number
  open : boolean
  description: string
  onClose?: any
}

export function showFeedbackDialog (timeout: number, message: string, setMessage: React.Dispatch<React.SetStateAction<string>>, setOpen: React.Dispatch<React.SetStateAction<boolean>>) : void {
  setMessage(message)
  setOpen(true)
  setTimeout(() => {
    setOpen(false)
  }, timeout)
}

export default function FeedbackDialog (props: FeedbackDialog): ReactElement {
  const classes = useStyles()
  return (
    <div>
      <Dialog
        hideBackdrop
        aria-describedby='alert-dialog-description'
        open={props.open}
        onClose={props.onClose
          ? props.onClose()
          : null}
      >
        <DialogContent>
          <div className={classes.centered}>
            {props.error ? <ErrorOutlineOutlinedIcon color='secondary' fontSize='large' /> : null}
            {props.success ? <CheckOutlinedIcon color='primary' fontSize='large' /> : null}
          </div>
          <DialogContentText id='alert-dialog-description'>
            {props.description}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
