import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Container, ListItemIcon, makeStyles, Typography } from '@material-ui/core'
import SubjectIcon from '@material-ui/icons/Subject'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import WidgetTextField from '../Widgets/WidgetTextField'
import { useDispatch } from 'react-redux'
import { podEditThunk } from '../../features/Pods/podsSlice'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import { NotificationData, Pod } from '../../interfaces/interfacesApp'
import makeDebug from 'debug'
import { userNotificationDeleteThunk } from '../../features/userNotifications/userNotificationsSlice'

const debug = makeDebug('LayoutPodDesc:')

export interface LayoutPodDesc {
  pod: Pod
  notifications?: NotificationData[]
}

const useStyles = makeStyles(() => ({
  screenSize: {
    maxWidth: '100vw',
    height: 'calc(100vh - 124px)'
  },
  icon: {
    fontSize: '24px',
    paddingLeft: '10px'
  }
}))

const LayoutPodDesc = ({ pod, notifications }: LayoutPodDesc): ReactElement => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('translation')
  const classes = useStyles()

  const titleNotification = notifications?.filter((notification : NotificationData) => notification.objectId === 'title').length !== 0
  const descriptionNotification = notifications?.filter((notification : NotificationData) => notification.objectId === 'description').length !== 0
  const dateNotification = notifications?.filter((notification : NotificationData) => notification.objectId === 'dateEnd').length !== 0

  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [datePickerValue, setDatePickerValue] = useState(new Date(pod.dateEnd as Date))

  const formatedDate = !isNaN(Date.parse(datePickerValue as any)) ? Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'short',
    weekday: 'short'
  }).format(datePickerValue) : undefined

  const handleDescSave = useCallback((description) => description !== pod.description ? dispatch(podEditThunk(pod, { description })) : null, [pod])
  const handleTitleSave = useCallback((title) => title !== pod.title ? dispatch(podEditThunk(pod, { title })) : null, [pod])
  const handleDateSave = useCallback((dateEnd) => {
    if (!isNaN(Date.parse(dateEnd)) && new Date(dateEnd as Date).toString() !== new Date(pod.dateEnd as Date).toString()) {
      dispatch(podEditThunk(pod, { dateEnd }))
    }
  }, [pod])

  // Keep latest notifications in a ref & initialize notifications local states.
  const latestNotifications = useRef(notifications)
  useEffect(() => {
    latestNotifications.current = notifications
  })

  // Remove update pod notifications on unmount
  useEffect(() => {
    debug(`mount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
    return () => {
      debug(`unmount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
      if (latestNotifications.current?.length) {
        dispatch(userNotificationDeleteThunk(latestNotifications.current))
      }
    }
  }, [])

  debug('Render')

  return (
    <>
      <Container className={classes.screenSize}>
        <Box width='80vw' overflow='hidden' textOverflow='ellipsis' p={3}>
          <Typography component='span'>
            <WidgetTextField
              value={pod.title}
              save={handleTitleSave}
              style={{ underline: true, sizeFont: '2em', multiline: true, fontWeight: titleNotification }}
            />
          </Typography>
        </Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <SubjectIcon className={classes.icon} color={!pod.description ? 'disabled' : 'inherit'} />
            </ListItemIcon>
            <WidgetTextField
              placeHolder={t('pods.podDesc.placeHolder')}
              value={pod.description === null ? '' : pod.description}
              save={handleDescSave}
              style={{ underline: true, multiline: true, fontWeight: descriptionNotification }}
            />
          </ListItem>
          <ListItem>
            <MuiPickersUtilsProvider locale={i18n.language === 'en' ? en : fr} utils={DateFnsUtils}>
              <KeyboardDatePicker
                open={openDatePicker}
                InputAdornmentProps={{ position: 'start' }}
                onOpen={() => setOpenDatePicker(true)}
                onClose={() => setOpenDatePicker(false)}
                autoOk
                fullWidth
                variant='inline'
                format='dd/MM/yyyy'
                placeholder={pod.dateEnd ? formatedDate : t('pods.podDate.placeHolder')}
                onChange={(date: any) => {
                  setDatePickerValue(date)
                  handleDateSave(date)
                }}
                InputProps={{ disableUnderline: true, readOnly: true, onClick: () => setOpenDatePicker(true), style: { fontWeight: dateNotification ? 'bold' : 'inherit' } }}
                onBlur={() => { pod.dateEnd === null ? handleDateSave(null) : handleDateSave(pod.dateEnd) }}
                value={pod.dateEnd === undefined ? null : pod.dateEnd} // Si undefined >> date du jour
              />
            </MuiPickersUtilsProvider>
          </ListItem>
        </List>
      </Container>
    </>
  )
}

export default memo(LayoutPodDesc)
