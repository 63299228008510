import React, { memo, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LayoutVerifyToken from '../../components/Layouts/LayoutVerifyToken'
import { verifyTokenCreateThunk } from '../../features/VerifyToken/verifyTokenSlice'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLocation } from 'react-router-dom'
import { AppState } from '../../interfaces/AppState'

const VerifyToken = (): ReactElement => {
  const location = useLocation()
  const token : string = new URLSearchParams(location.search).get('token') || ''
  const status = useSelector((appState: AppState) => appState.verifyToken.create)
  const error = useSelector((appState: AppState) => appState.verifyToken.createError)
  const dispatch = useDispatch()

  // an errror occured and the process is finished
  if (status === 'error') {
    return (
      <LayoutVerifyToken status='error' errorMsg={error} />
    )
  }
  // no errror occured and the process is finished
  if (status === 'done') {
    return (
      <LayoutVerifyToken status='verified' errorMsg={null} />
    )
  }
  if (!status) {
    dispatch(verifyTokenCreateThunk(token))
  }

  // other case
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default memo(VerifyToken)
