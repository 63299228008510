import React, { ReactElement } from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

// Build from 'icon-list.svg' asset
const ListIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <g id='Groupe_1417' data-name='Groupe 1417'>
        <rect id='Rectangle_1656' data-name='Rectangle 1656' width='24' height='24' fill='none' />
      </g>
      <g id='Groupe_1419' data-name='Groupe 1419' transform='translate(2 3)'>
        <g id='Groupe_1418' data-name='Groupe 1418'>
          <path id='Tracé_2225' data-name='Tracé 2225' d='M20,3H4A2.006,2.006,0,0,0,2,5V19a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,20,3Zm0,16H4V5H20Z' transform='translate(-2 -3)' fillRule='evenodd' />
          <path id='Tracé_2226' data-name='Tracé 2226' d='M19.41,10.42,17.99,9l-3.17,3.17-1.41-1.42L12,12.16,14.82,15Z' transform='translate(-2 -3)' fillRule='evenodd' />
          <rect id='Rectangle_1657' data-name='Rectangle 1657' width='5' height='2' transform='translate(3 4)' />
          <rect id='Rectangle_1658' data-name='Rectangle 1658' width='5' height='2' transform='translate(3 8)' />
          <rect id='Rectangle_1659' data-name='Rectangle 1659' width='5' height='2' transform='translate(3 12)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export default ListIcon
