import React, { ReactElement, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import MainBottomBar, { MainBottomBarSelectedItemType } from './MainBottomBar'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { StyledNavBarButton } from '../Styled/StyledNavBarButton'
import makeDebug from 'debug'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { List, ListItem, ListItemText, MenuItem } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import { NotificationData } from '../../interfaces/interfacesApp'

const debug = makeDebug('NavBar:')

const useStyles = makeStyles(() => ({
  titleContainer: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  titleWithSubtitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  subTitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'grey',
    textAlign: 'left',
    marginTop: '-10px',
    fontSize: '12px'

  },
  verifyAccount: {
    backgroundColor: '#007C89',
    height: '6rem',
    overflow: 'hidden'
  },
  menu: {
    marginTop: '30px'
  },
  menuSettings: {
    fontSize: '0.87em'
  },
  menuDelete: {
    fontSize: '0.87em',
    color: 'red'
  },
  banner: {
    backgroundColor: '#007C89',
    color: 'white',
    height: '7rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '-10px',
    position: 'relative'
  },
  bannerEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: 0,
    position: 'absolute',
    bottom: '0px'
  },
  bannerButton: {
    color: 'white',
    fontWeight: 'bold'
  },
  bannerText: {
    color: 'white'
  }
}))

interface VerifyAccountProps {
  onResentVerify: any
}

function VerifyAccount ({ onResentVerify } : VerifyAccountProps) : ReactElement {
  const classes = useStyles()

  const { t } = useTranslation('translation')
  return (
    <>
      <List className={classes.verifyAccount}>
        <ListItem>
          <ListItemText>
            <Typography className={classes.bannerText}>{t('navbar.verifyEmail.text')}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.bannerEnd}>
          <Button className={classes.bannerButton} onClick={() => { onResentVerify() }}>{t('navbar.verifyEmail.button')}</Button>
        </ListItem>
      </List>
    </>
  )
}

const CreatePrivateAccount = () : ReactElement => {
  const classes = useStyles()

  const { t } = useTranslation('translation')
  return (
    <>
      <List className={classes.banner}>
        <ListItem>
          <ListItemText>
            <Typography className={classes.bannerText}>{t('navbar.createPrivateAccount.text')}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.bannerEnd}>
          <Link style={{ textDecoration: 'unset' }} to='/publicsignup'>
            <Button className={classes.bannerButton}>{t('navbar.createPrivateAccount.button')}</Button>
          </Link>
        </ListItem>
      </List>
    </>
  )
}

interface NavBarMenuItem {
  label: string
  callBack: any
  colorProps?: string
}

export interface NavBarMenuProps {
  menuItems: NavBarMenuItem[]
}

const NavBarMenu = ({ menuItems } : NavBarMenuProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null) // Détermine l'élément sur lequel le menu est ancré.
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => { // Place l'élément dans le state.
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
      >{menuItems.map((item, index) => {
          return (
            <MenuItem key={index} style={{ color: item.colorProps }} dense onClick={() => { item.callBack(); handleClose() }}>{item.label}</MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
interface LayoutNavBarProps {
  title: string
  subTitle?: string
  onBack?: any, // Back callback to be executed
  backUrl?: string, // Redirect to backUrl
  back?: boolean, // Back in browser history
  mainBottomBar?: MainBottomBarSelectedItemType,
  onResendVerifyToken?: any,
  onTitleClicked?: any,
  shareIcon?: any,
  onActionButton?: any, // TODO why is there a timeout on this one before history back?
  actionButtonTitle?: string
  isActionButtonDisabled?: boolean
  iconParametersPollChoices?: boolean
  navBarMenu?: NavBarMenuProps,
  isPublicAccount?: boolean,
  notifications?: NotificationData[]
}

// function NavBar ({ mainBottomBar, back, edit, quit, editTitle, onAdd, onAddPoll, onSave, title, pod, onCancelAddFriend, onSaveFriend, backToEdit, moduleType, isVerified, onResentVerifyToken, userVerifyToken, user } : LayoutNavBarProps) {
function NavBar ({ title, subTitle, onBack, backUrl, back, mainBottomBar, onResendVerifyToken, onTitleClicked, shareIcon, onActionButton, actionButtonTitle, isActionButtonDisabled, iconParametersPollChoices, navBarMenu, isPublicAccount, notifications } : LayoutNavBarProps): ReactElement|null {
  const classes = useStyles()
  const history = useHistory()

  debug('render (history)', history)

  // TODO: better implementation to manage browser history in app without going out of the app on back
  // either implement an index in redux (intialised at 0) and inc it on push and dec it on pop
  // or use a libray who does the job: for instance https://github.com/supasate/connected-react-router
  const handleBackClick = () => {
    if (onBack) {
      debug('Execute specific onBack callback')
      onBack()
    } else if (backUrl) {
      debug('Replace to specified backUrl')
      history.replace(backUrl)
    } else {
      debug('Go back in browser history')
      history.goBack()
    }
  }

  return (
    <>
      <AppBar position='fixed' color='default'>
        <Toolbar>
          {onBack || backUrl || back ? <IconButton edge='start' color='inherit' onClick={handleBackClick}> <ChevronLeft /> </IconButton> : null}
          {onTitleClicked
            ? subTitle
              ? (
                <div className={classes.titleContainer}>
                  <Typography className={classes.titleWithSubtitle} onClick={onTitleClicked}>{title}<IconButton color='inherit'><EditOutlinedIcon style={{ fontSize: 15 }} /> </IconButton></Typography>
                  <Typography className={classes.subTitle} onClick={onTitleClicked}>{subTitle}</Typography>
                </div>)
              : (
                <Typography className={classes.title} onClick={onTitleClicked}>
                  {title} <IconButton color='inherit'><EditOutlinedIcon style={{ fontSize: 15 }} /> </IconButton>
                </Typography>)
            : (
              <Typography className={classes.title}>
                {title}
              </Typography>)}
          {shareIcon ? <IconButton color='inherit' onClick={shareIcon}> <ShareOutlinedIcon style={{ color: 'grey' }} /> </IconButton> : null}
          {onActionButton ? <StyledNavBarButton disabled={isActionButtonDisabled} onClick={() => { onActionButton() }}>{actionButtonTitle}</StyledNavBarButton> : null}
          {iconParametersPollChoices && navBarMenu ? <NavBarMenu menuItems={navBarMenu.menuItems} /> : null}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {mainBottomBar
        ? <MainBottomBar item={mainBottomBar} notifications={notifications || []} />
        : null}
      {isPublicAccount
        ? <CreatePrivateAccount />
        : onResendVerifyToken
          ? <VerifyAccount onResentVerify={onResendVerifyToken} />
          : null}
    </>)
}

export default NavBar
