import React, { ReactElement, useState } from 'react'
import { Redirect } from 'react-router-dom'
import smartLogo from '../../assets/illustration-smart-600px.svg'
import togetherLogo from '../../assets/illustration-together-600px.svg'

// ts-ignore because we can't use AutoRotatingCarousel otherwise
// @ts-ignore
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  image: {
    width: '15rem'
  }
}))

interface LayoutOnboardingProps {
  onRedirect: any
}

const LayoutOnboarding = (props: LayoutOnboardingProps) : ReactElement => {
  const { t } = useTranslation('translation')
  const classes = useStyles()

  const [redirect, setRedirect] = useState(false)

  if (redirect) {
    props.onRedirect()
    return <Redirect to='/pods' />
  }

  return (
    <div>
      <AutoRotatingCarousel
        autoplay
        label={t('onboarding.button')}
        style={{ position: 'absolute' }}
        interval={10000}
        open
        mobile
        onStart={() => setRedirect(true)}
      >
        <Slide
          title={t('onboarding.title1')}
          subtitle={t('onboarding.desc1')}
          style={{ backgroundColor: 'rgba(0, 124, 137, 0.6)' }}
          mediaBackgroundStyle={{ backgroundColor: 'white' }}
          media={<img src={smartLogo} className={classes.image} />}
        />
        <Slide
          title={t('onboarding.title2')}
          subtitle={t('onboarding.desc2')}
          style={{ backgroundColor: 'rgba(0, 124, 137, 0.6)' }}
          mediaBackgroundStyle={{ backgroundColor: 'white' }}
          media={<img src={togetherLogo} className={classes.image} />}
        />
      </AutoRotatingCarousel>
    </div>
  )
}

export default LayoutOnboarding
