import React, { memo, ReactElement } from 'react'
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@material-ui/core'
import WidgetTextField from './WidgetTextField'
import MaximizeIcon from '@material-ui/icons/Maximize'
import { makeStyles } from '@material-ui/core/styles'
import { getVotersByOption } from '../../lib/utils'
import { ModuleType, Poll, User } from '../../interfaces/interfacesApp'
import ListItemUser from './ListItemUser'
import i18n from '../../i18n'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useTranslation } from 'react-i18next'
import { pollUpdateOptionThunk, pollDeleteOptionThunk } from '../../features/Polls/pollsSlice'
import { useDispatch } from 'react-redux'
import { useModal } from 'mui-modal-provider'
import ConfirmationDialog from '../ModalDialogs/ConfirmationDialog'

const useStyles = makeStyles((theme) => ({
  dragIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  vote: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'gray'
  },
  delete: {
    color: 'red'
  },
  deleteIcon: {
    marginRight: '20px',
    color: 'red',
    textTransform: 'none'
  },
  creationText: {
    fontSize: '14px'
  },
  disabledLayout: {
    opacity: theme.palette.action.disabledOpacity,
    pointerEvents: 'none'
  }
}))

export type onOpen = () => void
export type onClose = () => void

interface WidgetSwipeableDrawerVoteProps{
  users : User[]
  poll : Poll
  onOpen: onOpen
  onClose: onClose
  open: boolean
  option: any
}

const WidgetSwipeableDrawerVote = ({ users, poll, open, onOpen, onClose, option }: WidgetSwipeableDrawerVoteProps) : ReactElement | null => {
  if (option == null || poll?.config == null) { return null } // Test input values

  const pollVoters = getVotersByOption(poll.choices, option.id, users)

  const { t } = useTranslation('translation')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showModal } = useModal()

  const createionDateToFormat = new Date(option.creationDate)
  const formatedDate = !isNaN(Date.parse(createionDateToFormat as any)) ? Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric'
  }).format(createionDateToFormat) : undefined
  const handleSave = (newOptionText : string) => {
    const updatedOption = { ...option, text: newOptionText }
    dispatch(pollUpdateOptionThunk(poll, updatedOption))
  }

  const handleDeleteOptionWithConfirmation = () => {
    const modal = showModal(ConfirmationDialog, {
      title: t('polls.deletePollOptionConfirmation.title'),
      description: t('polls.deletePollOptionConfirmation.description'),
      confirmButtonLabel: t('polls.deletePollOptionConfirmation.confirmButton'),
      onConfirm: () => {
        dispatch(pollDeleteOptionThunk(poll, option.id))
        modal.hide()
        onClose()
      },
      onCancel: () => {
        modal.hide()
      }
    })
  }

  const textEditable = (poll.type === ModuleType.POLL) || (pollVoters.length === 0) || poll.config?.enableMultipleUserPerOption

  return (
    <>
      <SwipeableDrawer
        style={{ zIndex: 1200 }}
        anchor='bottom'
        onOpen={onOpen}
        onClose={onClose}
        open={open}
        PaperProps={{ style: { borderTopLeftRadius: '30px', borderTopRightRadius: '30px' } }}
      >
        <List>
          <ListItemIcon className={classes.dragIcon}>
            <MaximizeIcon fontSize='large' style={{ color: 'gray' }} />
          </ListItemIcon>
          <div className={poll.closed ? classes.disabledLayout : ''}>
            <ListItem>
              <WidgetTextField
                style={{
                  variantProps: 'filled',
                  sizeFont: '1rem',
                  disabledProps: !textEditable,
                  textLineThrough: !textEditable
                }}
                label={t('polls.pollDrawer.label')}
                value={option.text}
                save={handleSave}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Typography className={classes.vote}>
                  {pollVoters.length > 0 ? t('polls.pollDrawer.numberOfVote', { count: pollVoters.length }) : t('polls.pollDrawer.noVote')}
                </Typography>
              </ListItemText>
            </ListItem>
            {!poll.config.enableAnonymousChoice && pollVoters.length > 0 ? pollVoters.map((voter, index) => {
              return (
                <ListItemUser user={voter} key={voter._id} index={index} />
              )
            }) : null}
            <Divider />
            <Button className={classes.deleteIcon} onClick={handleDeleteOptionWithConfirmation}>
              <ListItem>
                <DeleteOutlineIcon className={classes.deleteIcon} />
                {t('polls.pollDrawer.delete')}
              </ListItem>
            </Button>
            <Divider />
            <ListItem>
              {users.map((user, index) => {
                return (
                  <Typography
                    className={classes.creationText}
                    key={index}
                  >{user._id === option.creatorId ? t('polls.pollDrawer.creation', { name: user.firstname, date: formatedDate }) : null}
                  </Typography>
                )
              })}
            </ListItem>
          </div>
        </List>
      </SwipeableDrawer>
    </>)
}

export default memo(WidgetSwipeableDrawerVote)
