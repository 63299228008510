import React, { ReactElement, useCallback, useState, useEffect, memo, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AddCircle from '@material-ui/icons/AddCircle'
import SendIcon from '@material-ui/icons/Send'
import WidgetPosts from '../Widgets/Posts'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Container, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MediaLoader, { MediaLoaderAction } from '../Widgets/MediaLoader'
import ListenerWindowScroll, { ScrollStatus, ScrollPosition } from '../Listeners/ListenerWindowScroll'
import makeDebug from 'debug'
import { Post, Pod, ModuleType, User, NotificationData } from '../../interfaces/interfacesApp'
import WidgetSwipeableDrawer from '../Widgets/WidgetSwipeableDrawer'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { chatEditThunk } from '../../features/Chat/chatSlice'
import { useModal } from 'mui-modal-provider'
import ConfirmationDialog from '../ModalDialogs/ConfirmationDialog'
import { userNotificationDeleteThunk } from '../../features/userNotifications/userNotificationsSlice'

const debug = makeDebug('LayoutPodChat:')

const bottomMargin = 80 // necessary for the scroll listener

export const useStyles = makeStyles((theme) => ({
  screenSize: {
    maxWidth: '100%',
    padding: 'unset',
    height: 'calc((100vh - 104px) - 1.5rem)'
  },
  inputBar: {
    position: 'fixed',
    bottom: 0,
    height: '70px',
    backgroundColor: 'white',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid lightgray'
  },
  spacer: {
    marginTop: '1rem',
    marginBottom: `${bottomMargin}px`
  },
  dot: {
    height: '30px',
    width: '30px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'inline-block',
    position: 'absolute',
    textAlign: 'center'
  },
  arrow: {
    color: 'white',
    position: 'relative',
    fontSize: '30'
  },
  flowButton: {
    color: theme.palette.primary.main,
    zIndex: 2
  },
  flowCircle: {
    borderRadius: '100%',
    position: 'absolute',
    left: '12px',
    backgroundColor: theme.palette.primary.main,
    width: '35px',
    height: '35px',
    opacity: '21%',
    zIndex: 1,
    animation: '$ring 3s infinite'
  },
  '@keyframes ring': {
    '0%': {
      transform: 'scale(1)'
    },
    '50%': {
      transform: 'scale(1.5)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  CCCinstruction: {
    width: '236px',
    height: '59px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    bottom: '4.7rem',
    left: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  notchedOutline: {
    borderRadius: '25px',
    borderColor: '#EDEDED',
    borderWidth: '0'
  },
  textFieldbackgroundColor: {
    backgroundColor: '#EDEDED',
    borderRadius: '20px'
  },
  bottomPostMarge: {
    paddingBottom: '72px'
  }
}))

export type OnSendMessage = (text: string, medias: string[]|null) => void

export type OnDeleteMessage = (postId: string) => void

interface LayoutChatProps {
  pod: Pod
  posts: Post[]
  users: User[]
  friendIds: string[]
  loggedInUserId: string
  onSendMessage: OnSendMessage
  onDeleteMessage: OnDeleteMessage
  mediaToken: string
  notifications?: NotificationData[]
}

function haveFlow (posts : Post[]): boolean {
  for (let i = 0; i < posts.length; i++) {
    if (posts[i].moduleType) {
      return true
    }
  }
  return false
}

const LayoutPodChat = ({ pod, mediaToken, posts, loggedInUserId, onSendMessage, notifications } : LayoutChatProps): ReactElement => {
  // Keep latest notifications in a ref.
  const latestNotifications = useRef(notifications)
  useEffect(() => {
    latestNotifications.current = notifications
  })

  const dispatch = useDispatch()
  const [text, setText] = useState('')
  const [displaySend, setDisplaySend] = useState(false)
  const [poll, setPoll] = useState(false)
  const [list, setList] = useState(false)
  const [listN, setListN] = useState(false)
  const [uploadAction, setUploadAction] = useState(MediaLoaderAction.none)
  const [mediaAtached, setMediaAttached] = useState(false)
  const [scrollStatus, setScrollStatus] = useState(ScrollStatus.BOTTOM)

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const classes = useStyles()

  const { t } = useTranslation('translation')
  const { showModal } = useModal()

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: `Viewing chat of "${pod.title}"`
    })
  }, [])

  useEffect(() => {
    debug(`mount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
    return () => {
      debug(`unmount: latestNotifications=${latestNotifications.current?.length}`, latestNotifications.current)
      if (latestNotifications.current?.length) {
        dispatch(userNotificationDeleteThunk(latestNotifications.current))
      }
    }
  }, [])

  type Anchor = 'bottom'

  const handleDeletePostWithConfirmation = (post : Post) => {
    const modal = showModal(ConfirmationDialog, {
      title: t('chat.deletePost.title'),
      description: t('chat.deletePost.description'),
      confirmButtonLabel: t('chat.deletePost.confirmButton'),
      onConfirm: () => {
        const updatedPost = { message: '', medias: null }
        dispatch(chatEditThunk(post, updatedPost))
        modal.hide()
      },
      onCancel: () => {
        modal.hide()
      }
    })
  }

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const mediaUploadedHandler = useCallback((id: string) => {
    console.log('upload done', id, text)
    setUploadAction(MediaLoaderAction.none)
    const medias : [string] = [id]
    onSendMessage(text, medias)
    setText('')
    setMediaAttached(false)
  }, [text])

  const mediaAttachedHandler = useCallback(() => setMediaAttached(true), [])
  const mediaDetachedHandler = useCallback(() => setMediaAttached(false), [])

  function handleSubmit () : void {
    if (mediaAtached) {
      setUploadAction(MediaLoaderAction.upload)
    } else {
      setUploadAction(MediaLoaderAction.none)

      onSendMessage(text, null)
      setText('')
      setMediaAttached(false)
    }
    // setText('')
    setDisplaySend(false)
  }

  const handlePoll = () => {
    setPoll(true)
  }

  const handleList = () => {
    setList(true)
  }

  if (poll || list || listN) {
    let setFunction
    let moduleType
    if (poll) {
      setFunction = setPoll
      moduleType = ModuleType.POLL
    } else if (list) {
      setFunction = setList
      moduleType = ModuleType.LIST
    } else /* if (list_N) */{
      setFunction = setListN
      moduleType = ModuleType.LIST_N
    }
    return (<Redirect push to={`/pod/${pod._id}/modulecreate/${moduleType}`} />)
  }

  debug(`render: notifications=${notifications?.length}`, notifications)

  // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
  // TODO: process.browser is undefined, find a fix later
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const displayAnim = haveFlow(posts)
  return (
    <>
      <Container className={classes.screenSize}>
        <div className={classes.spacer}>
          <div className={classes.bottomPostMarge}>
            <WidgetPosts podId={pod._id} posts={posts} loggedInUserId={loggedInUserId} scrollStatus={scrollStatus} postToDelete={handleDeletePostWithConfirmation} />
          </div>
          <div className={classes.inputBar}>
            {displaySend || mediaAtached
              ? null
              : (
                <>
                  {displayAnim || posts.length > 1
                    ? null
                    : (
                      <span className={classes.CCCinstruction}>
                        <span style={{ width: '95%', color: 'white', overflowWrap: 'break-word', fontSize: '14px' }}>{t('chat.plusButton')}</span>
                      </span>
                    )}
                  <IconButton onClick={toggleDrawer('bottom', true)} className={classes.flowButton}><AddCircle fontSize='large' /></IconButton>
                  {displayAnim ? null
                    : <div className={classes.flowCircle} />}
                </>
              )}
            <TextField
              className={classes.textFieldbackgroundColor}
              size='small'
              label={t('chat.label')}
              margin='dense'
              multiline
              rowsMax={3}
              fullWidth
              id='outlined-basic'
              variant='outlined'
              value={text}
              InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
              onChange={msg => {
              // check if message does not contain only whitespaces
                (msg.target.value.replace(/\s/g, '').length) ? setDisplaySend(true) : setDisplaySend(false)
                setText(msg.target.value)
              }}
            />
            <MediaLoader podId={pod._id} token={mediaToken} action={uploadAction} onMediaAttached={mediaAttachedHandler} onMediaUploaded={mediaUploadedHandler} onMediaDetached={mediaDetachedHandler} />
            <ListenerWindowScroll offset={bottomMargin} onMove={(sp : ScrollPosition) => { setScrollStatus(sp.status) }} />

            {displaySend || mediaAtached
              ? (
                <IconButton onClick={() => { handleSubmit() }}>
                  <span className={classes.dot} />
                  <SendIcon className={classes.arrow} />
                </IconButton>)
              : null}
          </div>
          <WidgetSwipeableDrawer
            open={state.bottom}
            onClose={toggleDrawer('bottom', false)}
            onOpen={toggleDrawer('bottom', true)}
            handleList={handleList}
            handlePoll={handlePoll}
            toggleDrawer={toggleDrawer}
          />
        </div>
      </Container>
    </>
  )
}

export default memo(LayoutPodChat)
