import React from 'react'
import { connect } from 'react-redux'

import LayoutOnboarding from '../../components/Layouts/LayoutOnboarding'
import { onboardingThunk, OnboardingThunk } from './podsSlice'
import { User } from '../../interfaces/interfacesApp'

export interface PodsProps {
  user: User
  onboardingThunk: OnboardingThunk
}

const mapDispatch = { onboardingThunk }

const OnboardingFeature = (props: any) => {
  return (
    <LayoutOnboarding
      onRedirect={() => props.onboardingThunk(props.user)}
    />)
}

interface OnboardingState {
    login : {
      user: User
    }
}

const mapState = (state: OnboardingState) => ({ user: state?.login?.user })


export default connect(mapState, mapDispatch)(OnboardingFeature)
