import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { getColorFromID, isPublicEmail } from '../../lib/utils'
import { User } from '../../interfaces/interfacesApp'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import AvatarUser from './AvatarUser'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
    fontSize: '16px',
    fontFamily: 'Roboto',
    paddingLeft: '1rem'
  },
  badge: {
    color: '#ffffff',
    backgroundColor: '#000000',
    borderRadius: '10px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: '13px'
  }
}))

export interface ListItemUserProps {
  /** index of the user in the POD */
  index: number
  /** user data */
  user: User
  /** function to execute on chevron click, if not set chevron is not displayer */
  onChevron?: boolean
  /** function to execute on badge click, if not set chevron is not displayer */
  onBadge?: any
  /** badge text (replaces chevron) */
  badgeText?: string
  /** display circle checked if circle is set */
  circleChecked?: string
}

/**
 * A lis item simple user display on one line
 * - index, user, onChevron
 */
const ListItemUser = ({ index, user, onChevron, badgeText, onBadge, circleChecked } : ListItemUserProps) : ReactElement | null => {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const isPublic = isPublicEmail(user.email || '')

  if (user === undefined) return null
  return (
    <ListItem>
      <AvatarUser user={user} backgroundColor={(index !== -1 ? getColorFromID(user?._id) : 'lightGray')} />
      <ListItemText primary={
        <Typography
          component='span'
          variant='h6'
          className={classes.inline}
          color='textPrimary'
        >
          {user.firstname + ' ' + user.lastname + (isPublic ? ` (${t('profile.publicMail')})` : '')}
        </Typography>
      }
      />
      {onChevron ? <ChevronRightIcon style={{ color: 'lightGray' }} /> : null}
      {badgeText ? <div className={classes.badge} onClick={() => { if (onBadge) { onBadge() } }}>{badgeText}</div> : null}
      {circleChecked
        ? circleChecked === 'checked'
          ? index !== -1
            ? <CheckCircleOutlineIcon />
            : <CheckCircleOutlineIcon style={{ color: 'lightgray' }} />
          : <RadioButtonUncheckedIcon />
        : null}
    </ListItem>
  )
}

export default ListItemUser
