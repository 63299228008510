import React, { useRef, useEffect, memo, ReactElement } from 'react'
import { Post } from '../../interfaces/interfacesApp'
import WidgetPostFull from './PostFull'
import WidgetPostGroup from './PostGroup'
import WidgetPostModule from './PostModule'
import Debug from 'debug'
import WidgetDisplayInfo from '../Widgets/WidgetDisplayInfo'
import EmptyChatIcon from '../../assets/22--conversation-chat--communication-dialogue-discussion.svg'
import { useTranslation } from 'react-i18next'
import { ScrollStatus } from '../Listeners/ListenerWindowScroll'

const debug = Debug('widgets:Posts')
const renderDebug = Debug('render:Posts')

export interface LayoutPostsProps {
  posts: Post[]
  loggedInUserId: string
  scrollStatus: ScrollStatus
  postToDelete: (post: Post) => void,
  podId: string
}

export const ScrollToBottomContext = React.createContext(() => console.warn('nohing to do in scroll to bottom'))

const LayoutPosts = ({ podId, posts, loggedInUserId, scrollStatus, postToDelete } : LayoutPostsProps) : ReactElement => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const { t } = useTranslation('translation')

  const scrollToBottom = () => {
    debug('use effect scrollToBottom')
    if (messagesEndRef.current != null && scrollStatus === ScrollStatus.BOTTOM) {
      debug('scrollToBottom')
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom()
    }, 300)
  }, [posts])

  renderDebug('render')

  return (
    <ScrollToBottomContext.Provider value={scrollToBottom}>
      {(posts.length === 0) && <WidgetDisplayInfo title={t('emptyChat.title')} desc1={t('emptyChat.desc1')} image={EmptyChatIcon} />}
      {posts.map((post : Post) => {
        const isLoggedInUserPost = (post.creatorId === loggedInUserId)
        const postKey = post._id || post.moduleId || post.message
        if (post.moduleType == null) {
          if (post.postType === 'post-full') {
            // message with pseudo at the beginning
            return (
              <WidgetPostFull postToDelete={postToDelete} key={postKey} post={post} messagesEndRef={messagesEndRef} isLoggedInUserPost={isLoggedInUserPost} />
            )
            // message without pseudo
          } else {
            return (
              <WidgetPostGroup postToDelete={postToDelete} key={postKey} post={post} messagesEndRef={messagesEndRef} isLoggedInUserPost={isLoggedInUserPost} />
            )
          }
        }
        return (
          <WidgetPostModule podId={podId} key={postKey} post={post} messagesEndRef={messagesEndRef} />
        )
      })}
    </ScrollToBottomContext.Provider>
  )
}

export default memo(LayoutPosts)
