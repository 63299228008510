import React, { ReactElement, useState } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import NavBar from '../Menus/NavBar'
import { showFeedbackDialog } from '../Popup/FeedbackDialog'
import { urlApp } from '../../App'
import { Pod } from '../../interfaces/interfacesApp'
import Snackbar from '@material-ui/core/Snackbar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { copyToClipboard, getColorFromID, isShareAPISupported, showShareAPI } from '../../lib/utils'
// import { FullscreenExit } from '@material-ui/icons'
// import { createTrue } from 'typescript'

export const useStyles = makeStyles(() => ({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginTop: '1rem'
  },
  link: { textDecoration: 'none' },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginTop: '2rem',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  linktext: {
    display: 'flex',
    justifyContent: 'center',
    color: 'lightgray',
    fontSize: '14px',
    fontFamily: 'Roboto',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '1rem'
  },
  ellispsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'Roboto',
    marginTop: '2rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    overflowWrap: 'break-word',
    textAlign: 'center'
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '2rem'
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'large'
  },
  dot: {
    height: '56px',
    width: '56px',
    backgroundColor: '#404040',
    borderRadius: '50%',
    display: 'inline-block',
    position: 'absolute',
    textAlign: 'center'
  },
  littletext: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    overflowWrap: 'break-word',
    textAlign: 'center',
    marginTop: '1rem'
  },
  hoveraction: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)'
    },
    '&action': {
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  avatar: {
    width: '64px',
    height: '64px',
    borderRadius: '16px',
    opacity: '0.3'
  },
  avatarPod: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem'
  }
}))

export interface LayoutInvitationLinkProps {
    pod: Pod
}

// render layout for inviting people with a link
const LayoutInvitationLink = ({ pod } : LayoutInvitationLinkProps): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation('translation')

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  // Matomo tracking
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Viewing Invitation Link'
    })
  }, [])

  const link = `${urlApp}/podinvite/${pod._id}`

  // **** elements within LayoutInvitationLink ****
  function Label () : ReactElement {
    return (
      <div className={classes.label}>
        <span className={classes.ellipsis}>{pod.title}</span>
      </div>)
  }

  function LinkText () : ReactElement {
    return (
      <div className={classes.linktext}>
        <span className={classes.ellispsis}>{link}</span>
      </div>)
  }

  function Description () : ReactElement {
    return (
      <div className={classes.description}>
        <span>{t('invite.description')}</span>
      </div>)
  }

  function Icons () : ReactElement {
    // button share is displayed only for browsers who support shareAPI
    // button copy is always dispayed
    return (
      <div className={classes.icons}>
        {isShareAPISupported()
          ? (
            <>
              <div className={classes.icon}>
                <IconButton onClick={() => showShareAPI(link, ({ podTitle: pod.title, podDesc: pod.description }))} className={classes.hoveraction}>
                  <span className={classes.dot} />
                  <ShareIcon style={{ color: 'white', position: 'relative' }} />
                </IconButton>
                <span className={classes.littletext}>{t('invite.share')}</span>
              </div>
              <div style={{ width: '5%' }} />
            </>)
          : null}
        <div className={classes.icon}>
          <IconButton onClick={() => {
            if (copyToClipboard(link, ({ podTitle: pod.title, podDesc: pod.description }))) {
              showFeedbackDialog(1000, t('invite.copy_success'), setMessage, setOpen)
            }
          }}
          >
            <span className={classes.dot} />
            <FileCopyIcon style={{ color: 'white', position: 'relative' }} />
          </IconButton>
          <span className={classes.littletext}>{t('invite.copy')}</span>
        </div>
      </div>)
  }

  // **** principal layout ****
  return (
    <>
      <NavBar back title={t('invite.title')} />
      <div className={classes.screen}>
        <ListItemAvatar className={classes.avatarPod}>
          <div className={classes.avatar} style={{ backgroundColor: getColorFromID(pod._id).toString() }} />
        </ListItemAvatar>
        <Label />
        <LinkText />
        <Description />
        <Icons />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        message={message}
        key={'bottom' + ' ' + 'center'}
      />
    </>
  )
}

export default LayoutInvitationLink
