import React, { useState, ReactElement } from 'react'
import LayoutRelationships from '../../components/Layouts/LayoutRelationships'
import { connect } from 'react-redux'
import { User } from '../../interfaces/interfacesApp'
import { Redirect } from 'react-router-dom'
import { relationshipUpdateThunk } from '../../features/Relationships/relationshipsSlice'

const mapDispatch = { relationshipUpdateThunk }

export interface RelationshipsProps {
  relationships: any
  users: any
  userId: string
  email?: string
  relationshipUpdateThunk: any
}

const Relationships = (props : RelationshipsProps): ReactElement => {
  const [idCreate, setIdCreate] = useState(null)

  if (idCreate != null) {
    return (<Redirect push to={`/pod/${idCreate}`} />)
  }

  const processedRelationships = props.relationships.filter((r: any) => r != null && (r.requesterId === props.userId || r.requestedId === props.userId))

  const confirmRelationship = (id: string, status: string) => {
    const relationship = props.relationships.find((r: any) => r._id === id)
    if (relationship != null) {
      relationship.status = status
      props.relationshipUpdateThunk(relationship, status)
    }
  }

  return (
    <div>
      <LayoutRelationships
        userId={props.userId}
        users={props.users}
        relationships={processedRelationships}
        onConfirmRelationship={(id: string, status: string) => confirmRelationship(id, status)}
      />
    </div>
  )
}

interface PodsState {
  login : {
    user: User
  }
  relationships : any,
  users: any
}

const mapState = (state : PodsState) => ({
  users: state.users.queryResult.data || state.users.queryResult || [],
  relationships: state.relationships.queryResult.data || state.relationships.queryResult || [],
  userId: state.login?.user?._id
})

export default connect(mapState, mapDispatch)(Relationships)
