import React, { ReactElement } from 'react'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

type CookiesDialogProps = DialogProps & {
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const CookiesDialog = ({ onConfirm, ...props }: CookiesDialogProps): ReactElement => {
  const { t } = useTranslation('translation')
  return (
    <Dialog {...props} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{t('popup.cookies.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{t('popup.cookies.content') + ' '}<a href='https://sym.place/symly/mentions-legales.html'>{t('popup.cookies.link')}</a></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {t('popup.cookies.button')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CookiesDialog
