// store/UserProvider.js
import React, { createContext, ReactElement, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bootThunk, loginJWTThunk, LoginState, msAuthThunk, msTokensThunk, RemoveFeathersTokenThunk, removeFeathersTokenThunk, storeURLToken, logoutThunk, LogoutThunk } from './loginSlice'
import makeDebug from 'debug'
import { AppState } from '../../interfaces/AppState'
import { mediasClient, mediasServices, podsClient, podsServices, pollsClient, postsClient, usersClient, usersServices } from '../../App'
import LayoutLogin from '../../components/Layouts/LayoutLogin'
import i18n from '../../i18n'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { isPublicEmail } from '../../lib/utils'
import { podTrackThunk } from '../Pods/podsSlice'

const debug = makeDebug('ConnectionProvider')

const isDebugAuth = true // set to true to see debugging data in the app

const mapDispatch = { logoutThunk, removeFeathersTokenThunk }

export type LogoutCallback = () => void

export const LogoutContext = createContext(() => window.alert('NO LOGOUT'))

export interface LogoutProviderProps {
  children?: any // standard react children
  logoutThunk: LogoutThunk // thunk to call for logout
  removeFeathersTokenThunk: RemoveFeathersTokenThunk
  accessTokenFromURL?: string
  error: string
}

const ConnectionProvider = ({ children, logoutThunk, removeFeathersTokenThunk, accessTokenFromURL, error }: LogoutProviderProps) : ReactElement | null => {
  const loginStatus = useSelector((state:AppState) => state.login.status)
  const loginToken = useSelector((state:AppState) => state.login.token)
  const loginMsTokens = useSelector((state:AppState) => state.login.msTokens)
  const userEmail = useSelector((state:AppState) => state.login.user?.email)
  const userLocale = useSelector((state:AppState) => state.login.user?.locale || 'en')
  const userId = useSelector((state:AppState) => state.login.user?._id)

  const trackPodIdTarget = useSelector((state:AppState) => state.podsSlice?.trackPodIdTarget)

  const [podsIoConnected, setPodsIoConnected] = useState(false)
  const [podsToBeReloaded, setPodsToBeReloaded] = useState(false)
  const [usersIoConnected, setUsersIoConnected] = useState(false)
  const [usersToBeReloaded, setUsersToBeReloaded] = useState(false)
  const [pollsIoConnected, setPollsIoConnected] = useState(false)
  const [pollsToBeReloaded, setPollsToBeReloaded] = useState(false)
  const [postsIoConnected, setPostsIoConnected] = useState(false)
  const [postsToBeReloaded, setPostsToBeReloaded] = useState(false)
  const [mediasIoConnected, setMediasIoConnected] = useState(false)
  const [mediasToBeReloaded, setMediasToBeReloaded] = useState(false)

  const dispatch = useDispatch()

  // Register/unregister 'login' & 'disconnect' service listeners
  useEffect(() => {
    debug('DidMount: Register pods, users, polls, posts & medias login & disconnect listeners')
    const podsLoginListener = (event: any) => {
      const serviceName = event?.authentication?.payload?.app
      debug(`Service ${serviceName}: connected`)
      if (!podsIoConnected) {
        setPodsIoConnected(true)
        setPodsToBeReloaded(true)
      }
    }
    const podsDisconnectListener = (reason: any) => {
      debug(`Service pods: socket disconnected for reason ${reason}`)
      if (podsIoConnected) {
        setPodsIoConnected(false)
      }
    }
    podsClient.on('login', podsLoginListener)
    podsClient.io.on('disconnect', podsDisconnectListener)

    const usersLoginListener = (event: any) => {
      const serviceName = event?.authentication?.payload?.app
      debug(`Service ${serviceName}: connected`)
      if (!usersIoConnected) {
        setUsersIoConnected(true)
        setUsersToBeReloaded(true)
      }
    }
    const usersDisconnectListener = (reason: any) => {
      debug(`Service users: socket disconnected for reason ${reason}`)
      if (usersIoConnected) {
        setUsersIoConnected(false)
      }
    }
    usersClient.on('login', usersLoginListener)
    usersClient.io.on('disconnect', usersDisconnectListener)

    const pollsLoginListener = (event: any) => {
      const serviceName = event?.authentication?.payload?.app
      debug(`Service ${serviceName}: connected`)
      if (!pollsIoConnected) {
        setPollsIoConnected(true)
        setPollsToBeReloaded(true)
      }
    }
    const pollsDisconnectListener = (reason: any) => {
      debug(`Service polls: socket disconnected for reason ${reason}`)
      if (pollsIoConnected) {
        setPollsIoConnected(false)
      }
    }
    pollsClient.on('login', pollsLoginListener)
    pollsClient.io.on('disconnect', pollsDisconnectListener)

    const postsLoginListener = (event: any) => {
      const serviceName = event?.authentication?.payload?.app
      debug(`Service ${serviceName}: connected`)
      if (!postsIoConnected) {
        setPostsIoConnected(true)
        setPostsToBeReloaded(true)
      }
    }
    const postsDisconnectListener = (reason: any) => {
      debug(`Service posts: socket disconnected for reason ${reason}`)
      if (postsIoConnected) {
        setPostsIoConnected(false)
      }
    }
    postsClient.on('login', postsLoginListener)
    postsClient.io.on('disconnect', postsDisconnectListener)

    const mediaLoginListener = (event: any) => {
      const serviceName = event?.authentication?.payload?.app
      debug(`Service ${serviceName}: connected`)
      if (!mediasIoConnected) {
        setMediasIoConnected(true)
        setMediasToBeReloaded(true)
      }
    }
    const mediasDisconnectListener = (reason: any) => {
      debug(`Service medias: socket disconnected for reason ${reason}`)
      if (mediasIoConnected) {
        setMediasIoConnected(false)
      }
    }
    mediasClient.on('login', mediaLoginListener)
    mediasClient.io.on('disconnect', mediasDisconnectListener)
    return () => {
      debug('WillUnmout: Unregister pods, users, polls, posts & medias login & disconnect listeners')
      podsClient.removeListener('login', podsLoginListener)
      podsClient.removeListener('disconnect', podsDisconnectListener)

      usersClient.removeListener('login', usersLoginListener)
      usersClient.removeListener('disconnect', usersDisconnectListener)

      pollsClient.removeListener('login', pollsLoginListener)
      pollsClient.removeListener('disconnect', pollsDisconnectListener)

      postsClient.removeListener('login', postsLoginListener)
      postsClient.removeListener('disconnect', postsDisconnectListener)

      mediasClient.removeListener('login', mediaLoginListener)
      mediasClient.removeListener('disconnect', mediasDisconnectListener)
    }
  }, [podsIoConnected, usersIoConnected, pollsIoConnected, postsIoConnected, mediasIoConnected])

  // Matomo tracking
  const { trackEvent, pushInstruction } = useMatomo()

  const tokenPresence = loginToken ? 'YES' : 'NO'
  const msTokensPresence = (loginMsTokens?.length) ? 'YES' : 'NO'
  debug(`Render status:${loginStatus} token:${tokenPresence} userEmail:${userEmail} userLocale:${userLocale} userId:${userId} msTokens:${msTokensPresence} accessTokenFromURL:${accessTokenFromURL} error:${error}`)

  // LOGIN State machine
  // 1st are we logged out and have a token in the URL ... store it to log with it later ?
  if (loginStatus === LoginState.USER_NOT_LOGGED_IN && accessTokenFromURL !== '') {
    debug('=> storing token in status %s', loginStatus)
    dispatch(storeURLToken(accessTokenFromURL || ''))
  }

  // 2nd are we logged out and have a user token already ? try to log with it
  if (loginStatus === LoginState.USER_NOT_LOGGED_IN && loginToken !== null) {
    debug('=> status %s, stored token found, trying to log on api-users => calling loginJWTThunk', loginStatus)
    dispatch(loginJWTThunk(loginToken, usersClient))
    return null
  }

  // 3rd are we in an ongoing sequence ?
  if (loginStatus === LoginState.USER_AUTH_ONGOING ||
    loginStatus === LoginState.MS_RETRIEVING_TOKENS_ONGOING ||
    loginStatus === LoginState.MS_AUTH_WITH_TOKENS_ONGOING ||
    loginStatus === LoginState.USER_CONTEXT_INITIALIZATION
  ) {
    debug('=> status %s, waiting while authenticating on ms or user', loginStatus)
    return (<div><LayoutLogin pacerText={isDebugAuth ? loginStatus : ''} /></div>)
  }

  // 4th user authentication success now start retrieving all ms tokens and set user locale and language
  if (loginStatus === LoginState.USER_AUTH_OK) {
    debug('=> status %s, user authentified, trying to get ms tokens => calling msTokensThunk in status %s', loginStatus)
    trackEvent({ category: 'login-page', action: 'success' })
    pushInstruction('setUserId', userEmail)
    i18n.changeLanguage(userLocale)
    dispatch(msTokensThunk())
    return (<div><LayoutLogin pacerText={isDebugAuth ? loginStatus : ''} /></div>)
  }

  // 5th MS token retrieved authenticate to all ms with retrieved tokens
  if (loginStatus === LoginState.MS_RETRIEVING_TOKENS_OK && loginMsTokens) {
    debug('=> status %s, all ms tokens have been received, trying to authenticate on ms', loginStatus)
    // MEDIAS APP
    dispatch(msAuthThunk(podsClient, pollsClient, postsClient, mediasClient, loginMsTokens))
    return (<div><LayoutLogin pacerText={isDebugAuth ? loginStatus : ''} /></div>)
  }

  // 6th EUREKA we should now be fully authenticated to all MS thus we should not even be here as the app should have routed to the pods now :)
  if (loginStatus === LoginState.MS_AUTH_WITH_TOKENS_OK && userId) {
    debug('=> status %s, authenticated on all ms !!! login job done', loginStatus)
    dispatch(bootThunk(usersClient, podsClient, pollsClient, postsClient, mediasClient, userId))
    return (<div><LayoutLogin pacerText={isDebugAuth ? loginStatus : ''} /></div>)
  }

  debug('Boot over')

  // Reload data after a reconnection
  if (podsToBeReloaded && podsIoConnected) {
    debug('RELOAD PODS')
    dispatch(podsServices.pods.find()) // get my pods
    setPodsToBeReloaded(false)
  }
  if (usersToBeReloaded && usersIoConnected && podsIoConnected && userId) {
    debug('RELOAD USERS')
    dispatch(usersServices.users.find({ query: { loginId: userId } })) // get my pods & relationships users
    dispatch(usersServices.relationships.find()) // get my relationships
    dispatch(usersServices.notifications.find()) // get my notifications
    setUsersToBeReloaded(false)
  }
  if ((pollsToBeReloaded || postsToBeReloaded) &&
      pollsIoConnected && postsIoConnected && podsIoConnected && usersIoConnected && userId) {
    debug('UPDATE TRACKPACK, RELOAD POLLS & POSTS')
    dispatch(podTrackThunk(userId, trackPodIdTarget)) // set trackPodId & get my pod's polls, my pod's posts
    setPollsToBeReloaded(false)
    setPostsToBeReloaded(false)
  }
  if (mediasToBeReloaded && mediasIoConnected && podsIoConnected) {
    debug('RELOAD MEDIAS')
    dispatch(mediasServices.medias.find()) // get my pod's medias
    setMediasToBeReloaded(false)
  }

  const isPublic = isPublicEmail(userEmail || '')

  return (
    <LogoutContext.Provider value={isPublic ? removeFeathersTokenThunk : logoutThunk}>
      {children}
    </LogoutContext.Provider>
  )
}

const ACCESS_TOKEN_STR = '#access_token='

const mapStateToProps = (state : any, ownProps : any) => {
  // retrieve token from the URL (used in SSO)
  const urlHash = window.location.hash.toString()
  const tokenPosition = urlHash.indexOf(ACCESS_TOKEN_STR)
  let accessTokenFromURL = ''
  let error = null
  if (tokenPosition >= 0) {
    accessTokenFromURL = urlHash.substr(tokenPosition + ACCESS_TOKEN_STR.length)
  } else {
    error = urlHash // In case of error, the error is provided in the URL
  }

  return ({
    ...ownProps,
    accessTokenFromURL,
    error: error || state.login.error
  })
}
export default connect(mapStateToProps, mapDispatch)(ConnectionProvider)
